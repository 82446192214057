import { useEffect, useState } from "react";
import { Box } from '@material-ui/core';
import { Table } from '@material-ui/core';
import { TableBody } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { TableContainer } from '@material-ui/core';
import { TableHead } from '@material-ui/core';
import { TablePagination } from '@material-ui/core';
import { TableRow } from '@material-ui/core';
import { TableSortLabel } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
    tableRow: {
        '&:hover': {
            // backgroundColor: theme.palette.background.primary,
            cursor: "pointer",
        }
    },
    tableRowCell: {
        backgroundColor: theme.palette.background.white,
        color: theme.palette.text.primary,
        fontWeight: theme.palette.text.fontWeight.semiBold,
        fontSize: theme.palette.text.size.sm,
        borderBottom: `1px solid ${theme.palette.text.primary}`,
        borderTop: `1px solid ${theme.palette.border.primary}`,
    },
    activeHeadCell: {
        '& .MuiSvgIcon-root': {
            color: `${theme.palette.iconColor.success} !important`
        }
    },
    inactiveHeadCell: {
        '& .MuiTableSortLabel-icon': {
            color: `${theme.palette.iconColor.success} !important`
        }
    },
    tableHead: {
        borderBottom: `1px solid ${theme.palette.text.primary}`,
        color: theme.palette.text.primary,
        fontWeight: theme.palette.text.fontWeight.semiBold,
        fontSize: theme.palette.text.size.sm,
    },
    tableContainer: {
        '& .MuiPaper-rounded': {
            borderRadius: 0,
            boxShadow: 'none',
            backgroundColor: theme.palette.background.primary
        },
        '& .Mui-selected': {
            '&:hover': {
                opacity: 0.8
            },
        }
    },
    toggleRow: {
        display: 'flex',
        flexDirection: 'column'
    },
    checkboxColumn: {
        width: '30px',
    },
    checkboxColumnContent: {
        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'center',
    },
    selectAllCheckbox: {
        height: '48px'
    },
    selectOneCheckbox: {
        marginTop: '15px'
    }
}));



function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead({ headCells, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, checkboxless }) {
    const styles = useStyles();
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={styles.tableHead}>
            <TableRow>
                {checkboxless ? null :
                    <TableCell className={clsx(styles.tableHead, styles.checkboxColumn, styles.selectAllCheckbox)}>
                        <div className={styles.checkboxColumnContent}>
                            <Checkbox
                                color="primary"
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={rowCount > 0 && numSelected === rowCount}
                                onChange={onSelectAllClick}
                                inputProps={{ 'aria-label': 'select all' }}
                            />
                        </div>
                    </TableCell>}
                {headCells.map((headCell, index) => {
                    return (
                        <TableCell
                            className={styles.tableHead}
                            key={headCell.id || index}
                            align={'left'}
                            padding={'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {headCell.hideHeadLabel ?
                                ''
                                :
                                headCell.notSortable ?
                                    headCell.label
                                    :
                                    <TableSortLabel
                                        active={true}
                                        className={orderBy === headCell.id ? styles.activeHeadCell : styles.inactiveHeadCell}
                                        direction={orderBy === headCell.id ? order : 'desc'}
                                        onClick={createSortHandler(headCell.id)}
                                        IconComponent={orderBy === headCell.id ? ArrowDropUpIcon : ArrowDropDownIcon}
                                    >
                                        {headCell.label}
                                    </TableSortLabel>
                            }
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead >
    );
}

export default function BrandTable({
    rows,
    headCells,
    checkboxless,
    page,
    setPage,
    hidePagination,
    setSortBy,
    setSortType,
    rowIsClickable,
    additionalData,
    onRowClick,
    ToggleRowData,
    setSelectedRows,
    rowsPerPageOptions,
    rowsPerPage,
    setRowsPerPage,
    useTableSort}) {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [selected, setSelected] = useState([]);
    const [selectedData, setSelectedData] = useState({});
    const styles = useStyles();

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        if (hidePagination) {
            setSortBy(property)
            setSortType(isAsc ? 'DESC' : 'ASC')
        }
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {

            const tempSelectedData = {};
            const newSelecteds = rows.map((n) => {
                tempSelectedData[n.id] = n;
                return n.id;
            });
            setSelected(newSelecteds);
            setSelectedData({ ...tempSelectedData });
            return;
        }
        setSelected([]);
        setSelectedData({});
    };

    const handleClick = (event, id, row) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        const tempSelectedData = selectedData;

        if (event.target.checked) {
            tempSelectedData[id] = row;
        } else {
            delete tempSelectedData[id];
        }

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
        setSelectedData({ ...tempSelectedData });
    };

    useEffect(() => {
        if (setSelectedRows) {
            setSelectedRows({ ...selectedData })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedData])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;
    // eslint-disable-next-line no-unused-vars
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    return (
        <Box className={styles.tableContainer} sx={{ width: '100%', marginBottom: 20 }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <EnhancedTableHead
                            headCells={headCells}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            checkboxless={checkboxless}
                        />
                        <TableBody>
                            {hidePagination ?
                                (useTableSort ? stableSort(rows, getComparator(order, orderBy)) : rows).map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        row && row.toggledRow ?
                                            <ToggleRowData row={row} additionalData={additionalData} />
                                            :
                                            <TableRow
                                                hover={rowIsClickable}
                                                onClick={
                                                    checkboxless ?
                                                        rowIsClickable ?
                                                            (event) => onRowClick(event, row)
                                                            :
                                                            null
                                                        :
                                                        rowIsClickable ?
                                                            (event) => {
                                                                handleClick(event, row.id, row)
                                                            }
                                                            :
                                                            null
                                                }
                                                role={checkboxless ? null : "checkbox"}
                                                aria-checked={checkboxless ? null : isItemSelected}
                                                tabIndex={checkboxless ? null : -1}
                                                key={`${row.id}-${index}` || index}
                                                selected={checkboxless ? null : isItemSelected}
                                                className={rowIsClickable ? styles.tableRow : null}
                                            >
                                                {checkboxless ? null :
                                                    <TableCell className={clsx(styles.tableRowCell, styles.checkboxColumn, styles.selectOneCheckbox)} padding="checkbox">
                                                        <Checkbox
                                                            onClick={rowIsClickable ? null : (event) => handleClick(event, row.id, row)}
                                                            color="primary"
                                                            checked={isItemSelected}
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                        />
                                                    </TableCell>}
                                                {headCells.map((headCell, i) => {
                                                    const CellRender = headCell.CellRender;
                                                    return <TableCell style={{ minWidth: headCell.minWidth }} className={styles.tableRowCell} key={headCell.id ? headCell.id + i : i} align="left">
                                                        {CellRender ?
                                                            <CellRender key={headCell.id ? headCell.id + i : i} index={i} additionalData={additionalData} cellData={row[headCell.id]} rowData={row} tableRows={rows} />
                                                            :
                                                            row[headCell.id]
                                                        }
                                                    </TableCell>
                                                })}
                                            </TableRow>
                                    );
                                })
                                :
                                stableSort(rows, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                            row && row.toggledRow ?
                                                <ToggleRowData props={row} />
                                                :
                                                <TableRow
                                                    hover={true}
                                                    onClick={
                                                        checkboxless ?
                                                            rowIsClickable ?
                                                                (event) => onRowClick(event, row)
                                                                : null
                                                            : (event) => handleClick(event, row.id, row)}
                                                    role={checkboxless ? null : "checkbox"}
                                                    aria-checked={checkboxless ? null : isItemSelected}
                                                    tabIndex={checkboxless ? null : -1}
                                                    key={row.id || index}
                                                    selected={checkboxless ? null : isItemSelected}
                                                    className={styles.tableRow}
                                                >
                                                    {checkboxless ? null :
                                                        <TableCell className={styles.tableRowCell}>
                                                            <Checkbox
                                                                color="primary"
                                                                checked={isItemSelected}
                                                                inputProps={{
                                                                    'aria-labelledby': labelId,
                                                                }}
                                                            />
                                                        </TableCell>}
                                                    {headCells.map((headCell, i) => {
                                                        const CellRender = headCell.CellRender;
                                                        return <TableCell className={styles.tableRowCell} key={headCell.id ? headCell.id + i : i} align="left">
                                                            {CellRender ?
                                                                <CellRender key={headCell.id ? headCell.id + i : i} cellData={row[headCell.id]} additionalData={additionalData} rowData={row} tableRows={rows} />
                                                                :
                                                                row[headCell.id]
                                                            }
                                                        </TableCell>
                                                    })}
                                                </TableRow>
                                        );
                                    })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 75 * emptyRows }}>
                                    <TableCell colSpan={rowsPerPage} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {hidePagination ? null :
                    <TablePagination
                        rowsPerPageOptions={rowsPerPageOptions}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                }
            </Paper>
        </Box>
    );
}
