import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    circle: {
        width: 150,
        height: 150,
        borderRadius: '50%',
        backgroundColor: theme.palette.background.success,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    circleText: {
        color: theme.palette.text.default
    }
}));

const CircleComponent = ({ number }) => {
    const classes = useStyles();

    return (
        <Paper className={clsx(classes.circle)}>
            <Typography className={classes.circleText} variant="h4" color="secondary">{number}</Typography>
        </Paper>
    );
}

export default CircleComponent;
