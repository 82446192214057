/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, FormControlLabel, ListItem, ListItemText, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
  checkBoxStyle: {
    color: theme.palette.text.primary,
  },
  personaCheckBox: {
    '& .MuiCheckbox-colorPrimary': {
      color: theme.palette.text.primary,
    },
  },
  answerOptions: {
    borderBottom: `2px solid ${theme.palette.border.primary}`
  },
  answerOptionColor: {
    color: theme.palette.text.primary,
  },
  answersOptionsContainer: {
    marginLeft: theme.spacing(5),
    '& .MuiCheckbox-colorPrimary': {
      color: theme.palette.text.primary,
  },
  },
}));

  const AnswersAndSummaryQuesitonCard = ({question, index, setAllSelected, setSelectedPersonas, selectedQuestions, isSelectAllQuestions, setSelectedQuestions, setSelectedOptions, selectedOptions, isOpenEnded, additionalQuestions, studyQuestions}) => {
  const styles = useStyles();
  const quesitonId = question.type === 'additional' ? question.id : question.questionid;

  useEffect(() => {
    if (isSelectAllQuestions && !isOpenEnded) {
      handleSelectOrUnselectOption(isSelectAllQuestions);
    }
  },[isSelectAllQuestions]);

  const handleSelectOrUnselectOption = (isChecked, option) => {
    const selectedOptionsCopy = {...selectedOptions};
    if (!isOpenEnded) {
      if (!option) {
        question.answerOptions.forEach(option => {
          if (isChecked) {
            selectedOptionsCopy[option.id] = option;
          } else {
            delete selectedOptionsCopy[option.id];
          }
        });
      } else {
        if (isChecked) {
            selectedOptionsCopy[option.id] = option;
        } else {
            delete selectedOptionsCopy[option.id];
        }
        setSelectedPersonas({});
      }
    }
    setSelectedOptions(selectedOptionsCopy);
  }

  const handleSelectQuestion = (event) => {
    const isChecked = event.target.checked;

    const selectedQuestionsCopy = {...selectedQuestions};
    handleSelectOrUnselectOption(isChecked);
    if (isChecked) {
      selectedQuestionsCopy[quesitonId] = question;
    } else {
        delete selectedQuestionsCopy[quesitonId];
    }
    setSelectedQuestions(selectedQuestionsCopy);
    setAllSelected(Object.keys(selectedQuestionsCopy).length === (additionalQuestions.length + studyQuestions.length));
  }

  return (
    <div>
    <ListItemText
        key={index}
        primary={<ListItem>
            <FormControlLabel 
                className={styles.personaCheckBox}
                control={<Checkbox
                className={styles.checkBoxStyle}
                checked={Boolean(selectedQuestions[quesitonId])}
                color="primary"
                name='select all available'
                onChange={handleSelectQuestion}
            />}
        label={<Typography>{index + 1}. {question.question}</Typography>}
    />
    </ListItem>}
        secondary={
            <div key={`${quesitonId} ${question.question}`} className={styles.answerOptions}>
              {
                question?.answerOptions?.map((option) =>{
                  if (!option.id) return ''
                  return <div>
                     <FormControlLabel 
                        key={option.id}
                        className={styles.answersOptionsContainer}
                        control={<Checkbox
                        className={styles.checkBoxStyle}
                        color="primary"
                        name={option.answer_option}
                        checked={Boolean(selectedOptions[option.id])}
                        onChange={(event) => handleSelectOrUnselectOption(event.target.checked, option)}
                      />}
                       label={<Typography className={styles.answerOptionColor} variant="body1" component='div' color='primary'>{option.answer_option}</Typography>                                  }
                     />
                  </div>
                }
                )}
              </div>
                      }
        />
    </div>
  )
}

export default AnswersAndSummaryQuesitonCard