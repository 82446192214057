/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-sequences */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BrandInput, BrandPasswordInput } from '../CoreComponents/BrandInput'
import { BrandButton } from '../CoreComponents/BrandButton'
import { BrandLink } from '../CoreComponents/BrandLink'
import { BrandLoaderDots } from '../CoreComponents/BrandLoader'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';
import { onLogin } from '../services/UserService';
import { useStoreContext } from '../../store/Store';
import { BrandAlert } from '../CoreComponents/BrandAlert';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: theme.palette.background.darkSecondary
    },
    form: {
        width: '352px',
        height: '371px',
        padding: theme.spacing(2.5),
        border: '1px solid',
        borderColor: `2px solid ${theme.palette.border.primary}`,
        borderRadius: theme.spacing(1),
        background: theme.palette.background.white,
        display: 'flex',
        text: theme.palette.text.secondary,
        flexDirection: 'column',
        '& .MuiInputBase-root': {
            marginBottom: theme.spacing(2),
        },
    },
    buttonPosition: {
        '& .MuiButtonBase-root': {
            marginTop: theme.spacing(3),
            background: theme.palette.background.secondary,
        },
    },
    title: {
        textAlign: 'center',
        marginBottom: theme.spacing(3.5),
        color: theme.palette.text.primary,
        fontSize: theme.palette.text.size.lg,
        fontWeight:  theme.palette.text.fontWeight.semiBold,
    },
    forgotPassword: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& .MuiLink-underlineHover': {
            color: theme.palette.text.primary,
            textDecoration: 'underline',
            // fontWeight:  theme.palette.text.fontWeight.bold,
        }
    }
}));

const Login = () => {
    const styles = useStyles();
    const [, setState] = useStoreContext();
    const history = useHistory();
    const $username = useState('');
    const $password = useState('');
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState('');

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const data = await onLogin($username[0], $password[0]);
            if (!data) {
                throw Error('Incorrect username or password!')
            }

            if (data.active_status === 'pending') {
                localStorage.setItem("tempToken", data.authorization);
                delete data.authorization;
                setState(state => (state.tempUser = data, { ...state }));
                history.push('/reset-initial-password');
                setLoading(false);
                return;
            }

            localStorage.setItem("token", data.authorization);
            delete data.authorization;
            setState(state => (state.user = data, { ...state }));
            history.push('/');
            setLoading(false);
        } catch (e) {
            localStorage.clear('');
            setState(state => (state.user = null, state.tempUser = null, { ...state }));
            console.error(e?.response?.data || e);
            setAlert(e?.response?.data?.msg || e.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        setAlert('');
    }, [$username[0], $password[0]]);

    return (
        <div className={styles.formContainer}>
            <form onSubmit={onSubmit} className={styles.form}>
                <Typography variant='h6' component='div' className={styles.title}>Log into Account</Typography>
                <BrandInput
                    label="Username"
                    placeholder="Enter Username"
                    required
                    $value={$username}
                />
                <BrandPasswordInput
                    label="Password"
                    placeholder="Enter Password"
                    required
                    $value={$password}
                />
                {/* <div className={styles.forgotPassword}>
                    <BrandLink to='/forgot-password' variant='body1'>Forgot Password ?</BrandLink>
                </div> */}
                <BrandAlert>{alert}</BrandAlert>
                <div className={styles.buttonPosition}>
                    <BrandButton type='submit'>
                        {loading ?
                            <BrandLoaderDots />
                            :
                            'Log In'
                        }
                    </BrandButton>
                </div>
            </form>
        </div>
    );
}

export default Login;