import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { Route, Redirect } from 'react-router';
import Login from './Login';
import ResetInitialPassword from './ResetInitialPassword';
// import ForgotPassword from './ForgotPassword';
import { useHistory } from 'react-router-dom';

const LoggedOutPageLayout = () => {
    const history = useHistory();

    useEffect(() => {
        const fromReload = localStorage.getItem('fromReload');
        const pageBeforeReload = localStorage.getItem('pageBeforeReload');
        if (fromReload === 'true' && pageBeforeReload) {
            history.push(pageBeforeReload);
        }
        localStorage.removeItem('fromReload');
        localStorage.removeItem('pageBeforeReload');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/reset-initial-password" component={ResetInitialPassword} />
            {/* <Route exact path="/forgot-password" component={ForgotPassword} /> */}
            <Redirect from="*" to="/" />
        </Switch>
    );
}

export default LoggedOutPageLayout;