/* eslint-disable no-sequences */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AttributesAutocomplete from './AttributesAutocomplete';
import { BrandButton } from '../BrandButton';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.background.white,
        padding: theme.spacing(2),
        borderRadius: '8px',
        border: `1px solid ${theme.palette.border.primary}`,
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(1),
        marginTop: theme.spacing(1.5),
        '& .MuiButton-root:nth-child(1)': {
            '& .MuiButton-label': {
                color: theme.palette.text.white,
            }
        }
    },
}));

const AddEditAttributeForm = ({
    toggleAttributeForm,
    attributesList,
    attributes,
    setAttributes
}) => {
    const styles = useStyles();
    const [selectedAttribute, setSelectedAttribute] = useState(null)

    const onClickConfirm = () => {
        if (selectedAttribute) {
            setAttributes({ ...attributes, [selectedAttribute.id]: selectedAttribute })
        }
        toggleAttributeForm()
    }

    return (
        <div className={styles.container}>
            <AttributesAutocomplete
                options={attributesList}
                setSelectedAttribute={setSelectedAttribute}
                placeholder='Select Attribute'
                property='name'
                selectedAttributes={attributes}
            />
            <div className={styles.actions}>
                <BrandButton variant='outlined' onClick={toggleAttributeForm}>
                    Cancel
                </BrandButton>
                <BrandButton onClick={onClickConfirm}>
                    Confirm
                </BrandButton>
            </div>
        </div>
    );
}

export default AddEditAttributeForm;