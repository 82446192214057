/* eslint-disable no-sequences */
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Typography } from '@material-ui/core';
import { logout } from '../../services/UserService';
import { useHistory } from 'react-router-dom';
import { useStoreContext } from '../../../store/Store';

const useStyles = makeStyles((theme) => ({
    avatar: {
        width: theme.spacing(9),
        height: theme.spacing(9),
        '& .MuiSvgIcon-root': {
            width: '36px',
            height: '36px',
            color: theme.palette.iconColor.black
        }
    },
    logoutAvatar: {
        background: theme.palette.background.error,
    },
    subtitle: {
        fontSize: theme.palette.text.size.sm,
        fontWeight:  theme.palette.text.fontWeight.bold,
        color: theme.palette.text.white
    },
    iconButton: {
        '&:hover': {
            opacity: 0.8
        }
    }
}));

export const LogoutAvatar = () => {
    const styles = useStyles();
    const [, setStore] = useStoreContext();
    const history = useHistory();


    const onClickLogout = async () => {
        try {
            await logout();
            setStore(state => ({ ...state, user: undefined }));
            history.push('/');
        } catch (e) {
            setStore(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
            console.error(e?.response?.data || e);
        }
    };

    return (
        <IconButton className={styles.iconButton} disableFocusRipple disableRipple disableTouchRipple onClick={onClickLogout}>
            <Avatar className={clsx(styles.avatar, styles.logoutAvatar)}>
                <ExitToAppIcon color='secondary' />
            </Avatar>
            <Typography className={styles.subtitle} variant='subtitle2' component='div'>Logout</Typography>
        </IconButton>
    );
};
