import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ListItemText, Typography } from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        width: '100%',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.background.primary,
        borderRadius: '10px',
    },
    questionName: {

    },
    openEndList: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    closeEndList: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    answerContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        marginLeft: theme.spacing(4),
        marginTop: theme.spacing(2),
    },
    answerText: {
        textAlign: 'start',
        padding: theme.spacing(0,2)
    },
    answer: {
        padding: theme.spacing(0,2),
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'flex-start',
        whiteSpace: 'nowrap',
        width: '100%'
    },
    personaDescription: {
        color: theme.palette.text.successPrimary,
        marginLeft: theme.spacing(.5),
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '100px'
    },
    iconColor: {
        cursor: 'pointer',
        color: theme.palette.iconColor.success,
        marginLeft: theme.spacing(2),
        '&:hover': {
            color: theme.palette.iconColor.successSecondary,
        }
    },
    closeEndAnswerContainer: {
        display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', margin: theme.spacing(1,0)
    },
    questionAnswerOptions: {
        display: 'flex', alignItems: 'center', gap: theme.spacing(2), paddingLeft: theme.spacing(4), width: '90%', flexWrap: 'wrap'
    },
    qustionContainer: {
        width: '100%',
        padding: theme.spacing(.5, 2),
    },
    accordionSummary: {
        '&.MuiAccordionSummary-root.Mui-expanded': {
            borderBottom: `2px solid ${theme.palette.border.primary}`,
        },
    },
    accordion: {
        border: `2px solid ${theme.palette.border.primary}`,
        borderRadius: '10px',
        margin: theme.spacing(2,0)
    },
    expandIconColor: {
        color: theme.palette.iconColor.success,
    },
    questionAnswersOptions: {
        display: 'flex',
        gap: theme.spacing(0.5),
        alignItems: 'center',
        whiteSpace: 'nowrap'
    },
    dotIconStyle: {
        color: theme.palette.iconColor.secondary,
        width: '10px',
        height: '10px',
    },
}));
const AnswersCard = ({ questionName, quesitonId, answers, index, selectedPersonas, setTogglePersonaModal, isOpenEnded, questionsAnswersOptions }) => {
    const styles = useStyles();

    const displayPersonaName = (personaId, fullNameDisplay) => {
        const persona = selectedPersonas[personaId];
        const personaName = persona.Name;

        if (fullNameDisplay && persona) return persona.Name;
        if (persona && personaName.includes(',')) return persona.Name.split(',', 2)[0];
        if (persona && personaName.includes('"')) return persona.Name.split('"', 2)[0];
        if (persona && personaName.includes('\'')) return persona.Name.split('\'', 2)[0];
        if (persona && personaName.includes("-")) return persona.Name.split('-', 2)[0];

        return personaName
    };

    const displayPersonaDescription = (personaId) => {
        const persona = selectedPersonas[personaId];
        const personaName = persona.Name;

        if (persona && personaName.includes(',')) return persona.Name.split(',', 2)[1];
        if (persona && personaName.includes("-")) return persona.Name.split('-', 2)[1];
        if (persona && personaName.includes('"')) return persona.Name.split('"', 2)[1];
        if (persona && personaName.includes('\'')) return persona.Name.split('\'', 2)[1];
        
        return null
    }

    return (
        <Accordion key={quesitonId} className={styles.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={styles.expandIconColor}/>}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={styles.accordionSummary}
            >
                <div className={styles.qustionContainer}>
                    <ListItemText 
                        primary={
                        <Typography className={styles.questionName}>{index + 1}. {questionName}</Typography>
                        } 
                        secondary={
                            <Typography className={styles.questionAnswerOptions}>
                                {
                                    !isOpenEnded && questionsAnswersOptions && questionsAnswersOptions.length > 0 &&
                                    questionsAnswersOptions.map(quesitonAnswerOption => {
                                    return <div className={styles.questionAnswersOptions}>
                                            <FiberManualRecordIcon className={styles.dotIconStyle} />
                                            <Typography>
                                            {quesitonAnswerOption.answer_option}
                                            </Typography>
                                        </div>
                                    })
                                }
                            </Typography>
                        }
                    />
                </div>
            </AccordionSummary>
        <AccordionDetails>
        <div className={isOpenEnded ? styles.openEndList : styles.closeEndList}>
                {
                    answers.map((answer, index) => {
                    if (Object.keys(selectedPersonas).includes(answer.persona_id.toString())) {
                        return (
                            <div key={answer.id} className={isOpenEnded ? styles.answerContainer : styles.closeEndAnswerContainer}>
                                <div className={styles.answer}>
                                    <Typography>{index + 1}.{displayPersonaName(answer.persona_id)}</Typography>
                                    ,<Typography className={styles.personaDescription}>{displayPersonaDescription(answer.persona_id)}</Typography>
                                    <VisibilityOutlinedIcon className={styles.iconColor} color='primary' onClick={() => setTogglePersonaModal(selectedPersonas[answer.persona_id])} />   
                                </div>
                                <Typography className={styles.answerText}>
                                    {answer.answer}
                                </Typography>
                            </div>
                        )
                    }
                    return null;
                })
            }
        </div>
        </AccordionDetails>
      </Accordion>
  )
}

export default AnswersCard