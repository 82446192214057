/* eslint-disable no-sequences */
import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useStoreContext } from '../../store/Store';
import { IconButton, Typography } from '@material-ui/core';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import { BrandButton } from '../CoreComponents/BrandButton';
import { BrandSearchInput } from '../CoreComponents/BrandInput';
import { debounce } from '../utils/FetchUtils';
import BrandCard from '../CoreComponents/BrandCard';
import SortRoundedIcon from '@material-ui/icons/SortRounded';
import { BrandModal } from '../CoreComponents/BrandModal';
import BrandSortOptions from '../CoreComponents/BrandSortOptions';
import { onDeletePanel, onGetPanels } from '../services/PanelService';

const SORT_OPTIONS = [
    {
        label: 'Date',
        type: 'radio',
        options: [
            {
                label: 'Newest',
                sortType: 'DESC'
            },
            {
                label: 'Oldest',
                sortType: 'ASC'
            }
        ]
    },
    {
        label: 'Name',
        type: 'radio',
        options: [
            {
                label: 'A-Z',
                sortType: 'ASC'
            },
            {
                label: 'Z-A',
                sortType: 'DESC'
            }
        ]
    }
]

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3)
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        paddingBottom: theme.spacing(1.5),
        color: theme.palette.text.primary,
        fontWeight:  theme.palette.text.fontWeight.bold,
        borderBottom: `2px solid ${theme.palette.border.primary}`,
        '& .MuiSvgIcon-root': {
            width: '36px',
            height: '36px'
        }
    },
    toolbar: {
        marginTop: theme.spacing(5),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    leftSide: {
        display: 'flex',
        gap: theme.spacing(1),
        '& .MuiButtonBase-root': {
            width: theme.spacing(20)
        },
        '& .MuiInputBase-root': {
            width: 'fit-content'
        }
    },
    deck: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(4),
        paddingTop: theme.spacing(4),
    },
    warning: {
        marginBottom: theme.spacing(5),
        marginRight: theme.spacing(5),
    },
    warningSubText: {
        color: theme.palette.text.error
    },
    pageIcon: {
        fontSize: theme.palette.text.size.xxxl,
        color: theme.palette.iconColor.secondary
    },
    sortIcon: {
        color: theme.palette.iconColor.secondary
     }
}));

const INPUTBACKGROUND = 'transparent';

const Panels = () => {
    const styles = useStyles();
    const history = useHistory();
    const [, setState] = useStoreContext();
    const [panels, setPanels] = useState([])
    const [search, setSearch] = useState('');
    const [searchFilter, setFilterSearch] = useState('');
    const [toggleCardDescriptionDic, setToggleCardDescriptionDic] = useState({});
    const [openDrawer, setOpenDrawer] = useState(null);
    const [selectedSortOptions, setSelectedSortOptions] = useState({ date: 'ASC', name: 'ASC' });
    const [panelForDeletion, setPanelForDeletion] = useState(null);

    const prepare = async () => {
        try {
            const panelData = await onGetPanels(null, null, searchFilter, selectedSortOptions.date, selectedSortOptions.name);
            setPanels(panelData || []);
        } catch (e) {
            setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
            console.error(e?.response?.data || e);
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => prepare(), [searchFilter, selectedSortOptions]);

    const debounceMemo = useMemo(
        () => debounce(
            (event) => {
                setSearch(event.target.value);
            }, (event) => {
                setFilterSearch(event.target.value);
            }, 500),
        [],
    );

    const openPanel = (panel) => {
        history.push(`/panels?id=${panel.id}`);
    };

    const onClickDelete = async (panel) => {
        try {
            await onDeletePanel(panel.id);
            setState(state => (state.toggleStatusModal = { message: 'Panel deleted successfully', isSuccessModal: true, title: 'Success' }, { ...state }));
            prepare();
        } catch (e) {
            setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
            console.error(e?.response?.data || e);;
        }
        finally {
            setPanelForDeletion(null);
        }
    };

    return (
        <div className={styles.container}>
            <BrandModal
                onClose={() => setPanelForDeletion(null)}
                open={Boolean(panelForDeletion)}
                clickAwayClosable={true}
                title='Delete Panel'
                titleWithDot={true}
                isWarningModal={true}
                leftBtnText='Cancel'
                rightBtnText='Delete Panel'
                leftBtnAction={() => setPanelForDeletion(null)}
                rightBtnAction={() => onClickDelete(panelForDeletion)}
            >
                <Typography variant='body2' component='div' className={styles.warning}>
                    Are you sure you want to delete this Panel?<br />
                    <span className={styles.warningSubText}>Your questions will be lost.</span>
                </Typography>
            </BrandModal>
            <BrandSortOptions
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
                sortOptions={SORT_OPTIONS}
                selectedSortOptions={selectedSortOptions}
                setSelectedSortOptions={setSelectedSortOptions}
            />
            <Typography variant="h3" component='div' className={styles.title}>
                <PeopleAltOutlinedIcon className={styles.pageIcon} />
                <div>Panels</div>
            </Typography>
            <div className={styles.toolbar}>
                <div className={styles.leftSide}>
                    <BrandButton onClick={() => history.push('/panels?create=true')}>
                        Create Panel
                    </BrandButton>
                    <BrandSearchInput
                        style={{background: INPUTBACKGROUND}}
                        variant='outlined'
                        placeholder='Search in Panels'
                        value={search}
                        onChange={debounceMemo}
                    />
                </div>
                <div>
                    <IconButton onClick={(e) => setOpenDrawer(e.currentTarget)}>
                        <SortRoundedIcon className={styles.sortIcon} color='default' fontSize='large' />
                    </IconButton>
                </div>
            </div>
            <div className={styles.deck}>
                {panels.map((panel) => {
                    return (
                        <BrandCard
                            key={panel.id}
                            card={panel}
                            setToggleCardDescriptionDic={setToggleCardDescriptionDic}
                            toggleCardDescriptionDic={toggleCardDescriptionDic}
                            leftButtonLabel='Open Panel'
                            leftButtonAction={() => openPanel(panel)}
                            onClickDelete={() => setPanelForDeletion(panel)}
                            isNew={panel.is_new}
                            showResults={false}
                            // onClickClone={() => onClickClone(study)} // TODO: AFTER MVP ADD CLONE FUNCTIONALITY
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default Panels;




