import { getToken } from '../utils/FetchUtils';
import axios from 'axios';

export const getPanelMessages = async (personaId, panelId, offset, limit) => {
    const token = getToken();
    const response = await axios.get(`/api/chat/get-panel-messages?personaId=${personaId}&panelId=${panelId}&limit=${limit}&offset=${offset}`, {
        headers: { Authorization: token }
    });
    return response;
}

export const sendMessage = async (personaId, panelId, message, studyId) => {
    const token = getToken();
    const response = await axios.post('/api/chat/send-message', { personaId, panelId, message, studyId }, {
        headers: { Authorization: token }
    });
    return response;
}

export const getStudyMessages = async (personaId, studyId, offset, limit) => {
    const token = getToken();
    const response = await axios.get(`/api/chat/get-study-messages?personaId=${personaId}&studyId=${studyId}&limit=${limit}&offset=${offset}`, {
        headers: { Authorization: token }
    });
    return response;
}

export const checkPersonaForPendingQuestions = async (personaId, studyId) => {
    const token = getToken();
    const response = await axios.get(`/api/chat/check-persona-for-pending-questions`, {
        params: { personaId, studyId },
        headers: { Authorization: token }
    });
    return response.data;
}