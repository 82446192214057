import axios from 'axios';
import { getToken } from '../utils/FetchUtils';


export const onCreateOrUpdateStudy = async (name, description, additionalContext, questions, studyId) => {
    const authorizationToken = getToken();
    const res = await axios.post(`${process.env.REACT_APP_HOST}/api/study/study`, {
        name,
        description,
        additionalContext,
        questions,
        studyId
    }, {
        headers: { Authorization: authorizationToken }
    });
    return res.data;
};

export const onGetStudies = async (id, limit, searchFilter, dateSortType, nameSortType, fullAnswerOptionsData) => { 
    const authorizationToken = getToken();
    const params = `?id=${id || ''}&limit=${limit || ''}&filter=${searchFilter || ''}&dateSortType=${dateSortType || ''}&nameSortType=${nameSortType || ''}&fullAnswerOptionsData=${fullAnswerOptionsData || false}`;
    const res = await axios.get(`${process.env.REACT_APP_HOST}/api/study/get-studies${params}`, {
        headers: { Authorization: authorizationToken }
    });
    return res.data;
};

export const onDeleteStudy = async (id) => {
    const authorizationToken = getToken();
    const res = await axios.delete(`${process.env.REACT_APP_HOST}/api/study/delete-study`, {
        data: { id },
        headers: { Authorization: authorizationToken }
    });
    return res.data;
};


export const onAskQuestionsFromStudy = async (studyId, panelId, personaIds, questions) => {
    const authorizationToken = getToken();
    const res = await axios.post(`${process.env.REACT_APP_HOST}/api/study/ask-questions`, {
        studyId,
        panelId,
        personaIds,
        questions
    }, {
        headers: { Authorization: authorizationToken }
    });
    return res.data;
}

export const getAnswers = async (personaIds, id, type) => {
    const authorizationToken = getToken();
    const res = await axios.post(`${process.env.REACT_APP_HOST}/api/study/get-answers`, {
        personaIds,
        id,
        type
    }, {
        headers: { Authorization: authorizationToken }
    });
    return res.data;
}

export const onAddOrEditAdditionalQuestion = async (question, answerOptions, studyId, order, questionId) => {
    const authorizationToken = getToken();
    const res = await axios.post(`${process.env.REACT_APP_HOST}/api/study/additional-questions`, {
        question,
        answerOptions,
        studyId,
        order,
        questionId
    }, {
        headers: { Authorization: authorizationToken }
    });
    return res.data;
}

export const onDeleteQuestion = async (questionId) => {
    const authorizationToken = getToken();
    const res = await axios.delete(`${process.env.REACT_APP_HOST}/api/study/delete-question`, {
        data: { questionId },
        headers: { Authorization: authorizationToken }
    });
    return res.data;
}

export const onGetAdditionalQuestions = async (studyId, panelId, fullAnswerOptionsData) => {
    const authorizationToken = getToken();
    const res = await axios.get(`${process.env.REACT_APP_HOST}/api/study/get-additional-questions`, {
        params: { studyId, panelId, fullAnswerOptionsData : fullAnswerOptionsData ? fullAnswerOptionsData : false },
        headers: { Authorization: authorizationToken }
    });
    return res.data;
}