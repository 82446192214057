import { IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import AttributesDeckAutocomplete from './AttributesDeckAutocomplete';
import clsx from 'clsx';
import { BrandInput } from '../BrandInput';

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.background.white,
        borderRadius: '8px',
        border: `2px solid ${theme.palette.border.primary}`,
        width: '300px',
        height: 'fit-content',
        paddingBottom: theme.spacing(1),
        boxShadow: theme.palette.shadow.large
    },
    required: {
        color: theme.palette.text.error
    },
    cardTitleContainer: {
        padding: theme.spacing(1),
        borderBottom: `2px solid ${theme.palette.border.primary}`,
        color: theme.palette.text.primary,
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(0.5),
        alignItems: 'center',
        '& .MuiButtonBase-root': {
            padding: '0px'
        }
    },
    addOptionsButton: {
        background: 'transparent',
        color: theme.palette.text.primary,
        border: 'none',
        marginLeft: theme.spacing(1.5),
        cursor: 'pointer',
        '&:hover': {
            opacity: '0.8'
        }
    },
    autocompleteContainer: {
        padding: theme.spacing(1),
        background: theme.palette.background.white,

        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'center',
        '& .MuiAutocomplete-root': {
            width: '100%',
            background: theme.palette.background.white,

        },
        '& .MuiInputBase-root': {
            color: theme.palette.text.primary,
            background: theme.palette.background.white,
        },
    },
    disabled: {
        opacity: '0.6',
        cursor: 'default',
        '&:hover': {
            opacity: '0.6'
        }
    },
    deleteButton: {
        color: theme.palette.iconColor.error
    },
    cardTitle: {
        color: theme.palette.text.primary,
        fontSize: theme.palette.text.size.lg,
        fontWeight:  theme.palette.text.fontWeight.bold,
    }
}));

const  INPUT_STYLES = {background: '#DCDFE8', border: 'none', color: '#575F73'};

const AttributesDeck = ({
    setCustomAttributeForDeletion,
    attributes,
    setAttributes
}) => {
    const styles = useStyles();

    const onClickAddOptions = (attribute) => {
        const newAttributes = { ...attributes };
        if (newAttributes[attribute.id].selectedOptions) {
            if (attribute.isCustom) {
                newAttributes[attribute.id].selectedOptions.push({
                    id: newAttributes[attribute.id].selectedOptions.length + 1,
                    isCustom: true,
                    option: '',
                    attributeId: attribute.id,
                    attributeName: attribute.name
                })
            } else {
                newAttributes[attribute.id].selectedOptions.push({});
            }
        } else {
            if (attribute.isCustom) {
                newAttributes[attribute.id].selectedOptions = [{
                    id: 1,
                    isCustom: true,
                    option: '',
                    attributeId: attribute.id,
                    attributeName: attribute.name
                }];
            } else {
                newAttributes[attribute.id].selectedOptions = [{}];
            }
        }
        setAttributes(newAttributes);
    };

    return (
        <>
            {Object.values(attributes).map((attribute) => {
                return (
                    <div key={attribute.id} className={styles.card}>
                        <div className={styles.cardTitleContainer}>
                            <Typography
                                variant='body1'
                                component='div'
                                className={styles.cardTitle}
                            >
                                {attribute.name}
                            </Typography>
                            {attribute.required ?
                                <Typography
                                    className={styles.required}
                                    variant='body1'
                                    component='div'
                                >
                                    *
                                </Typography>
                                :
                                <IconButton onClick={() => {
                                    if (attribute.isCustom) {
                                        setCustomAttributeForDeletion(attribute)
                                    }
                                    const newAttributes = { ...attributes };
                                    delete newAttributes[attribute.id];
                                    setAttributes(newAttributes);
                                }}>
                                    <DeleteOutlineRoundedIcon className={styles.deleteButton} />
                                </IconButton>
                            }
                        </div>
                        {attribute.selectedOptions && attribute.selectedOptions.map((option, index) => {
                            return (
                                <div className={styles.autocompleteContainer} key={attribute.id + '' + option.id + index}>
                                    {attribute.isCustom ?
                                        <BrandInput
                                            style={INPUT_STYLES}
                                            variant='outlined'
                                            placeholder='Custom Option'
                                            value={option.option || ''}
                                            onChange={(e) => {
                                                setAttributes((prev) => {
                                                    const newAttributes = { ...prev };
                                                    newAttributes[attribute.id].selectedOptions[index].option = e.target.value;
                                                    newAttributes[attribute.id].selectedOptions[index].isCustom = true;
                                                    return newAttributes;
                                                });
                                            }}
                                        />
                                        :
                                        <AttributesDeckAutocomplete
                                            options={attribute?.options || []}
                                            placeholder='Select Option'
                                            property='option'
                                            value={option}
                                            hasSelectAll={true}
                                            selectedAttributes={attribute.selectedOptions}
                                            allAreSelected={(attribute?.options || []).filter(opt => !attribute.selectedOptions.find(selectedOpt => selectedOpt?.id === opt.id)).length === 0}
                                            setSelectedAttribute={(newValue) => {
                                                if (newValue.id === 0) {
                                                    return;
                                                }
                                                const newAttributes = { ...attributes };

                                                const selectedCUrrentOption = attribute.selectedOptions[index];
                                                if (selectedCUrrentOption && selectedCUrrentOption.isCustom) {
                                                    newAttributes[attribute.id].options = newAttributes[attribute.id].options.filter(opt => opt.id !== selectedCUrrentOption.id);
                                                }

                                                if (newValue?.option === 'Select All') {
                                                    newAttributes[attribute.id].selectedOptions = [...attribute.options];
                                                    setAttributes(newAttributes);
                                                    return;
                                                }
                                                newAttributes[attribute.id].selectedOptions[index] = newValue || {};
                                                setAttributes(newAttributes);
                                            }}
                                            onClickAddNewOption={(inputValue) => {
                                                let newAttributes = { ...attributes };
                                                const obj = {
                                                    option: inputValue,
                                                    id: attribute.options.length + 1,
                                                    isCustom: true,
                                                    attributeId: attribute.id,
                                                    attributeName: attribute.name
                                                };
                                                newAttributes[attribute.id].options.push(obj);
                                                newAttributes[attribute.id].selectedOptions.splice(index, 1, obj);
                                                setAttributes(newAttributes);
                                            }}
                                        />
                                    }
                                    <IconButton
                                    style={{padding: 0}}
                                        onClick={() => {
                                            const newAttributes = { ...attributes };
                                            newAttributes[attribute.id].selectedOptions.splice(index, 1);
                                            if (option.isCustom && !attribute.isCustom) {
                                                newAttributes[attribute.id].options = newAttributes[attribute.id].options.filter(opt => opt.id !== option.id);
                                            }
                                            setAttributes(newAttributes);
                                        }}
                                    >
                                        <DeleteOutlineRoundedIcon className={styles.deleteButton} />
                                    </IconButton>
                                </div>
                            )
                        })}
                        <Typography
                            className={clsx(styles.addOptionsButton)}
                            variant='body1'
                            component='button'
                            onClick={() => onClickAddOptions(attribute)}
                        >
                            + Add Options
                        </Typography>
                    </div>
                )
            })}
        </>
    );
}

export default AttributesDeck;