/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
import React, { useEffect, useState } from 'react';
import { BrandInput, BrandTextArea } from '../BrandInput';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { TertiaryBrandButton } from '../BrandButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { onGetAttributes } from '../../services/PanelService';
import AttributesDeck from './AttributesDeck'
import AddEditAttributeForm from './AddEditAttributeForm';
import { useStoreContext } from '../../../store/Store';

const useStyles = makeStyles((theme) => ({
    orangeTypography: {
        color: theme.palette.text.primary,
        fontWeight:  theme.palette.text.fontWeight.bold,
        fontSize: theme.palette.text.size.lg,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1.5),
    },
    addDescriptionButton: {
        color: theme.palette.text.primary,
        width: 'fit-content',
    },
    descriptionButton: {
        marginTop: theme.spacing(1.5),
        display: 'flex',
        alignItems: 'start',
        width: '90%'
    },
    saveChangesBtn: {
        color: theme.palette.text.successPrimary,
        marginLeft: theme.spacing(1),
        '&:hover': {
            color: theme.palette.text.successSecondary
        }
    },
    cancelBtn: {
        color: theme.palette.text.errorSecondary,
        marginLeft: theme.spacing(1),
        '&:hover': {
            color: theme.palette.text.error
        }
    },
    addNewAttribute: {
        fontSize: theme.palette.text.size.lg,
        width: '302px',
        padding: theme.spacing(2.5, 0),
        // paddingBottom: theme.spacing(2.5),
        backgroundColor: theme.palette.background.secondary,
        boxShadow: '0 10px 15px -3px #575F7340, 0 4px 6px -4px #575F7340',
        height: 'fit-content',
        '& .MuiButton-label': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            color: theme.palette.text.white,
            gap: theme.spacing(1),
            fontFamily: theme.palette.text.fontFamily.primary,
            '& span': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }
        },
        '&:hover': {
            background: theme.palette.background.blue,
        }
    },
    panelNameInput: {
        '& .MuiInputBase-root': {
            width: '250px',
        }
    },
    deck: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(2),
    },
    savedDescription: {
        display: 'flex',
        gap: theme.spacing(1),
        margin: theme.spacing(2, 0),
        alignItems: 'center',
        color: theme.palette.text.primary
    },
    description: {
        fontSize: theme.palette.text.size.sm,
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(4),
    },
    editIcon: {
        color: theme.palette.iconColor.success
    },
    textArea: {
        background: theme.palette.background.white,
        border: 'none',
        boxShadow: theme.palette.shadow.large,
        width: '70%'
    }
}));

const INPUT_STYLE = {background: 'transparent'};

function generateCombinations(attributes) {
    const combinations = [];
    const getCombinations = (attributes, index, currentCombination) => {
        if (index === attributes.length) {
            combinations.push(currentCombination);
            return;
        }
        const currentAttribute = attributes[index];
        if (currentAttribute.selectedOptions) {
            currentAttribute.selectedOptions.forEach((option) => {
                if (option.option) {
                    getCombinations(attributes, index + 1, currentCombination.concat(option));
                }
            });
        } else {
            getCombinations(attributes, index + 1, currentCombination);
        }
    };
    getCombinations(Object.values(attributes), 0, []);

    return combinations
        .filter((combination) => combination.length)
        .map((combination, index) => {
            return {
                combination: combination.map((attribute) => {
                    return `${attribute.attributeName}:${attribute.option}`;
                }).join('\n'),
                builtFrom: combination,
                numberOfPersonas: 1,
            };
        })
        .sort((a, b) => a.combination.localeCompare(b.combination))
        .map((combination, index) => {return {...combination, name: `Persona ${index + 1}`}});
};

function areArraysOfCombinationsEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
        return false;
    }
    
    const combinations1 = arr1.map(obj => obj.combination).sort();
    const combinations2 = arr2.map(obj => obj.combination).sort();
    
    for (let i = 0; i < combinations1.length; i++) {
        if (combinations1[i] !== combinations2[i]) {
            return false;
        }
    }
    
    return true;
}

const PanelForm = ({
    $panelName,
    savedDescription,
    setSavedDescription,
    attributesList,
    setAttributesList,
    attributes,
    setAttributes,
    setCustomAttributeForDeletion,
    setPossibleCombinations,
    step2Metadata,
    numberOfPersonas
}) => {
    const styles = useStyles();
    const [showDescriptionInput, setShowDescriptionInput] = useState(false);
    const [description, setDescription] = useState('');
    const [showAttributeForm, setShowAttributeForm] = useState(false);
    const [, setState] = useStoreContext();

    const toggleAttributeForm = () => setShowAttributeForm(!showAttributeForm);

    const prepareAttributes = async () => {
        if (attributesList.length) {
            return;
        }

        try {
            setState(state => (state.isLoading = true, { ...state }))
            const _attributesList = await onGetAttributes();
            setAttributesList(_attributesList);
            const requiredAttributesDic = {};
            _attributesList.forEach(attribute => {
                if (attribute.required) {
                    requiredAttributesDic[attribute.id] = attribute;
                }
            });
            setAttributes(requiredAttributesDic);
        } catch (e) {
            setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
            console.error(e?.response?.data || e);
        } finally {
            setState(state => (state.isLoading = false, { ...state }))
        }
    }

    const toggleDescriptionInput = () => {
        if (!showDescriptionInput) {
            setDescription(savedDescription);
        }
        setShowDescriptionInput(!showDescriptionInput)
    };

    const onClickCancelDescriptionEdit = (e) => {
        e.preventDefault();
        setDescription('');
        toggleDescriptionInput();
    };

    const onClickSaveDescriptionEdit = (e) => {
        e.preventDefault();
        setSavedDescription(description);
        setDescription('');
        toggleDescriptionInput();
    };

    useEffect(() => prepareAttributes(), []);

    useEffect(() => {
        const attributesWithOption = Object.values(JSON.parse(JSON.stringify(attributes)))
        attributesWithOption.forEach(attribute => {
            if (attribute.selectedOptions) {
                attribute.selectedOptions = attribute.selectedOptions.filter(option => option.option);
                if (attribute.selectedOptions.length === 0) {
                    delete attribute.selectedOptions
                }
            }
        });

        const _possibleCombinations = generateCombinations(attributesWithOption);
        if (step2Metadata && step2Metadata.possibleCombinations) {
            const step2MetadataPossibleCombinations = JSON.parse(step2Metadata.possibleCombinations);
        
            if (areArraysOfCombinationsEqual(_possibleCombinations, step2MetadataPossibleCombinations)) {
                setPossibleCombinations(step2MetadataPossibleCombinations);
            } else {
                setPossibleCombinations(_possibleCombinations);
                numberOfPersonas.current = _possibleCombinations.length
            }
        } else {
            numberOfPersonas.current = _possibleCombinations.length
            setPossibleCombinations(_possibleCombinations);
        }
    }, [attributes]);

    return (
        <>
            <div>
                <Typography
                    className={styles.orangeTypography}
                    variant='body1'
                    component='div'
                >
                    1. Select Name for this panel
                </Typography>
                <Typography
                    className={styles.description}
                    variant='body1'
                    component='div'
                >
                    Select a name for the panel and choose Attributes for the future Personas.
                </Typography>
                <div className={styles.panelNameInput}>
                    <BrandInput
                        style={INPUT_STYLE}
                        $value={$panelName}
                        placeholder='Enter Panel Name*'
                        variant='outlined'
                    />
                </div>
                {showDescriptionInput ?
                    <div className={styles.descriptionButton}>
                        <BrandTextArea
                            classes={{root: styles.textArea}}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder={`Click here to add text.\nMaximum length is 255 characters.`}
                            minRows={3}
                            limit={255}
                        />
                        <TertiaryBrandButton
                            className={styles.saveChangesBtn}
                            onClick={onClickSaveDescriptionEdit}
                        >
                            &#10004; Save Changes
                        </TertiaryBrandButton>
                        <TertiaryBrandButton
                            className={styles.cancelBtn}
                            onClick={onClickCancelDescriptionEdit}
                        >
                            &#10008; Cancel
                        </TertiaryBrandButton>
                    </div>
                    :
                    <TertiaryBrandButton
                        className={styles.addDescriptionButton}
                        onClick={toggleDescriptionInput}
                    >
                        {savedDescription ?
                            <div className={styles.savedDescription}>
                                <div>{savedDescription}</div>
                                <EditOutlinedIcon className={styles.editIcon} />
                            </div>
                            :
                            <span className={styles.savedDescription}>+ Add description (Optional)</span>}
                    </TertiaryBrandButton>
                }
            </div>
            <div className={styles.deck}>
                <AttributesDeck
                    setCustomAttributeForDeletion={setCustomAttributeForDeletion}
                    attributes={attributes}
                    setAttributes={setAttributes}
                />
                {showAttributeForm ?
                    <AddEditAttributeForm
                        toggleAttributeForm={toggleAttributeForm}
                        attributesList={attributesList}
                        attributes={attributes}
                        setAttributes={setAttributes}
                    />
                    :
                    <TertiaryBrandButton
                        classes={{root: styles.addNewAttribute}}
                        onClick={toggleAttributeForm}
                    >
                        Add attribute
                        <span><AddCircleOutlineIcon /></span>
                    </TertiaryBrandButton>
                }
            </div>
        </>
    );
}

export default PanelForm;