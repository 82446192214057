import LinearProgress from '@material-ui/core/LinearProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    linearDeterminate: {
        width: '50%',
        marginTop: theme.spacing(2),
        display: 'flex',
        gap: theme.spacing(2),
        alignItems: 'center',
            "& .MuiLinearProgress-colorPrimary": {
                backgroundColor: theme.palette.background.default,
            },
            "& .MuiLinearProgress-barColorPrimary": {
                backgroundColor: theme.palette.background.disabled
            },
    },
    linearDeterminateRoot: {
        width: '100%'
    },
    linearDeterminateLabel: {
        whiteSpace: 'nowrap',
        color: theme.palette.text.primary
    }
}));


const LinearDeterminate = ({ value, label }) => {
    const styles = useStyles();

    return (
        <div className={styles.linearDeterminate}>
            <Typography variant='body2' component='div' color='primary' className={styles.linearDeterminateLabel}>
                {label}: {Math.round(value)}%
            </Typography>
            <div className={styles.linearDeterminateRoot}>
                <LinearProgress variant="determinate" value={value} />
            </div>
        </div>
    );
}

export default LinearDeterminate;
