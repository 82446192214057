/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ListIcon from '@material-ui/icons/PlaylistAdd';
import { HistoryLists } from '../CoreComponents/HomeComponents.js/HistoryLists';
import { useHistory } from 'react-router-dom';
import { onGetStudies } from '../services/StudyService';
import { onGetPanels } from '../services/PanelService';
import { useStoreContext } from '../../store/Store';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    accentColoredTitle: {
        color: theme.palette.text.primary,
        fontSize: theme.palette.text.size.xl,
        fontWeight:  theme.palette.text.fontWeight.normal,
        fontFamily: theme.palette.text.fontFamily.primary
    },
    pageTitle: {
        color: theme.palette.text.primary,
        fontWeight:  theme.palette.text.fontWeight.bold,
        marginRight: theme.spacing(2),
        fontSize: theme.palette.text.size.xxl
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    card: {
        width: '233px',
        height: '233px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '20px',
        boxShadow: theme.palette.shadow.large,
        background: theme.palette.background.white,
        '&:hover': {
            cursor: 'pointer',
        }
    },
    deck: {
        display: 'flex',
        marginTop: theme.spacing(3.5),
        gap: theme.spacing(12)
    },
    lists: {
        marginTop: theme.spacing(3.5),
        display: 'flex',
        gap: theme.spacing(10)
    },
    icons: {
        color: theme.palette.iconColor.primary,
        fontSize: 68
    },
    bold: {
        fontWeight: theme.palette.text.fontWeight.bold
    }
}));

const Home = () => {
    const styles = useStyles();
    const [state, setState] = useStoreContext();
    const [recentPanels, setRecentPanels] = useState([])
    const [recentStudies, setRecentStudies] = useState([])
    const history = useHistory();

    const prepare = async () => {
        try {
            const studyData = await onGetStudies(null, 5);
            const panelData = await onGetPanels(null, 5);
            setRecentStudies(studyData || []);
            setRecentPanels(panelData || []);
        } catch (e) {
            setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
            console.error(e?.response?.data || e);
        }
    };

    useEffect(() => prepare(), []);

    const onClickCreatePanel = () => history.push('/panels?create=true');
    const onClickCreateStudy = () => history.push('/studies?create=true');

    return (
        <div>
            <div className={styles.titleContainer}>
                <Typography className={styles.pageTitle} variant="h3" >Welcome, </Typography>
                <Typography variant="h6" className={styles.accentColoredTitle}>{state.user ? `${state.user.first_name} ${state.user.last_name}` : 'Guest'}</Typography>
            </div>
            <div className={styles.deck}>
                <div className={styles.card} onClick={onClickCreatePanel}>
                    <PersonAddIcon  className={styles.icons} />
                    <Typography variant="h6" className={clsx(styles.accentColoredTitle, styles.bold)}>Create Panel</Typography>
                </div>
                <div className={styles.card} onClick={onClickCreateStudy}>
                        <ListIcon className={styles.icons} />
                    <Typography variant="h6" className={clsx(styles.accentColoredTitle, styles.bold)}>Create Study</Typography>
                </div>
            </div>
            <div className={styles.lists}>
                <HistoryLists title='Recent Panels' listItems={recentPanels} navigationPath='/panels' />
                <HistoryLists title='Recent Studies' listItems={recentStudies} navigationPath='/studies' />
            </div>
        </div>
    );
}


export default Home;

