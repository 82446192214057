

import { makeStyles } from '@material-ui/core/styles';
import { BrandTextArea, BrandSearchInput } from '../BrandInput';
import { useEffect, useMemo, useState } from 'react';
import { debounce } from '../../utils/FetchUtils';
import { IconButton, Typography } from '@material-ui/core';
import { TertiaryBrandButton } from '../BrandButton';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import clsx from 'clsx';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

const useStyles = makeStyles((theme) => ({
    attributesList: {
        width: 'calc(100% - 59px)',
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        overflowY: 'auto',
    },
    info: {
        color: theme.palette.text.white,
        fontSize: theme.palette.text.size.xs,
        fontWeight:  theme.palette.text.fontWeight.bold,
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        borderBottom: `2px solid ${theme.palette.border.success}`,
    },
    customBtn: {
        marginBottom: theme.spacing(2),
        color: theme.palette.text.successSecondary,
        fontSize: theme.palette.text.size.sm,
        boxShadow: 'none',
        backgorund: 'transparent',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        background: 'transparent',
        '& .MuiButtonBase-root': {
            '& .MuiButton-label': {
                display: 'flex',
                alignItems: 'center',
            },
        },
        '& .MuiSvgIcon-root': {
            height: '24px',
            width: '24px',
            color: theme.palette.text.successSecondary,
            marginLeft: theme.spacing(1)
        },
        '&:hover': {
            color: theme.palette.text.successPrimary,
            background: 'transparent',
            boxShadow: 'none',
            '& .MuiSvgIcon-root': {
                color: theme.palette.text.successPrimary,

            }
        }
    },
    selected: {
        marginLeft: theme.spacing(0.5),
        marginBottom: theme.spacing(1),
        color: theme.palette.text.disabled,
        fontSize: theme.palette.text.size.sm,
        cursor: 'default',
    },
    unselected: {
        marginLeft: theme.spacing(0.5),
        marginBottom: theme.spacing(1),
        cursor: 'default',
        color: theme.palette.text.white,
        fontSize: theme.palette.text.size.sm
    },
    isSelectable: {
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.8
        }
    },
    attributeInput: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    saveChangesBtn: {
        color: theme.palette.text.successSecondary,
        height: theme.spacing(3),
        width: theme.spacing(3),
        padding: 3,
        '&:hover': {
            // color: theme.palette.text.successPrimary,
            opacity: 0.8,
        }
    },
    cancelBtn: {
        color: theme.palette.text.error,
        height: theme.spacing(3),
        width: theme.spacing(3),
        padding: 3,
        '&:hover': {
            // color: theme.palette.text.error,
            opacity: 0.8,
        }
    },
    customEditableAttribute: {
        display: 'flex',
        gap: theme.spacing(0.5),
        alignItems: 'center',

    },
    lastCustomEditableAttribute: {
        marginBottom: theme.spacing(2.5),
    },
    disabledCustomEditableAttribute: {
        opacity: 0.6,
        cursor: 'default',
        '&:hover': {
            opacity: 0.6
        }
    },
    searchAttribute: {
        backgroundColor: 'white'
    },
    customAttribute: {
        color: theme.palette.text.disabled
    },
    editIcon: {
        color: theme.palette.iconColor.success
    }
}));

const AttributesList = ({ attributesList, selectedAttributes, setSelectedAttributes, showLeftSide, customAttributeForDeletion, setCustomAttributeForDeletion }) => {
    const styles = useStyles();
    const [search, setSearch] = useState('');
    const [searchFilter, setFilterSearch] = useState('');
    const [filteredAttributes, setFilteredAttributes] = useState([]);
    const [customAttributes, setCustomAttributes] = useState([]);
    const [showAttributeInput, setShowAttributeInput] = useState(false);
    const [attributeInput, setAttributeInput] = useState('');
    const [customAttributeForEdit, setCustomAttributeForEdit] = useState(null);

    const toggleAttributeInput = () => {
        setShowAttributeInput(!showAttributeInput)
    };

    const onClickCancelAttributeEdit = (e) => {
        e.preventDefault();
        if (customAttributeForEdit) {
            setCustomAttributeForEdit(null);
        } else {
            setAttributeInput('');
        }

        toggleAttributeInput();
    };

    const onClickSaveAttributeEdit = (e) => {
        e.preventDefault();

        if (customAttributeForEdit) {
            const newCustomAttributes = customAttributes.map((attribute) => {
                if (attribute.id === customAttributeForEdit.id) {
                    return customAttributeForEdit;
                }
                return attribute;
            });
            setCustomAttributes(newCustomAttributes);
            setCustomAttributeForEdit(null);
            setSelectedAttributes({ ...selectedAttributes, [customAttributeForEdit.id]: customAttributeForEdit });
            toggleAttributeInput();
            return;
        }

        const id = 'custom' + (filteredAttributes.length + 1);
        const newAttribute = {
            id: id,
            name: attributeInput,
            required: false,
            isCustom: true,
        };
        setCustomAttributes([...customAttributes, newAttribute]);
        setSelectedAttributes({ ...selectedAttributes, [id]: newAttribute })
        setAttributeInput('');
        toggleAttributeInput();
    };

    const debounceMemo = useMemo(
        () => debounce(
            (event) => {
                setSearch(event.target.value);
            }, (event) => {
                setFilterSearch(event.target.value);
            }, 500),
        [],
    );

    useEffect(() => {
        if (customAttributeForDeletion) {
            const newCustomAttributes = customAttributes.filter((attribute) => attribute.id !== customAttributeForDeletion.id);
            setCustomAttributes(newCustomAttributes);
            setCustomAttributeForDeletion(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customAttributeForDeletion]);

    useEffect(() => {
        if (searchFilter) {
            setFilteredAttributes([...attributesList, ...customAttributes]
                .filter((attribute) => attribute.name.toLowerCase().includes(searchFilter.toLowerCase()))
                .sort((a, b) => {
                    const aIsCustom = a.isCustom && !a.type;
                    const bIsCustom = b.isCustom && !b.type;
                    return aIsCustom ? -1 : bIsCustom ? 1 : a.required === true ? -1 : b.required === true ? 1 : a.id - b.id;
                }));
        } else {
            setFilteredAttributes([...attributesList, ...customAttributes]
                .sort((a, b) => {
                    const aIsCustom = a.isCustom && !a.type;
                    const bIsCustom = b.isCustom && !b.type;
                    return aIsCustom ? -1 : bIsCustom ? 1 : a.required === true ? -1 : b.required === true ? 1 : a.id - b.id;
                }));
        }
    }, [attributesList, customAttributes, searchFilter]);

    if (!showLeftSide) {
        return null;
    }

    return (
        <div className={styles.attributesList}>
            <BrandSearchInput
                variant='outlined'
                placeholder='Search Attributes'
                value={search}
                onChange={debounceMemo}
            />
            <Typography variant='subtitle2' component='div' className={styles.info}>
                Select an Attribute. Hover over attribute to view its possible options. You can always add a custom Attribute.
            </Typography>
            {showAttributeInput ?
                <div className={styles.attributeInput}>
                    <BrandTextArea
                        value={customAttributeForEdit ? customAttributeForEdit.name : attributeInput}
                        onChange={(e) => {
                            if (customAttributeForEdit) {
                                setCustomAttributeForEdit({ ...customAttributeForEdit, name: e.target.value });
                            } else {
                                setAttributeInput(e.target.value);
                            }
                        }}
                        minRows={1}
                        maxRows={1}
                        limit={255}
                    />
                    <IconButton
                        className={styles.saveChangesBtn}
                        onClick={onClickSaveAttributeEdit}
                    >
                        <CheckRoundedIcon />
                    </IconButton>
                    <IconButton
                        className={styles.cancelBtn}
                        onClick={onClickCancelAttributeEdit}
                    >
                        <ClearRoundedIcon />
                    </IconButton>
                </div>
                : <div>
                    <TertiaryBrandButton classes={{root: styles.customBtn}} onClick={toggleAttributeInput}>
                        <div className={styles.customBtnLabel}>Custom</div>
                        <AddCircleOutlineRoundedIcon className={styles.customBtnIcon} color='primary' />
                    </TertiaryBrandButton>
                </div>}
            <div>
                {filteredAttributes.map((attribute, index) => {
                    const isSelected = Boolean(selectedAttributes[attribute.id]);
                    const isRequired = attribute.required;
                    const isCustom = attribute.isCustom && !attribute.type;
                    const isLastCustom = filteredAttributes[index + 1] && !filteredAttributes[index + 1].isCustom;
                    const isSelectedForEdit = customAttributeForEdit && customAttributeForEdit.id === attribute.id;

                    if (isSelectedForEdit) {
                        return null;
                    }

                    if (isCustom) {
                        return (
                            <div
                                key={attribute.id}
                                className={clsx(
                                    styles.selected,
                                    styles.isSelectable,
                                    styles.customEditableAttribute,
                                    isLastCustom ? styles.lastCustomEditableAttribute : null,
                                    showAttributeInput ? styles.disabledCustomEditableAttribute : null
                                )}
                                onClick={() => {
                                    if (showAttributeInput) return;
                                    setCustomAttributeForEdit(attribute);
                                    toggleAttributeInput();
                                }}
                            >
                                <Typography
                                    variant='subtitle2'
                                    component='div'
                                    color='primary'
                                    className={styles.customAttribute}
                                >
                                    {attribute.name}
                                </Typography>
                                <EditOutlinedIcon className={styles.editIcon} color='primary' />
                            </div>
                        );
                    }

                    return (
                        <Typography
                            variant='subtitle2'
                            component='div'
                            key={attribute.id}
                            className={clsx(isSelected ? styles.selected : styles.unselected, isRequired ? null : styles.isSelectable)}
                            onClick={isRequired ? null : () => setSelectedAttributes({ ...selectedAttributes, [attribute.id]: attribute })}
                        >
                            {attribute.name}
                        </Typography>
                    );
                })}
            </div>
        </div>
    );
}

export default AttributesList;