import { Popover } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { ListItemText } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    listItem: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1.5, 2),
        borderBottom: `1px solid ${theme.palette.border.primary}`,
        '& .MuiListItemText-root': {
            display: 'flex',
            width: '100%',
            marginBottom: theme.spacing(2)
        },
        '& .MuiFormControl-root': {
            width: '100%'
        },
        '&:last-child': {
            borderBottom: 'none'
        },
        '& .MuiRadio-colorSecondary.Mui-checked': {
            color: theme.palette.text.primary
        },
        '& .MuiRadio-colorSecondary': {
            color: theme.palette.text.primary
        }
    },
    hr: {
        margin: theme.spacing(0),
        padding: theme.spacing(0),
        border: 'none',
        borderBottom: `1px solid ${theme.palette.border.primary}`,
        width: '350px',
    },
    list: {
        width: '350px'
    }
}));

const BrandSortOptions = ({ openDrawer, setOpenDrawer, sortOptions, selectedSortOptions, setSelectedSortOptions }) => {
    const styles = useStyles();

    return (
        <Popover
            anchorEl={openDrawer}
            keepMounted
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={Boolean(openDrawer)}
            onClose={() => setOpenDrawer(null)}
        >
            <List className={styles.list}>
                {sortOptions.map((option, index) => {
                    return (
                        <ListItem key={index} className={styles.listItem}>
                            <ListItemText primary={`Sort: ${option.label}`} />
                            <hr className={styles.hr} />
                            {option.type === 'radio' ?
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label={option.label}
                                        name={option.label}
                                        value={selectedSortOptions[option.label.toLowerCase()]}
                                        onChange={(event) =>
                                            setSelectedSortOptions({ ...selectedSortOptions, [option.label.toLowerCase()]: event.target.value })
                                        }
                                    >
                                        {option.options.map((opt, idx) =>
                                            <FormControlLabel
                                                key={idx}
                                                value={opt.sortType}
                                                control={<Radio />}
                                                label={opt.label}
                                            />
                                        )}
                                    </RadioGroup>
                                </FormControl>
                                :
                                null
                            }
                        </ListItem>
                    );
                })}
            </List>
        </Popover>
    );
}

export default BrandSortOptions;