
/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Typography } from '@material-ui/core';
import { BrandButton } from '../BrandButton';
import clsx from 'clsx';
import CircleComponent from '../PanelsComponents/CircleComponent';
import CirclesComponent from '../PanelsComponents/CirclesComponent';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Checkbox } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    actions: {
        display: 'flex',
        justifyContent: 'center',
        width: '90%',
        gap: theme.spacing(2),
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        '& .MuiButton-root:nth-child(1)': {
            '& .MuiButton-label': {
                color: theme.palette.text.disabled,
            }
        },
        '& .MuiButton-root:nth-child(2)': {
            '& .MuiButton-label': {
                color: theme.palette.text.secondary,
            },
            '&:hover': {
                background: theme.palette.background.blue
            }
        }
    },
    rightPanel: {
        width: '403px',
        borderLeft: `2px solid ${theme.palette.border.success}`,
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: theme.palette.background.dark
    },
    circleComponentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    legendLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(1),

    },
    possiblePersonasText: {
        color: theme.palette.text.white
    },
    legend: {
        width: '57px',
        height: '14px',
        borderRadius: '7px',
        color: theme.palette.text.white
    },
    stepper: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: theme.spacing(5),
        '& .MuiPaper-root': {
            backgroundColor: theme.palette.background.dark,
            width: '100%',
        },
    },
    primaryLegend: {
        backgroundColor: theme.palette.background.success,
    },
    secondaryLegend: {
        backgroundColor: theme.palette.background.white,
    },
    legends: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        marginTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        borderBottom: `2px solid ${theme.palette.border.success}`,
    },
    numberOfPersonasLimitSetter: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        '& .MuiIconButton-root': {
            padding: 0,
        },
        '& .MuiIconButton-root[disabled]': {
            color: theme.palette.text.textDisabledLighter,
        },
    },
    numberOfPersonasLimitSetterContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1.5),
        gap: theme.spacing(1),
    },
    regenerateBtn: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5)
    },
    activeStepText: {
        color: theme.palette.text.successSecondary,
        fontSize: theme.palette.text.size.sm
    },
    inactiveStepText: {
        color: theme.palette.text.disabled,
        fontSize: theme.palette.text.size.sm
    },
    activeStepIcon: {
        color: theme.palette.iconColor.success,
    },
    inactiveStepIcon: {
        width: 20,
        height: 20,
        borderRadius: '100%',
        background: theme.palette.background.black
    },
    legendText: {
        color: theme.palette.text.white,
    },
    RemovePersonIcon: {
        color: theme.palette.iconColor.success
    },
    addIcon: {
        color: theme.palette.iconColor.default
    },
    bottomContainer: {
        width: '90%'
    },
    checkboxLegend: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        marginTop: theme.spacing(2),
        '& .MuiCheckbox-root': {
            padding: 0,
            color: theme.palette.iconColor.successSecondary
        }
    },
    checkboxLabel: {
        color: theme.palette.text.white
    }
}));

const STEPS = ['Step 1', 'Step 2', 'Step 3'];

const RightPanel = ({
    activeStep,
    possibleCombinations,
    onClickPrevious, onClickNext,
    nextIsDisabled,
    numberOfPersonas,
    onClickRegenerate,
    numberOfPersonasLimit,
    setNumberOfPersonasLimit,
    useTheNewWay,
    setUseTheNewWay
}) => {
    const styles = useStyles();

    return (
        <div className={styles.rightPanel}>
            <div className={styles.stepper}>
                <Stepper activeStep={activeStep} alternativeLabel>
                    {STEPS.map((label, index) => (
                        <Step key={label}>
                            <StepLabel classes={{ iconContainer: styles.iconContainer }} icon={activeStep >= index ? <CheckCircleIcon className={styles.activeStepIcon} /> : <div className={styles.inactiveStepIcon} />}>
                                <Typography className={activeStep >= index ? styles.activeStepText : styles.inactiveStepText}>{label}</Typography></StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </div>
            <div className={styles.bottomContainer}>
                <div className={styles.circleComponentContainer}>
                    {activeStep === 0 && <CircleComponent number={possibleCombinations.length} />}
                    {(activeStep === 1 || activeStep === 2) && <CirclesComponent circle1={numberOfPersonas.current} circle2={numberOfPersonasLimit} />}
                    <div className={styles.legends}>
                        <div className={styles.legendLabel}>
                            <div className={clsx(styles.legend, styles.secondaryLegend)} />
                            <Typography className={styles.possiblePersonasText} variant="body1" component='div'>personas to be generated</Typography>
                        </div>
                        {activeStep === 1 && <div className={styles.checkboxLegend}>
                            <Checkbox
                                checked={useTheNewWay}
                                onChange={(e) => {
                                    setUseTheNewWay(e.target.checked)
                                }}
                            />
                            <Typography variant="body1" component='div' className={styles.checkboxLabel}>
                                Generate the personas via the new way
                            </Typography>
                        </div>}
                    </div>
                </div>
                {/* TODO: AFTER MVP ADD ADD/VIEW SPECIFICATION BUTTON FUNCTIONALITY AND STYLING*/}
                {activeStep === 1 &&
                    <div className={styles.numberOfPersonasLimitSetterContainer}>
                        <Typography className={styles.legendText} variant="body2" component='div'>Select total number of Personas that will be randomly generated.</Typography>
                        <div className={styles.numberOfPersonasLimitSetter}>
                            <HoldableIconButton
                                numberOfPersonasLimit={numberOfPersonasLimit}
                                setNumberOfPersonasLimit={setNumberOfPersonasLimit}
                                subtract={true}
                            />
                            <Typography variant="body1" component='div'>{numberOfPersonasLimit}</Typography>
                            <HoldableIconButton
                                numberOfPersonasLimit={numberOfPersonasLimit}
                                setNumberOfPersonasLimit={setNumberOfPersonasLimit}
                                subtract={false}
                                numberOfPersonas={numberOfPersonas.current}
                            />
                        </div>
                    </div>
                }
                {activeStep === 2 &&
                    <div className={styles.regenerateBtn}>
                        <BrandButton onClick={onClickRegenerate}>
                            Regenerate
                        </BrandButton>
                    </div>
                }
                <div className={styles.actions}>
                    <BrandButton variant='outlined' onClick={onClickPrevious}>
                        Previous
                    </BrandButton>
                    <BrandButton
                        disabled={nextIsDisabled}
                        onClick={onClickNext}>
                        Next Step
                    </BrandButton>
                </div>
            </div>
        </div>
    );
};

export default RightPanel;

const HoldableIconButton = ({ numberOfPersonasLimit, setNumberOfPersonasLimit, subtract, numberOfPersonas }) => {
    const styles = useStyles();

    const [timer, setTimer] = useState(null);

    const startDecrement = () => {
        const intervalTimer = setInterval(() => applyCalculations(subtract), 100);
        setTimer(intervalTimer);
    };

    const stopDecrement = () => {
        clearInterval(timer);
    };

    const applyCalculations = (subtract) => {
        if (subtract) {
            setNumberOfPersonasLimit(prevLimit => prevLimit > 0 ? prevLimit - 1 : prevLimit);
        } else {
            setNumberOfPersonasLimit(prevLimit => prevLimit < 250 ? prevLimit + 1 : prevLimit);
        }
    }

    return (
        <IconButton
            disabled={(subtract && numberOfPersonasLimit === 0) || (!subtract && (numberOfPersonasLimit === Math.min(numberOfPersonas, 250)))}
            color='primary'
            onMouseDown={startDecrement}
            onMouseUp={stopDecrement}
            onMouseLeave={stopDecrement}
        >
            {subtract ?
                <RemoveCircleOutlineRoundedIcon className={styles.RemovePersonIcon} />
                :
                <AddCircleOutlineRoundedIcon className={styles.addIcon} />
            }
        </IconButton>
    );
}

