import axios from 'axios';
import { getToken } from '../utils/FetchUtils';

export const onGetAttributes = async () => {
    const authorizationToken = getToken();
    const res = await axios.get(`${process.env.REACT_APP_HOST}/api/panel/get-attributes`, {
        headers: { Authorization: authorizationToken }
    });
    return res.data;
};

export const onGeneratePersonas = async (
    name,
    description,
    attributes,
    templates,
    panel_id,
    panelsAttributesWereEdited,
    panelNameOrDescriptionWasEdited,
    useTheNewWay
) => {
    const authorizationToken = getToken();
    const reqBody = {
        name,
        description,
        templates,
        attributes,
        panel_id,
        panelWasEdited: panelNameOrDescriptionWasEdited,
        panelsAttributesWereEdited,
        newWay: useTheNewWay
    };
    const res = await axios.post(`${process.env.REACT_APP_HOST}/api/panel/generate-personas`, reqBody, {
        headers: { Authorization: authorizationToken }
    });
    return { panelId: res.data.panelId, personas: res.data.personas};
};

export const onDeletePersonas = async (personaIds, panelId) => {
    const authorizationToken = getToken();

    const res = await axios.delete(`${process.env.REACT_APP_HOST}/api/panel/delete-personas`, {
        data: { personaIds, panelId },
        headers: { Authorization: authorizationToken }
    });
    return res.data;
};

export const onGetPanels = async (id, limit, searchFilter, dateSortType, nameSortType) => {
    const authorizationToken = getToken();
    const params = `?id=${id || ''}&limit=${limit || ''}&filter=${searchFilter || ''}&dateSortType=${dateSortType || ''}&nameSortType=${nameSortType || ''}`;
    const res = await axios.get(`${process.env.REACT_APP_HOST}/api/panel/get-panels${params}`, {
        headers: { Authorization: authorizationToken }
    });
    return res.data;
}

export const onDeletePanel = async (id) => {
    const authorizationToken = getToken();
    const res = await axios.delete(`${process.env.REACT_APP_HOST}/api/panel/delete-panel`, {
        data: { id },
        headers: { Authorization: authorizationToken }
    });
    return res.data;
};

export const onGetPersonas = async (id) => {
    const authorizationToken = getToken();
    const res = await axios.get(`${process.env.REACT_APP_HOST}/api/panel/get-personas`, {
        params: { id },
        headers: { Authorization: authorizationToken }
    });
    return res.data;
};


export const onPanelSetup = async (panelId) => {
    const authorizationToken = getToken();
    const res = await axios.get(`${process.env.REACT_APP_HOST}/api/panel/get-panel-data`, {
        params: { id: panelId},
        headers: { Authorization: authorizationToken }
    });
    return res.data;
}