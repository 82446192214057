/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {  } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { BrandLink } from '../BrandLink';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PanelForm from '../PanelsComponents/PanelForm';
import TemplatesDeck from '../PanelsComponents/TemplatesDeck';
import PersonasDeck from '../PanelsComponents/PersonasDeck';

const useStyles = makeStyles((theme) => ({
    breadcrumbs: {
        color: theme.palette.text.primary,
        '& .MuiTypography-root': {
            fontSize: theme.spacing(1.6),
        }
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
    },
    createPanelLayout: {
        width: 'calc(100% - 325px - 300px)',
        padding: theme.spacing(3),
        overflow: 'auto'
    },
    extendedCreatePanelLayout: {
        width: 'calc(100% - 325px - 59px)'
    },
    createPanelLayoutWithoutFilters: {
        width: 'calc(100% - 325px)',
        padding: theme.spacing(3),
        overflow: 'auto'
    },
    pageHead: {
        color: theme.palette.text.primary,
        fontSize: theme.palette.text.size.sm
    },
    boldedBrandLink: {
        fontWeight:  theme.palette.text.fontWeight.bold,
    }
}));

const MiddlePanel = ({
    showLeftSide,
    activeStep,
    $panelName,
    savedDescription,
    setSavedDescription,
    attributesList,
    setAttributesList,
    attributes,
    setAttributes,
    setCustomAttributeForDeletion,
    selectedFilters,
    setPossibleCombinations,
    possibleCombinations,
    generatedPersonas,
    setGeneratedPersonas,
    setActiveStep,
    panelId,
    step2Metadata,
    numberOfPersonas
}) => {
    const styles = useStyles();

    const onClickBrandLink = (e, step) => {
        e.preventDefault();
        setActiveStep(step);
    };

    return (
        <div className={clsx(
            activeStep === 2 ? styles.createPanelLayoutWithoutFilters : //TODO: AFTER MVP ADD FILTRATION FOR PERSONAS
                styles.createPanelLayout,
            activeStep === 2 ? null : //TODO: AFTER MVP ADD FILTRATION FOR PERSONAS
                showLeftSide ? null : styles.extendedCreatePanelLayout
        )}>
            <Breadcrumbs separator=">" aria-label="breadcrumb" className={styles.breadcrumbs}>
                <BrandLink to='/panels' className={styles.pageHead} variant='body1'>Panels</BrandLink>
                <BrandLink to='/panels?create=true' onClick={(e) => e.preventDefault()} className={styles.pageHead} variant='body1'>Create Panel</BrandLink>
                {activeStep === 0 ? <BrandLink to='/panels?create=true' onClick={(e) => onClickBrandLink(e, 0)} className={clsx(styles.pageHead, styles.boldedBrandLink)} variant='body1'>Step 1</BrandLink> : null}
                {activeStep === 1 ? <BrandLink to='/panels?create=true' onClick={(e) => onClickBrandLink(e, 1)} className={clsx(styles.pageHead, styles.boldedBrandLink)} variant='body1'>Step 2</BrandLink> : null}
                {activeStep === 2 ? <BrandLink to='/panels?create=true' onClick={(e) => onClickBrandLink(e, 2)} className={clsx(styles.pageHead, styles.boldedBrandLink)} variant='body1'>Step 3</BrandLink> : null}
            </Breadcrumbs>
            <div className={styles.content}>
                {activeStep === 0 && <PanelForm
                    $panelName={$panelName}
                    savedDescription={savedDescription}
                    setSavedDescription={setSavedDescription}
                    attributesList={attributesList}
                    setAttributesList={setAttributesList}
                    attributes={attributes}
                    step2Metadata={step2Metadata}
                    setAttributes={setAttributes}
                    setCustomAttributeForDeletion={setCustomAttributeForDeletion}
                    setPossibleCombinations={setPossibleCombinations}
                    numberOfPersonas={numberOfPersonas}
                />}
                {activeStep === 1 && <TemplatesDeck
                    selectedFilters={selectedFilters}
                    possibleCombinations={possibleCombinations}
                    setPossibleCombinations={setPossibleCombinations}
                    attributes={attributes}
                    numberOfPersonas={numberOfPersonas}
                />}
                {activeStep === 2 && <PersonasDeck
                    generatedPersonas={generatedPersonas}
                    setGeneratedPersonas={setGeneratedPersonas}
                    panelId={panelId}
                />}
            </div>
        </div>
    );
};

export default MiddlePanel;