import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { BrandTextArea } from '../BrandInput';
import { useEffect, useState } from 'react';
import { IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { BrandLink } from '../BrandLink';
import { TertiaryBrandButton } from '../BrandButton';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    saveChangesBtn: {
        color: theme.palette.text.successPrimary,
        marginLeft: theme.spacing(1),
        '&:hover': {
            color: theme.palette.text.successSecondary
        }
    },
    cancelBtn: {
        color: theme.palette.text.errorSecondary,
        marginLeft: theme.spacing(1),
        '&:hover': {
            color: theme.palette.text.error
        }
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(1),
    },
    listItemText: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(1.3),
        color: theme.palette.text.primary,
        '& .MuiInputBase-root': {
            backgroundColor: theme.palette.background.primary,
        },
    },
    answerOptions: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1.5),
        '& .MuiIconButton-root': {
            padding: '5px',
            '& .MuiSvgIcon-root': {
                color: theme.palette.text.error,
            }
        },
        '& .MuiLink-root': {
            height: '52px',
            marginTop: theme.spacing(2),
            minWidth: 'fit-content',
        }
    },
    primaryText: {
        '& .MuiInputBase-root': {
            width: theme.spacing(60),
        }
    },
    listItem:{
        borderBottom: `1px solid ${theme.palette.border.primary}`,
    }
}));

const LINK_COLOR = '#575F73';

const AddEditQuestionListItemForm = ({ toggleQuestionForm, setQuestions, questions, questionForEdit, questionForEditIndex, onCustomSubmitForm }) => {
    const styles = useStyles();
    const [question, setQuestion] = useState('');
    const [answerOptions, setAnswerOptions] = useState([]);

    const onSubmitForm = (e) => {
        const study = {
            answerOptions: answerOptions,
            question: question
        }

        if (onCustomSubmitForm) {
            onCustomSubmitForm(study, questionForEdit, questionForEditIndex, toggleQuestionForm);
            return;
        }

        if (questionForEdit && questionForEditIndex !== undefined) {
            const newQuestions = [...questions];
            newQuestions[questionForEditIndex] = study;
            setQuestions(newQuestions);
            toggleQuestionForm();
            return;
        }

        setQuestions([...questions, study]);
        toggleQuestionForm();
    };

    const addBlankAnswerOption = (e) => {
        e.preventDefault();
        setAnswerOptions([...answerOptions, '']);
    };

    const formIsDisabled = () => {
        if (question.length === 0) {
            return true;
        }
        for (let i = 0; i < answerOptions.length; i++) {
            if (answerOptions[i].length === 0) {
                return true;
            }
        }
        return false;
    }

    const onClickDelete = (e) => {
        const newOptions = [...answerOptions];
        newOptions.splice(answerOptions.length - 1, 1);
        setAnswerOptions(newOptions);
    };

    useEffect(() => {
        if (questionForEdit) {
            setQuestion(questionForEdit.question);
            setAnswerOptions(questionForEdit.answerOptions.map((option) => {
                return option;
            }));
        }

    }, [questionForEdit]);

    const isLastElement = questionForEditIndex + 1 === questions.length;
    return (
        <ListItem className={isLastElement ? null : styles.listItem}>
            <ListItemText
                primary={
                    <div className={clsx(styles.listItemText, styles.primaryText)}>
                        <BrandTextArea
                            value={question}
                            rows={1}
                            onChange={(e) => setQuestion(e.target.value)}
                            placeholder='Type Question Here'
                            required
                        />
                        <div className={styles.actions}>
                            <TertiaryBrandButton
                                className={styles.saveChangesBtn}
                                onClick={onSubmitForm}
                                disabled={formIsDisabled()}
                            >
                                &#10004; Save Changes
                            </TertiaryBrandButton>
                            <TertiaryBrandButton
                                className={styles.cancelBtn}
                                onClick={toggleQuestionForm}
                            >
                                &#10008; Cancel
                            </TertiaryBrandButton>
                        </div>
                    </div>
                }
                secondary={
                    <div className={styles.answerOptions}>
                        {answerOptions.map((option, index) => {
                            return (
                                <div className={styles.listItemText}>
                                    <BrandTextArea
                                        value={option}
                                        rows={1}
                                        onChange={(e) => {
                                            const newOptions = [...answerOptions];
                                            newOptions[index] = e.target.value;
                                            setAnswerOptions(newOptions);
                                        }}
                                        placeholder='Type a possible answer here'
                                        required
                                    />
                                    <IconButton onClick={onClickDelete}>
                                        <ClearIcon />
                                    </IconButton>
                                </div>
                            );
                        })}
                        {answerOptions.length < 4 ?
                            <BrandLink style={{color: LINK_COLOR}} to='/' onClick={addBlankAnswerOption} color='primary' variant='body1'>&#x2022; Add New</BrandLink>
                            :
                            null
                        }
                    </div>
                }
            />
        </ListItem>
    );
}

export default AddEditQuestionListItemForm;