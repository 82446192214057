import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { BrandButton } from './BrandButton';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    dialog: {
        '& .MuiDialog-paper': {
            backgroundColor: theme.palette.background.white,
            border: `1px solid ${theme.palette.border.primary}`,
            borderRadius: theme.spacing(0.8),
            width: 'fit-content',
            height: 'fit-content',

        }
    },
    loadingDialog: {
        '& .MuiDialog-paper': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            border: 'none',
            width: 'fit-content',
            height: 'fit-content',
        }
    },
    title: {
        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        borderBottom: `2px solid ${theme.palette.border.primary}`,
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        paddingTop: theme.spacing(0.8),
        paddingBottom: theme.spacing(0.8),
        '& .MuiSvgIcon-root': {
            height: theme.spacing(1.5),
            width: theme.spacing(1.5),
        }
    },
    withPadding: {
        padding: theme.spacing(1.5),
        paddingTop: theme.spacing(0.2),
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(1),
        '& .MuiButton-root': {
            width: 'fit-content',
        },
        '& .MuiButton-root:nth-child(1)': {
            '& .MuiButton-label': {
                color: theme.palette.text.white,
            }
        }
    },
    successDialog: {
        '& .MuiDialog-paper': {
            backgroundColor: theme.palette.background.white,
            border: `1px solid ${theme.palette.text.successPrimary}`,
            borderRadius: theme.spacing(0.8),
            width: 'fit-content',
            height: 'fit-content',

        }
    },
    successDialogActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(1),
        '& .MuiButton-root': {
            width: 'fit-content',
            backgroundColor: theme.palette.text.successSecondary,
            border: 'none'
        },
        '& .MuiButton-root:nth-child(1)': {
            '& .MuiButton-label': {
                color: theme.palette.text.black,
            }
        }
    },
    successTitle: {
        color: theme.palette.text.successSecondary,
    },
    warningDialog: {
        '& .MuiDialog-paper': {
            backgroundColor: theme.palette.background.white,
            border: `1px solid ${theme.palette.border.error}`,
            borderRadius: theme.spacing(0.8),
            width: 'fit-content',
            height: 'fit-content',

        }
    },
    warningDialogActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(1),
        '& .MuiButton-root': {
            width: 'fit-content',
        },
        '& .MuiButton-root:nth-child(1)': {
            borderColor: theme.palette.text.error,
            backgroundColor: 'transparent',
            '& .MuiButton-label': {
                color: theme.palette.text.dark,
            }
        },
        '& .MuiButton-root:nth-child(2)': {
            backgroundColor: theme.palette.text.error,
            borderColor: theme.palette.text.error,
            '& .MuiButton-label': {
                color: theme.palette.text.dark,
            }
        }
    },
    warningTitle: {
        color: theme.palette.text.error,
        fontSize: theme.palette.text.size.lg,
        fontWeight:  theme.palette.text.fontWeight.semiBold,
    },
    defaultTitle: {
        color: theme.palette.text.primary,
        fontWeight:  theme.palette.text.fontWeight.bold,
    },
}));


export function BrandModal({
    open,
    onClose,
    clickAwayClosable,
    title,
    titleWithDot,
    hasPadding = true,
    leftBtnText,
    rightBtnText,
    leftBtnAction,
    rightBtnAction,
    children,
    isLoaderModal,
    isSuccessModal,
    isWarningModal
}) {
    const styles = useStyles();
    return (
        <Dialog
            open={open}
            onClose={clickAwayClosable ? null : onClose}
            TransitionComponent={Transition}
            maxWidth={false}
            className={isWarningModal ? styles.warningDialog : isSuccessModal ? styles.successDialog : isLoaderModal ? styles.loadingDialog : styles.dialog}
        >
            <div >
                {title ?
                    <div className={styles.title}>
                        {titleWithDot ? <FiberManualRecordIcon className={isWarningModal ? styles.warningTitle : isSuccessModal ? styles.successTitle : styles.defaultTitle} /> : null}
                        <Typography variant="body1" className={isWarningModal ? styles.warningTitle : isSuccessModal ? styles.successTitle : styles.defaultTitle}>
                            {title}
                        </Typography>
                    </div>
                    :
                    null
                }
                <div className={hasPadding ? styles.withPadding : null}>
                    {children}
                    {leftBtnText || rightBtnText ?
                        <div className={isWarningModal ? styles.warningDialogActions : isSuccessModal ? styles.successDialogActions : styles.actions}>
                            {leftBtnText ?
                                <BrandButton variant='outlined' onClick={leftBtnAction}>
                                    {leftBtnText}
                                </BrandButton>
                                :
                                null
                            }
                            {rightBtnText ?
                                <BrandButton onClick={rightBtnAction}>
                                    {rightBtnText}
                                </BrandButton>
                                :
                                null
                            }
                        </div>
                        :
                        null
                    }
                </div>
            </div>
        </Dialog>
    );
}