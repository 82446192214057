/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { ListItemText, Typography, makeStyles } from '@material-ui/core';
import { BrandMenuItem, BrandSelect } from '../BrandSelect';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.text.primary,
        fontSize: '20px'
    },
    head: {
        borderBottom: `2px solid ${theme.palette.border.primary}`
    },
    subtitle: {
        color: theme.palette.text.primary,
        fontSize: '16px',
        marginTop: theme.spacing(2)
    },
    closeIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '5px',
        position: 'absolute',
        top: '10px',
        right: '10px',
        color: theme.palette.text.error,
        cursor: 'pointer'
    },
    usersAnswersContainer: {
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '5px',
        marginTop: theme.spacing(2),
    },
    answersContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: theme.spacing(1)
    },
    answersContent: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column'
    },
    actions: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: '5px'
    },
    openDescription: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: theme.spacing(1),
        color: theme.palette.text.primary,
        cursor: 'pointer'
    },
    personaDescription: {
        fontSize: '18px',
        fontWeight: '500',
        marginBottom: theme.spacing(1),
        color: theme.palette.text.primary,

    },
    personaName: {
        fontSize: '18px',
        fontWeight: '500',
        marginBottom: theme.spacing(1)
    },
    personaAnswer: {
        fontSize: '14px',
        fontWeight: '400'
    },
    dialog: {
        '& .MuiDialog-paper': {
            border: `2px solid ${theme.palette.border.primary}`,
            width: '930px',
            height: '648px'
        }
    },
    spreadAnswers: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
    },
    answerOptions: {
        display: 'flex',
        gap: theme.spacing(2.5),
        marginLeft: theme.spacing(2.5),
    },
    answerOption: {
        display: 'flex',
        gap: theme.spacing(0.5),
        alignItems: 'center',
    },
    answerOptionColor: {
        color: theme.palette.text.primary,
    },
    dotIconStyle: {
        color: theme.palette.iconColor.secondary,
        width: '10px',
        height: '10px',
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SELECT_STYLES = {background: '#DCDFE8', border: 'none', color: '#575F73'};

export default function QuestionModal({ openQuestionModal, handleCloseQuestionModal, usersAnswers, question, questionIndex, personas, answersOptions }) {
    const [selectValue, setSelectValue] = useState('all');
    const [filteredAnswers, setFilteredAnswers] = useState([]);

    const displayPersonaName = (personaId, fullNameDisplay) => {
        const persona = personas.find(persona => persona.id === personaId);
        const personaName = persona.Name;

        if (fullNameDisplay && persona) return persona.Name;
        if (persona && personaName.includes(',')) return persona.Name.split(",", 2)[0];
        if (persona && personaName.includes("-")) return persona.Name.split("-", 2)[0];

        return personaName
    };

    const displayPersonaDescription = (personaId) => {
        const persona = personas.find(persona => persona.id === personaId);
        const personaName = persona.Name;

        if (persona && personaName.includes(',')) return persona.Name.split(",", 2)[1];
        if (persona && personaName.includes("-")) return persona.Name.split("-", 2)[1];
        
        return ''
    }

    useEffect(() => {
        if (selectValue === 'all') {
            setFilteredAnswers([...usersAnswers]);
        } else {
            const filter = usersAnswers.filter(userAnswer => userAnswer.persona_id === selectValue);
            setFilteredAnswers([...filter]);
        }
    }, [selectValue])

    const handleChangeSelectValue = (newValue) => {
        setSelectValue(newValue);
    };

    const handleClose = () => {
        handleCloseQuestionModal();
        setFilteredAnswers([]);
        setSelectValue('all');
    };

    const styles = useStyles();
    return (
        <Dialog
            open={openQuestionModal}
            TransitionComponent={Transition}
            className={styles.dialog}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <div className={styles.closeIcon} onClick={handleClose}>
                <span>Close</span>
                <CloseRoundedIcon />
            </div>
            <DialogTitle id="alert-dialog-slide-title" className={styles.head}>
                <Typography className={styles.title} variant='body2' component='div'>
                    Question:
                </Typography>
                {
                    answersOptions.length > 0 ?
                    <ListItemText
                    primary={`${questionIndex + 1}. ${question}`}
                    secondary={
                        <div className={styles.answerOptions}>
                            {answersOptions.map((option, index) =>
                                <div key={index} className={styles.answerOption}>
                                    <FiberManualRecordIcon className={styles.dotIconStyle} />
                                    <Typography className={styles.answerOptionColor} variant="body1" component='div' color='primary'>{option}</Typography>
                                </div>
                            )}
                        </div>
                    }
                />
                :
                <Typography className={styles.subtitle} variant='body2' component='div'>
                    {questionIndex + 1}. {question}
                </Typography>
                }
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <BrandSelect inputStyles={SELECT_STYLES} value={selectValue} select onChange={handleChangeSelectValue}>
                        <BrandMenuItem value={'all'}>
                            All Personas
                        </BrandMenuItem>
                        {usersAnswers.map(person => {
                            return (
                                <BrandMenuItem key={person.persona_id} value={person.persona_id}>
                                    {displayPersonaName(person.persona_id, true)}
                                </BrandMenuItem>
                            )
                        })}
                    </BrandSelect>
                    <div className={styles.usersAnswersContainer}>
                        {((filteredAnswers && filteredAnswers.length > 0 && filteredAnswers) ||
                            (filteredAnswers.length === 0 && usersAnswers)).map((persona, index) => {
                                const is_open_ended = persona.is_open_ended;
                                return (
                                    <div key={index} className={!is_open_ended ? styles.spreadAnswers : null}>
                                        <div className={styles.answersContainer} key={index}>
                                            <Typography>{index + 1}.</Typography>
                                            <div className={styles.answersContent} key={index}>
                                                <div className={styles.actions}>
                                                    <Typography className={styles.personaName} variant='body2' component='div'>
                                                        {displayPersonaName(persona.persona_id)},
                                                    </Typography>
                                                    <Typography variant='body2' component='div' className={styles.personaDescription}>
                                                        {displayPersonaDescription(persona.persona_id)}
                                                    </Typography>
                                                </div>
                                                {!is_open_ended ? null :
                                                    <Typography className={styles.personaAnswer}>{persona.answer}</Typography>}
                                            </div>
                                        </div>
                                        {is_open_ended ? null :
                                            <Typography variant='body2' component='div' className={styles.personaAnswer}>{persona.answer}</Typography>}
                                    </div>
                                );
                            })}
                    </div>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}