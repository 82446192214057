/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */

import { onCreateOrUpdateStudy, onDeleteStudy, onGetStudies } from "../services/StudyService";
import { useStoreContext } from "../../store/Store";
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { BrandLink } from '../CoreComponents/BrandLink';
import { BrandTextArea } from '../CoreComponents/BrandInput';
import { makeStyles } from '@material-ui/core/styles';
import { ClickAwayListener, IconButton, Typography } from '@material-ui/core';
import { BrandButton, TertiaryBrandButton } from '../CoreComponents/BrandButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import QuestionsDeck from '../CoreComponents/StudiesComponents/QuestionsDeck';
import QuestionsList from '../CoreComponents/StudiesComponents/QuestionsList';
import AddEditQuestionForm from '../CoreComponents/StudiesComponents/AddEditQuestionForm';
import { format } from 'date-fns'
import clsx from 'clsx';
import { labelParser } from "../utils/basicFunctions";
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import { BrandModal } from "../CoreComponents/BrandModal";
import { BrandAlert } from "../CoreComponents/BrandAlert";

const formatDate = (date) => {
    return format(new Date(date), 'dd.MM.yyyy');
};

const useStyles = makeStyles((theme) => ({
    createStudyPageLayout: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    studyPageLayout: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline',
    },
    breadcrumbs: {
        color: theme.palette.text.primary,
        fontSize: theme.spacing(1.6),
        '& .MuiTypography-root': {
            fontSize: theme.spacing(1.6),
        }
    },
    orangeTypography: {
        color: theme.palette.text.primary,
        fontWeight:  theme.palette.text.fontWeight.bold,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1.5),
    },
    addDescriptionButton: {
        color: theme.palette.text.primary,
        width: 'fit-content',
    },
    studyForm: {
        display: 'flex',
        flexDirection: 'column',
    },
    descriptionButton: {
        marginTop: theme.spacing(1.5),
        display: 'flex',
        alignItems: 'top',
        width: theme.spacing(96)
    },
    contextButton: {
        marginTop: theme.spacing(1.5),
        display: 'flex',
        alignItems: 'top',
        justifyContent: 'flex-end',
        width: theme.spacing(96)
    },
    saveChangesBtn: {
        color: theme.palette.text.successPrimary,
        marginLeft: theme.spacing(1),
        '&:hover': {
            color: theme.palette.text.successSecondary
        }
    },
    cancelBtn: {
        color: theme.palette.text.errorSecondary,
        marginLeft: theme.spacing(1),
        '&:hover': {
            color: theme.palette.text.error
        }
    },
    addNewQuestion: {
        color: theme.palette.text.white,
        width: '250px',
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
        backgroundColor: theme.palette.background.secondary,
        boxShadow: theme.palette.shadow.large,
        marginTop: theme.spacing(2),
        height: 'fit-content',
        '& .MuiButton-label': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            gap: theme.spacing(1),
            '& span': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }
        },
        '&:hover': {
            background: theme.palette.background.blue,
        }
    },
    studyNameInput: {
        '& .MuiInputBase-root': {
            width: '250px',
        }
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'flex-end',
        height: theme.spacing(2.5),
    },
    active: {
        color: theme.palette.text.successSecondary
    },
    inactive: {
        color: theme.palette.text.primary
    },
    deck: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(2),
    },
    list: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(3),
        '& .MuiButton-root': {
            width: 'fit-content',
        },
        '& .MuiButton-root:nth-child(1)': {
            '& .MuiButton-label': {
                color: theme.palette.text.primary,
            }
        }
    },
    warning: {
        marginBottom: theme.spacing(5),
        marginRight: theme.spacing(5),
        fontSize: theme.palette.text.size.md
    },
    warningSubText: {
        color: theme.palette.text.error
    },
    savedDescription: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        margin: theme.spacing(2, 0),
        color: theme.palette.text.primary
    },
    oldSavedDescription: {
        gap: theme.spacing(0),
        marginRight: '-16px',
    },
    addButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        '& .MuiButton-root': {
            '& .MuiButton-label': {
                justifyContent: 'end'
            }
        }
    },
    additionalContextTitle: {
        textAlign: 'end',
        color: theme.palette.text.primary
    },
    studyPageLayoutLeftSide: {
        '& .MuiButtonBase-root': {
            '&:disabled': {
                color: theme.palette.text.disabled,
                opacity: 1
            }
        },
    },
    expandInputArea: {
        width: '600px',
        marginBottom: theme.spacing(2),
    },
    titleActions: {
        display: 'flex',
        alignItems: 'center',
    },
    deleteTitle: {
        padding: 5,
        marginLeft: theme.spacing(0),
        marginTop: theme.spacing(1.5),

    },
    startOrViewBtn: {
        minWidth: 'fit-content',
        width: '40%',
        '& .MuiButtonBase-root': {
            whiteSpace: 'nowrap'
        },
    },
    actionsAndAlertContainer: {
        borderTop: `2px solid ${theme.palette.border.primary}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        width: '100%',
    },
    links: {
        color: theme.palette.text.primary,
    },
    boldLink: {
        fontWeight:  theme.palette.text.fontWeight.bold,

    },
    editIcon: {
        color: theme.palette.iconColor.success
    },
    addDescription: {
            color: theme.palette.text.successPrimary,
            '&:hover': {
            color: theme.palette.text.successSecondary,
            }
    },
    textArea: {
        width: '50%'
    },
    addText: {
        color: theme.palette.text.successPrimary,
        '&:hover': {
        color: theme.palette.text.successSecondary,
        }
    },
}));

const ViewStudy = ({ id }) => {
    const styles = useStyles();
    const history = useHistory();
    const [state, setState] = useStoreContext();

    const [name, setName] = useState('');
    const [savedName, setSavedName] = useState('');
    const [showNameInput, setShowNameInput] = useState(false);

    const [description, setDescription] = useState('');
    const [savedDescription, setSavedDescription] = useState('');
    const [showDescriptionInput, setShowDescriptionInput] = useState(false);

    const [showAdditionalContextInput, setShowAdditionalContextInput] = useState(false);
    const [additionalContext, setAdditionalContext] = useState('');
    const [savedAdditionalContext, setSavedAdditionalContext] = useState('');

    const [activeView, setActiveView] = useState('Card View');
    const [showQuestionForm, setShowQuestionForm] = useState(false);
    const [questions, setQuestions] = useState([]);

    const toggleQuestionForm = () => setShowQuestionForm(!showQuestionForm);
    const toggleNameForm = () => setShowNameInput(!showNameInput);
    const [studyForDeletion, setStudyForDeletion] = useState(null);
    const [showDiscardModal, setShowDiscardModal] = useState(false);
    const [originalStudy, setOriginalStudy] = useState('');
    const [disableDiscard, setDisableDiscard] = useState(true);
    const [disableSave, setDisableSave] = useState(true);
    const [isNew, setIsNew] = useState(true);
    const [date, setDate] = useState(new Date());
    const [createStudyIsDisabled, setCreateStudyIsDisabled] = useState(false);
    const [alert, setAlert] = useState('');
    const [panelId, setPanelId] = useState(0);

    const toggleNameInput = () => {
        if (!showNameInput) {
            setName(savedName);
        }
        setShowNameInput(!showNameInput)
    };

    const onClickCancelNameEdit = (e) => {
        e.preventDefault();
        setName('');
        toggleNameForm();
    };

    const onClickSaveNameEdit = (e) => {
        e.preventDefault();
        setSavedName(name);
        setName('');
        toggleNameForm();
    };



    const toggleDescriptionInput = () => {
        if (!showDescriptionInput) {
            setDescription(savedDescription);
        }
        setShowDescriptionInput(!showDescriptionInput)
    };

    const onClickCancelDescriptionEdit = (e) => {
        e.preventDefault();
        setDescription('');
        toggleDescriptionInput();
    };

    const onClickSaveDescriptionEdit = (e) => {
        e.preventDefault();
        setSavedDescription(description);
        setDescription('');
        toggleDescriptionInput();
    };



    const toggleAdditionalContextInput = () => {
        if (!showAdditionalContextInput) {
            setAdditionalContext(savedAdditionalContext);
        }
        setShowAdditionalContextInput(!showAdditionalContextInput);
    }

    const onClickCancelAdditionalContextEdit = (e) => {
        e.preventDefault();
        setAdditionalContext('');
        setShowAdditionalContextInput(false);
    };

    const onClickSaveAdditionalContextEdit = (e) => {
        e.preventDefault();
        setSavedAdditionalContext(additionalContext);
        setAdditionalContext('');
        setShowAdditionalContextInput(false);
    }

    const toggleDiscardChanges = () => {
        if (!disableDiscard) {
            history.push('/studies');
            return;
        }
        setShowDiscardModal(!showDiscardModal)
    };

    const refresh = (e) => e.preventDefault();

    const onClickListView = (e) => {
        e.preventDefault();
        setActiveView('List View');
    };

    const onClickCardView = (e) => {
        e.preventDefault();
        setActiveView('Card View');
    };

    const onClickDelete = async (id) => {
        try {
            await onDeleteStudy(id);
            setState(state => (state.toggleStatusModal = { message: `You have successfully delete Study: ${savedName}`, isSuccessModal: true, title: 'Success', btnLabel: 'Continue' }, { ...state }));
            history.push('/studies');
        } catch (e) {
            setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
            console.error(e?.response?.data || e);;
        }
        finally {
            setStudyForDeletion(null);
        }
    };

    const onClickDiscardChanges = () => {
        const [savedAdditionalContext, savedDescription, savedName, questions] = JSON.parse(originalStudy);

        setSavedDescription(savedDescription);
        setSavedName(savedName);
        setSavedAdditionalContext(savedAdditionalContext);
        questions.forEach(question => {
            if (!question.answeroptions) {
                return;
            }
            question.answerOptions = question.answeroptions;
            question.answerOptions = question.answerOptions.filter(option => option !== null);
            delete question.answeroptions;
        })
        setQuestions(questions);
        toggleDiscardChanges();
        setState(state => (state.toggleStatusModal = { message: 'Your changes have been discarded', isSuccessModal: true, title: 'Success', btnLabel: 'Continue' }, { ...state }));
    };

    const saveChanges = async () => {
        if (savedName && questions.length) {
            try {
                setState(state => (state.isLoading = true, { ...state }))
                const data = await onCreateOrUpdateStudy(savedName, savedDescription, savedAdditionalContext, questions, id);
                setOriginalStudy(JSON.stringify([data.additionalContext, data.description, data.name, data.questions]));
                setState(state => (state.toggleStatusModal = { message: 'Your changes are saved', isSuccessModal: true, title: 'Success', btnLabel: 'Continue' }, { ...state }));
            } catch (e) {
                setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
                console.error(e?.response?.data || e);
            } finally {
                setState(state => (state.isLoading = false, { ...state }))
            }
        }
    };

    const prepare = async () => {
        try {
            setState(state => (state.isLoading = true, { ...state }))
            const studyData = await onGetStudies(id);
            studyData[0].questions.forEach(question => {
                if (!question.answeroptions) {
                    return;
                }
                question.answerOptions = question.answeroptions;
                question.answerOptions = question.answerOptions.filter(option => option !== null);
                delete question.answeroptions;
            })
            setPanelId(studyData[0].panel_id);
            setSavedDescription(studyData[0].description);
            setSavedName(studyData[0].name);
            setSavedAdditionalContext(studyData[0].additionalContext);
            setIsNew(studyData[0].isNew);
            // setIsNew(false);
            setDate(studyData[0].date);
            setOriginalStudy(JSON.stringify([studyData[0].additionalContext, studyData[0].description, studyData[0].name, studyData[0].questions]));
            setQuestions(studyData[0].questions);
        } catch (e) {
            setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
            console.error(e?.response?.data || e);
        } finally {
            setState(state => (state.isLoading = false, { ...state }))
        }
    };

    useEffect(() => {
        if (state.study && state.user) {
            state.study.questions.forEach(question => {
                if (!question.answeroptions) {
                    return;
                }
                question.answerOptions = question.answeroptions;
                question.answerOptions = question.answerOptions.filter(option => option !== null);
                delete question.answeroptions;
            })
            setPanelId(state.study.panel_id);
            setSavedName(state.study.name);
            setQuestions(state.study.questions);
            setOriginalStudy(JSON.stringify([state.study.additionalContext, state.study.description, state.study.name, state.study.questions]));
            setSavedDescription(state.study?.description);
            setSavedAdditionalContext(state.study?.additionalContext);
            setIsNew(state.study.isNew);
            setDate(state.study.date);
        } else if (state.user && !savedName) {
            prepare();
        }
    }, [state.study, state.user]);

    useEffect(() => {
        if (originalStudy) {
            const compareStudy = JSON.stringify([savedAdditionalContext, savedDescription, savedName, questions]);
            setDisableDiscard(originalStudy !== compareStudy)

            if (!savedName || questions.length === 0) {
                setDisableSave(true);
            } else {
                setDisableSave(originalStudy === compareStudy)
            }
        }
    }, [savedName, savedDescription, savedAdditionalContext, questions, originalStudy]);

    useEffect(() => {
        let isDisabled = false;
        if (!savedName || questions.length === 0) {
            isDisabled = true;
        } else {
            const questionTexts = questions.map(q => q.question.toLowerCase().trim());
            const answerOptions = questions.map(q => q.answerOptions.map(ao => (ao.trim() + q.question.trim()).toLowerCase())).flat();
            if (new Set(questionTexts).size !== questionTexts.length) {
                isDisabled = true;
                setAlert('Questions must be unique.')
            } else if (new Set(answerOptions).size !== answerOptions.length) {
                setAlert('Each question must have unique answer options.')
                isDisabled = true;
            }
        }
        if (!isDisabled) {
            setAlert('')
        }
        setCreateStudyIsDisabled(isDisabled);
    }, [savedName, questions])

    return (
        <div className={styles.createStudyPageLayout}>
            <BrandModal
                onClose={toggleDiscardChanges}
                open={showDiscardModal}
                title='Warning'
                titleWithDot={true}
                leftBtnText='Cancel'
                rightBtnText='Discard Changes'
                leftBtnAction={toggleDiscardChanges}
                rightBtnAction={onClickDiscardChanges}
                isWarningModal={true}
            >
                <Typography variant='body2' component='div' className={styles.warning}>
                    Are you sure you want to discard your changes?
                </Typography>
            </BrandModal>


            <BrandModal
                onClose={() => setStudyForDeletion(null)}
                open={Boolean(studyForDeletion)}
                title='Delete Study'
                titleWithDot={true}
                isWarningModal={true}
                leftBtnText='Cancel'
                rightBtnText='Delete Study'
                leftBtnAction={() => setStudyForDeletion(null)}
                rightBtnAction={() => onClickDelete(studyForDeletion)}
            >
                <Typography variant='body2' component='div' className={styles.warning}>
                    Are you sure you want to delete this study?<br />
                    This action cannot be undone.
                </Typography>
            </BrandModal>
            <div>
                <Breadcrumbs separator=">" aria-label="breadcrumb" className={styles.breadcrumbs}>
                    <BrandLink className={styles.links} to='/studies' color='primary' variant='body1'>Studies</BrandLink>
                    <BrandLink className={clsx(styles.links, styles.boldLink)} to='/studies?create=true' onClick={refresh} color='primary' variant='body1'>View Study</BrandLink>
                </Breadcrumbs>
                <div className={styles.studyForm}>
                    <div className={styles.studyPageLayout}>
                        <div className={styles.studyPageLayoutLeftSide}>
                            <div>
                                {showNameInput ?
                                    <div className={styles.descriptionButton}>
                                        <BrandTextArea
                                            classes={{root: styles.textArea}}
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            placeholder={`Click here to add text.\nMaximum length is 255 characters.`}
                                            minRows={3}
                                            limit={255}
                                        />
                                        <TertiaryBrandButton
                                            className={styles.saveChangesBtn}
                                            onClick={onClickSaveNameEdit}
                                        >
                                            &#10004; Save Changes
                                        </TertiaryBrandButton>
                                        <TertiaryBrandButton
                                            className={styles.cancelBtn}
                                            onClick={onClickCancelNameEdit}
                                        >
                                            &#10008; Cancel
                                        </TertiaryBrandButton>
                                    </div>
                                    :
                                    <div className={styles.titleActions}>
                                        <TertiaryBrandButton
                                            className={styles.addDescriptionButton}
                                            onClick={toggleNameInput}
                                            disabled={!isNew}
                                        >
                                            <Typography
                                                className={clsx(styles.orangeTypography, styles.savedDescription)}
                                                variant='h6'
                                                component='div'
                                            >
                                                <div>{savedName} {formatDate(date || new Date())}</div>
                                                {!isNew ? null : <EditOutlinedIcon className={styles.editIcon} color='primary' />}
                                            </Typography>
                                        </TertiaryBrandButton>
                                        {!isNew ? null :
                                            <IconButton
                                                className={clsx(styles.cancelBtn, styles.deleteTitle)}
                                                onClick={() => setStudyForDeletion(id)}
                                            >
                                                <DeleteOutlineRoundedIcon />
                                            </IconButton>
                                        }
                                    </div>
                                }
                            </div>
                            {showDescriptionInput ?
                                <div className={styles.descriptionButton}>
                                    <BrandTextArea
                                        classes={{root: styles.textArea}}
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        placeholder={`Click here to add text.\nMaximum length is 255 characters.`}
                                        minRows={3}
                                        limit={255}
                                    />
                                    <TertiaryBrandButton
                                        className={styles.saveChangesBtn}
                                        onClick={onClickSaveDescriptionEdit}
                                    >
                                        &#10004; Save Changes
                                    </TertiaryBrandButton>
                                    <TertiaryBrandButton
                                        className={styles.cancelBtn}
                                        onClick={onClickCancelDescriptionEdit}
                                    >
                                        &#10008; Cancel
                                    </TertiaryBrandButton>
                                </div>
                                :
                                !isNew && !savedDescription ?
                                    null
                                    :
                                    <TertiaryBrandButton
                                        className={styles.addDescriptionButton}
                                        onClick={toggleDescriptionInput}
                                        disabled={!isNew}
                                    >
                                        {savedDescription ?
                                            <div className={styles.savedDescription}>
                                                <div>{savedDescription}</div>
                                                {!isNew ? null : <EditOutlinedIcon className={styles.editIcon} color='primary' />}
                                            </div>
                                            :
                                            <span className={styles.addDescription}>+ Add description (Optional)</span>}
                                    </TertiaryBrandButton>
                            }
                            <div className={styles.startOrViewBtn}>
                                <BrandButton
                                    onClick={() => history.push(`/conversation?id=${id}${panelId ? `&panelId=${panelId}` : ''}`)}
                                >
                                    {isNew ? 'Start study' : 'View Results'}
                                </BrandButton>
                            </div>
                        </div>
                        {!isNew && !savedAdditionalContext ? null :
                            <div>
                                <Typography variant="h6" color='primary' className={styles.additionalContextTitle}>Additional Context</Typography>
                                {showAdditionalContextInput ?
                                    <ClickAwayListener onClickAway={(e) => !isNew && onClickCancelAdditionalContextEdit(e)}>
                                        <div className={!isNew ? styles.expandInputArea : null}>
                                            <BrandTextArea
                                                value={additionalContext}
                                                onChange={(e) => setAdditionalContext(e.target.value)}
                                                placeholder='Click here to add text'
                                                minRows={5}
                                                maxRows={5}
                                            />
                                            {!isNew ? null :
                                                <div className={styles.contextButton}>
                                                    <TertiaryBrandButton
                                                        className={styles.saveChangesBtn}
                                                        onClick={onClickSaveAdditionalContextEdit}
                                                    >
                                                        &#10004; Save Changes
                                                    </TertiaryBrandButton>
                                                    <TertiaryBrandButton
                                                        className={styles.cancelBtn}
                                                        onClick={onClickCancelAdditionalContextEdit}
                                                    >
                                                        &#10008; Cancel
                                                    </TertiaryBrandButton>
                                                </div>
                                            }
                                        </div>
                                    </ClickAwayListener>
                                    :
                                    <div className={styles.addButtonContainer}>
                                        <TertiaryBrandButton
                                            className={styles.addDescriptionButton}
                                            onClick={toggleAdditionalContextInput}
                                        >
                                            {savedAdditionalContext ?
                                                <div className={clsx(styles.savedDescription, !isNew ? styles.oldSavedDescription : null)}>
                                                    <div>{labelParser(savedAdditionalContext, 30)}</div>
                                                    {!isNew ?
                                                        <ArrowDropDownRoundedIcon className={styles.editIcon} fontSize="large" />
                                                        :
                                                        <EditOutlinedIcon className={styles.editIcon} />
                                                    }
                                                </div>
                                                :
                                                <span className={styles.addText}>+ Add</span>}
                                        </TertiaryBrandButton>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    {!questions.length ?
                        <div className={styles.toolbar} />
                        :
                        <div className={styles.toolbar}>
                            <Breadcrumbs separator="|" aria-label="breadcrumb" className={styles.breadcrumbs}>
                                <BrandLink
                                    to='/'
                                    onClick={onClickListView}
                                    color='primary'
                                    variant='body1'
                                    className={activeView === 'List View' ? styles.active : styles.inactive}
                                >
                                    List View
                                </BrandLink>
                                <BrandLink
                                    to='/'
                                    onClick={onClickCardView}
                                    color='primary'
                                    variant='body1'
                                    className={activeView === 'Card View' ? styles.active : styles.inactive}
                                >
                                    Card View
                                </BrandLink>
                            </Breadcrumbs>
                        </div>}
                    <div className={activeView === 'Card View' ? styles.deck : activeView === 'List View' ? styles.list : null}>
                        {activeView === 'Card View' && questions.length ?
                            <QuestionsDeck questions={questions} setQuestions={setQuestions} studyIsNew={isNew} />
                            :
                            activeView === 'List View' && questions.length ?
                                <QuestionsList questions={questions} setQuestions={setQuestions} studyIsNew={isNew} />
                                :
                                null
                        }
                        {!isNew ? null :
                            showQuestionForm ?
                                <AddEditQuestionForm toggleQuestionForm={toggleQuestionForm} setQuestions={setQuestions} questions={questions} />
                                :
                                <TertiaryBrandButton
                                    classes={{root: styles.addNewQuestion}}
                                    onClick={toggleQuestionForm}
                                >
                                    Add New Question
                                    <span><AddCircleOutlineIcon /></span>
                                </TertiaryBrandButton>
                        }
                    </div>
                </div>
            </div>
            {!isNew ? null :
                <div className={styles.actionsAndAlertContainer}>
                    <div className={styles.actions}>
                        <BrandButton
                            variant='outlined'
                            onClick={toggleDiscardChanges}
                        >
                            {!disableDiscard ? 'Go Back' : 'Discard Changes'}
                        </BrandButton>
                        <BrandButton
                            onClick={saveChanges}
                            disabled={disableSave || createStudyIsDisabled}
                        >
                            Save Study
                        </BrandButton>
                    </div>
                    <BrandAlert>{alert}</BrandAlert>
                </div>
            }
        </div >
    );
}

export default ViewStudy;