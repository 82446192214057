/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { onDeletePanel, onGetPanels, onGetPersonas } from "../services/PanelService";
import { useStoreContext } from "../../store/Store";
import { makeStyles } from '@material-ui/core/styles';
import { Breadcrumbs, IconButton, Typography } from "@material-ui/core";
import { BrandLink } from "../CoreComponents/BrandLink";
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import clsx from 'clsx';
import { BrandModal } from "../CoreComponents/BrandModal";
import { useHistory } from 'react-router-dom';
import { BrandButton } from "../CoreComponents/BrandButton";
import PersonasDeck from '../CoreComponents/PanelsComponents/PersonasDeck'

const useStyles = makeStyles((theme) => ({
    createPanelPageLayout: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    panelPageLayout: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline',
    },
    breadcrumbs: {
        color: theme.palette.text.primary,
        fontSize: theme.spacing(1.6),
        '& .MuiTypography-root': {
            fontSize: theme.spacing(1.6),
        }
    },
    orangeTypography: {
        color: theme.palette.text.primary,
        fontWeight:  theme.palette.text.fontWeight.bold,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1.5),
    },
    panelForm: {
        display: 'flex',
        flexDirection: 'column',
    },
    cancelBtn: {
        color: theme.palette.text.error,
        marginLeft: theme.spacing(1),
        '&:hover': {
            // color: theme.palette.text.error,
            opacity: 0.8,
        }
    },
    warning: {
        marginBottom: theme.spacing(5),
        marginRight: theme.spacing(5),
        fontSize: theme.palette.text.size.md
    },
    warningSubText: {
        color: theme.palette.text.error
    },
    panelPageLayoutLeftSide: {
        '& .MuiButtonBase-root': {
            '&:disabled': {
                color: theme.palette.text.error,
                opacity: 0.6
            }
        },
    },
    deleteTitle: {
        padding: 5,
        marginLeft: theme.spacing(0),
        marginTop: theme.spacing(1.5),

    },
    titleWithAction: {
        display: 'flex',
        alignItems: 'center'
    },
    links: {
        color: theme.palette.text.primary,
    },
    boldLink: {
        fontWeight:  theme.palette.text.fontWeight.bold,
    },
    numberOfPersonas:{
        marginLeft: theme.spacing(0.6),
        color: theme.palette.text.successPrimary,
    }
}));

const ViewPanel = ({ id }) => {
    const styles = useStyles();
    const [, setState] = useStoreContext();
    const history = useHistory();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [personas, setPersonas] = useState([]);
    const [panelId, setPanelId] = useState("");
    const [isNew, setIsNew] = useState(false);
    const [panelForDeletion, setPanelForDeletion] = useState(null);

    const prepare = async () => {
        try {
            setState(state => (state.isLoading = true, { ...state }));
            const panelData = await onGetPanels(id);
            const personas = await onGetPersonas(id);
            setPersonas(personas)
            setName(panelData[0].name);
            setDescription(panelData[0].description);
            setPanelId(panelData[0].id);
            setIsNew(panelData[0].isNew);
        } catch (e) {
            setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
            console.error(e?.response?.data || e);
        } finally {
            setState(state => (state.isLoading = false, { ...state }));
        }
    };

    useEffect(() => prepare(), []);
    const refresh = (e) => e.preventDefault();

    const onClickDelete = async (id) => {
        try {
            await onDeletePanel(id);
            setState(state => (state.toggleStatusModal = { message: `You have successfully delete Panel: ${name}`, isSuccessModal: true, title: 'Success', btnLabel: 'Continue' }, { ...state }));
            history.push('/panels');
        } catch (e) {
            setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
            console.error(e?.response?.data || e);;
        }
        finally {
            setPanelForDeletion(null);
        }
    };

    const onClickPanelSetup = () => {
        setState(state => (state.fetchingPanelSetupData = true, state.isLoading = true, { ...state }));
        history.push(`/panels?create=true&id=${panelId}`);
    }

    return (
        <div className={styles.createPanelPageLayout}>
            <BrandModal
                onClose={() => setPanelForDeletion(null)}
                open={Boolean(panelForDeletion)}
                title='Delete Panel'
                titleWithDot={true}
                isWarningModal={true}
                leftBtnText='Cancel'
                rightBtnText='Delete Panel'
                leftBtnAction={() => setPanelForDeletion(null)}
                rightBtnAction={() => onClickDelete(panelForDeletion)}
            >
                <Typography variant='body2' component='div' className={styles.warning}>
                    Are you sure you want to delete this Panel?<br />
                    <span className={styles.warningSubText}>This action cannot be undone.</span>
                </Typography>
            </BrandModal>
            <div>
                <Breadcrumbs separator=">" aria-label="breadcrumb" className={styles.breadcrumbs}>
                    <BrandLink className={styles.links} to='/panels' color='primary' variant='body1'>Panels</BrandLink>
                    <BrandLink className={styles.links} to='/panels' onClick={refresh} color='primary' variant='body1'>View Panels</BrandLink>
                    <BrandLink className={clsx(styles.orangeTypography, styles.boldLink)} to='/panels?create=true' onClick={refresh} color='primary' variant='body1'>{name}</BrandLink>
                </Breadcrumbs>
                <div className={styles.panelForm}>
                    <div className={styles.panelPageLayout}>
                        <div className={styles.panelPageLayoutLeftSide}>
                            <div className={styles.titleWithAction}>
                                <Typography variant="h6" component='div' className={styles.orangeTypography}>{name}</Typography>
                                <Typography variant="h6" component='div' className={clsx(styles.orangeTypography, styles.numberOfPersonas)}>({personas.length} Personas)</Typography>
                                <IconButton
                                    className={clsx(styles.cancelBtn, styles.deleteTitle)}
                                    onClick={() => setPanelForDeletion(id)}
                                    disabled={!isNew}
                                >
                                    <DeleteOutlineRoundedIcon />
                                </IconButton>
                            </div>
                            <Typography variant="body1" component='div' className={styles.orangeTypography}>{description}</Typography>
                        </div>
                        <div>
                            <BrandButton onClick={onClickPanelSetup}>
                                Panel Setup
                            </BrandButton>
                        </div>
                    </div>
                </div>
                <PersonasDeck
                    generatedPersonas={personas}
                    setGeneratedPersonas={setPersonas}
                    panelId={panelId}
                    disableDelete={true}
                    hideTitle={true}
                />
            </div>
        </div>
    );
}

export default ViewPanel;