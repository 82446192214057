import { Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { TertiaryBrandButton } from '../BrandButton';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useState } from "react";
import AddEditQuestionForm from "./AddEditQuestionForm";

const useStyles = makeStyles((theme) => ({
    card: {
        borderRadius: theme.spacing(1.5),
        padding: theme.spacing(1.5),
        backgroundColor: theme.palette.background.white,
        marginTop: theme.spacing(2),
        width: '224px',
        height: 'fit-content',
    },
    answerOption: {
        marginLeft: theme.spacing(1.5),
        color: theme.palette.text.primary
    },
    questionAndAnswerOptionContainer: {
        width: '89%',
        borderRadius: theme.spacing(1.5),
        wordWrap: 'break-word',
        padding: theme.spacing(1.5),
        marginBottom: theme.spacing(1),
        maxHeight: '100px',
        overflow: 'auto',
        backgroundColor: theme.palette.background.primary,
        color: theme.palette.text.default
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(1),
        '& .MuiButtonBase-root': {
            width: 'fit-content',
        },
        '& .MuiButton-label': {
            gap: theme.spacing(0.5),
            alignItems: 'flex-start',
            justifyContent: 'center',
            '& .MuiSvgIcon-root': {
                height: '20px',
                width: '20px',
            }
        },
        '& .MuiButtonBase-root:first-child': {
            color: theme.palette.text.errorSecondary,
            '&:hover': {
                color: theme.palette.text.error,
            }
        },
        '& .MuiButtonBase-root:nth-child(2)': {
            color: theme.palette.text.successPrimary,
            '&:hover': {
                color: theme.palette.text.successSecondary,
            }
        }
    }
}));

const QuestionsDeck = ({ questions, setQuestions, studyIsNew = true }) => {
    const styles = useStyles();
    const [questionForEditDic, setQuestionForEditDic] = useState([]);

    const handleDeleteQuestion = (index) => {
        const newQuestions = [...questions];
        newQuestions.splice(index, 1);
        setQuestions(newQuestions);
    }

    const handleEditQuestion = (index) => {
        setQuestionForEditDic({ ...questionForEditDic, [index]: true });
    }

    return (
        <>
            {questions.map((question, index) => {
                if (questionForEditDic[index]) {
                    return <AddEditQuestionForm
                        key={index}
                        toggleQuestionForm={() => setQuestionForEditDic({ ...questionForEditDic, [index]: false })}
                        setQuestions={setQuestions}
                        questions={questions}
                        questionForEdit={question}
                        questionForEditIndex={index}
                    />
                }
                return (
                    <div key={index} className={styles.card}>
                        {!studyIsNew ? null :
                            <div className={styles.actions}>
                                <TertiaryBrandButton onClick={() => handleDeleteQuestion(index)}>
                                    <div>Delete</div>
                                    <DeleteOutlineOutlinedIcon />
                                </TertiaryBrandButton>
                                <TertiaryBrandButton onClick={() => handleEditQuestion(index)}>
                                    <div>Edit</div>
                                    <EditOutlinedIcon />
                                </TertiaryBrandButton>
                            </div>
                        }
                        <Typography variant="body1" component='div' className={styles.questionAndAnswerOptionContainer}>{question.question}</Typography>
                        <div>
                            {question.answerOptions.map((option, index) => {
                                return (
                                    <div key={index}>
                                        <Typography variant="body1" component='div' className={styles.answerOption}>Option {index + 1}:</Typography>
                                        <Typography variant="body1" component='div' className={styles.questionAndAnswerOptionContainer}>{option}</Typography>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </>
    );
}

export default QuestionsDeck;