/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
import React, { useEffect, useState } from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { BrandLink } from '../CoreComponents/BrandLink';
import { BrandInput, BrandTextArea } from '../CoreComponents/BrandInput';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { BrandButton, TertiaryBrandButton } from '../CoreComponents/BrandButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { BrandModal } from '../CoreComponents/BrandModal';
import { useHistory } from 'react-router-dom';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { onCreateOrUpdateStudy } from '../services/StudyService';
import { useStoreContext } from '../../store/Store';
import { labelParser } from '../utils/basicFunctions';
import QuestionsDeck from '../CoreComponents/StudiesComponents/QuestionsDeck';
import QuestionsList from '../CoreComponents/StudiesComponents/QuestionsList';
import AddEditQuestionForm from '../CoreComponents/StudiesComponents/AddEditQuestionForm';
import { BrandAlert } from '../CoreComponents/BrandAlert';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    createStudyPageLayout: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    studyPageLayout: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline',
    },
    breadcrumbs: {
        color: theme.palette.text.primary,
        fontSize: theme.spacing(1.6),
        '& .MuiTypography-root': {
            fontSize: theme.spacing(1.6),
        }
    },
    orangeTypography: {
        color: theme.palette.text.primary,
        fontWeight:  theme.palette.text.fontWeight.bold,
        fontSize: theme.palette.text.size.lg,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(1.5),
    },
    addDescriptionButton: {
        color: theme.palette.text.primary,
        width: 'fit-content',
    },
    studyForm: {
        display: 'flex',
        flexDirection: 'column',
    },
    descriptionButton: {
        marginTop: theme.spacing(1.5),
        display: 'flex',
        alignItems: 'top',
        width: theme.spacing(96)
    },
    contextButton: {
        marginTop: theme.spacing(1.5),
        display: 'flex',
        alignItems: 'top',
        justifyContent: 'flex-end',
        width: theme.spacing(96)
    },
    saveChangesBtn: {
        color: theme.palette.text.successPrimary,
        marginLeft: theme.spacing(1),
        '&:hover': {
            color: theme.palette.text.successSecondary
        }
    },
    cancelBtn: {
        color: theme.palette.text.errorSecondary,
        marginLeft: theme.spacing(1),
        '&:hover': {
            color: theme.palette.text.error
        }
    },
    addNewQuestion: {
        color: theme.palette.text.white,
        width: '250px',
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
        backgroundColor: theme.palette.background.secondary,
        boxShadow: theme.palette.shadow.large,
        marginTop: theme.spacing(2),
        height: 'fit-content',
        '& .MuiButton-label': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            gap: theme.spacing(1),
            '& span': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }
        },
        '&:hover': {
            background: theme.palette.background.blue,
        }
    },
    studyNameInput: {
        '& .MuiInputBase-root': {
            width: '250px',
        }
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'flex-end',
        height: theme.spacing(2.5),
    },
    inactive: {
        color: theme.palette.text.primary
    },
    active: {
        color: theme.palette.text.successSecondary
    },
    deck: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(2),
    },
    list: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(3),
        '& .MuiButton-root': {
            width: 'fit-content',
        },
        '& .MuiButton-root:nth-child(1)': {
            '& .MuiButton-label': {
                color: theme.palette.text.primary,
            }
        }
    },
    warning: {
        marginBottom: theme.spacing(5),
        marginRight: theme.spacing(5),
        fontSize: theme.palette.text.size.md
    },
    warningSubText: {
        color: theme.palette.text.error
    },
    savedDescription: {
        display: 'flex',
        gap: theme.spacing(1),
        margin: theme.spacing(2, 0),
        alignItems: 'center',
        color: theme.palette.text.primary
    },
    descriptionLabel: {
        color: theme.palette.text.successPrimary,
    },
    addButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        '& .MuiButton-root': {
            '& .MuiButton-label': {
                justifyContent: 'end'
            }
        }
    },
    additionalContextTitle: {
        textAlign: 'end',
        color: theme.palette.text.primary
    },
    actionsAndAlertContainer:{
        borderTop: `2px solid ${theme.palette.border.primary}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        width: '100%',
    },
    links: {
        color: theme.palette.text.primary,
    },
    boldLink: {
        fontWeight:  theme.palette.text.fontWeight.bold,

    },
    editIcon: {
        color: theme.palette.iconColor.success
    },
    textArea: {
        width: '70%',

    },
    shadow: {
        border: 'none',
        boxShadow: theme.palette.shadow.large
    }
}));

const INPUT_BACKGROUND = 'transparent';

const CreateStudy = () => {
    const styles = useStyles();
    const [, setState] = useStoreContext();
    const $studyName = useState('');
    const [showDescriptionInput, setShowDescriptionInput] = useState(false);
    const [description, setDescription] = useState('');
    const [savedDescription, setSavedDescription] = useState('');
    const [showAdditionalContextInput, setShowAdditionalContextInput] = useState(false);
    const [additionalContext, setAdditionalContext] = useState('');
    const [savedAdditionalContext, setSavedAdditionalContext] = useState('');
    const [showQuestionForm, setShowQuestionForm] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [activeView, setActiveView] = useState('Card View');
    const [showCancelModal, setShowCancelModal] = useState(false);
    const history = useHistory();
    const [createStudyIsDisabled, setCreateStudyIsDisabled] = useState(false);
    const [alert, setAlert] = useState('');

    const refresh = (e) => e.preventDefault();
    const toggleQuestionForm = () => setShowQuestionForm(!showQuestionForm);
    const toggleCancelModal = () => setShowCancelModal(!showCancelModal);

    const toggleDescriptionInput = () => {
        if (!showDescriptionInput) {
            setDescription(savedDescription);
        }
        setShowDescriptionInput(!showDescriptionInput)
    };

    const onClickCancelDescriptionEdit = (e) => {
        e.preventDefault();
        setDescription('');
        toggleDescriptionInput();
    };

    const onClickSaveDescriptionEdit = (e) => {
        e.preventDefault();
        setSavedDescription(description);
        setDescription('');
        toggleDescriptionInput();
    };

    const toggleAdditionalContextInput = () => {
        if (!showAdditionalContextInput) {
            setAdditionalContext(savedAdditionalContext);
        }
        setShowAdditionalContextInput(!showAdditionalContextInput);
    }

    const onClickCancelAdditionalContextEdit = (e) => {
        e.preventDefault();
        setAdditionalContext('');
        setShowAdditionalContextInput(false);
    };

    const onClickSaveAdditionalContextEdit = (e) => {
        e.preventDefault();
        setSavedAdditionalContext(additionalContext);
        setAdditionalContext('');
        setShowAdditionalContextInput(false);
    }

    const onClickCardView = (e) => {
        e.preventDefault();
        setActiveView('Card View');
    };

    const onClickListView = (e) => {
        e.preventDefault();
        setActiveView('List View');
    };

    const onClickCancelStudy = () => {
        history.push('/studies');
    };

    const onClickCancelAction = (e) => {
        if ($studyName[0] || description || questions.length) {
            toggleCancelModal();
        } else {
            onClickCancelStudy();
        }
    };

    const onClickCreateStudy = async () => {
        if ($studyName[0] && questions.length) {
            try {
                setState(state => (state.isLoading = true, { ...state }))
                const data = await onCreateOrUpdateStudy($studyName[0], savedDescription, savedAdditionalContext, questions);
                setState(state => (state.study = data, { ...state }));
                history.push(`/studies?id=${data.id}`);
            } catch (e) {
                setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
                console.error(e?.response?.data || e);
            } finally {
                setState(state => (state.isLoading = false, { ...state }))
            }
        }
    }

    useEffect(() => {
        let isDisabled = false;
        if (!$studyName[0] || questions.length === 0) {
            isDisabled = true;
        } else {
            const questionTexts = questions.map(q => q.question.toLowerCase().trim());
            const answerOptions = questions.map(q => q.answerOptions.map(ao => (ao.trim() + q.question.trim()).toLowerCase())).flat();
            if (new Set(questionTexts).size !== questionTexts.length) {
                isDisabled = true;
                setAlert('Questions must be unique.')
            } else if (new Set(answerOptions).size !== answerOptions.length) {
                setAlert('Each question must have unique answer options.')
                isDisabled = true;
            }
        }
        if (!isDisabled) {
            setAlert('')
        }
        setCreateStudyIsDisabled(isDisabled);
    }, [$studyName[0], questions])

    return (
        <>
            <BrandModal
                onClose={toggleCancelModal}
                open={showCancelModal}
                clickAwayClosable={true}
                title='Cancel Study'
                titleWithDot={true}
                leftBtnText='Stay on page'
                rightBtnText='Cancel Study'
                leftBtnAction={toggleCancelModal}
                rightBtnAction={onClickCancelStudy}
            >
                <Typography variant='body2' component='div' className={styles.warning}>
                    Are you sure you want to cancel this study?<br />
                    <span className={styles.warningSubText}>Your questions will be lost.</span>
                </Typography>
            </BrandModal>
            <div className={styles.createStudyPageLayout}>
                <div>
                    <Breadcrumbs separator=">" aria-label="breadcrumb" className={styles.breadcrumbs}>
                        <BrandLink className={styles.links} to='/studies' color='primary' variant='body1'>Studies</BrandLink>
                        <BrandLink className={clsx(styles.links, styles.boldLink)} to='/studies?create=true' onClick={refresh} color='primary' variant='body1'>Create Study</BrandLink>
                    </Breadcrumbs>
                    <div className={styles.studyForm}>
                        <div className={styles.studyPageLayout}>
                            <div>
                                <Typography
                                    className={styles.orangeTypography}
                                    variant='body1'
                                    component='div'
                                >
                                    1. Select Name for this study
                                </Typography>
                                <div className={styles.studyNameInput}>
                                    <BrandInput
                                        style={{background: INPUT_BACKGROUND}}
                                        $value={$studyName}
                                        placeholder='Enter Study Name*'
                                        variant='outlined'
                                    />
                                </div>
                                {showDescriptionInput ?
                                    <div className={styles.descriptionButton}>
                                        <BrandTextArea
                                            classes={{root: clsx(styles.textArea, styles.shadow)}}
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            placeholder={`Click here to add text.\nMaximum length is 255 characters.`}
                                            minRows={3}
                                            limit={255}
                                        />
                                        <TertiaryBrandButton
                                            className={styles.saveChangesBtn}
                                            onClick={onClickSaveDescriptionEdit}
                                        >
                                            &#10004; Save Changes
                                        </TertiaryBrandButton>
                                        <TertiaryBrandButton
                                            className={styles.cancelBtn}
                                            onClick={onClickCancelDescriptionEdit}
                                        >
                                            &#10008; Cancel
                                        </TertiaryBrandButton>
                                    </div>
                                    :
                                    <TertiaryBrandButton
                                        className={styles.addDescriptionButton}
                                        onClick={toggleDescriptionInput}
                                    >
                                        {savedDescription ?
                                            <div className={styles.savedDescription}>
                                                <div>{savedDescription}</div>
                                                <EditOutlinedIcon className={styles.editIcon} color='primary' />
                                            </div>
                                            :
                                            <span className={styles.descriptionLabel}>+ Add description (Optional)</span>}
                                    </TertiaryBrandButton>
                                }
                            </div>
                            <div>
                                <Typography variant="h6" color='primary' className={styles.additionalContextTitle}>Additional Context</Typography>
                                {showAdditionalContextInput ?
                                    <div>
                                        <BrandTextArea
                                            classes={{root: styles.shadow}}
                                            value={additionalContext}
                                            onChange={(e) => setAdditionalContext(e.target.value)}
                                            placeholder='Click here to add text'
                                            minRows={5}
                                            maxRows={5}
                                        />
                                        <div className={styles.contextButton}>
                                            <TertiaryBrandButton
                                            className={styles.saveChangesBtn}
                                                onClick={onClickSaveAdditionalContextEdit}
                                            >
                                                &#10004; Save Changes
                                            </TertiaryBrandButton>
                                            <TertiaryBrandButton
                                                className={styles.cancelBtn}
                                                onClick={onClickCancelAdditionalContextEdit}
                                            >
                                                &#10008; Cancel
                                            </TertiaryBrandButton>
                                        </div>
                                    </div>
                                    :
                                    <div className={styles.addButtonContainer}>
                                        <TertiaryBrandButton
                                            className={styles.addDescriptionButton}
                                            onClick={toggleAdditionalContextInput}
                                        >
                                            {savedAdditionalContext ?
                                                <div className={styles.savedDescription}>
                                                    <div>{labelParser(savedAdditionalContext, 30)}</div>
                                                    <EditOutlinedIcon className={styles.editIcon} color='primary' />
                                                </div>
                                                :
                                                <span className={styles.descriptionLabel}>+ Add</span>}
                                        </TertiaryBrandButton>
                                    </div>
                                }
                            </div>
                        </div>
                        {!questions.length ?
                            <div className={styles.toolbar} />
                            :
                            <div className={styles.toolbar}>
                                <Breadcrumbs separator="|" aria-label="breadcrumb" className={styles.breadcrumbs}>
                                    <BrandLink
                                        to='/'
                                        onClick={onClickListView}
                                        color='primary'
                                        variant='body1'
                                        className={activeView === 'List View' ? styles.active : styles.inactive}
                                    >
                                        List View
                                    </BrandLink>
                                    <BrandLink
                                    to='/'
                                    onClick={onClickCardView}
                                    color='primary'
                                    variant='body1'
                                    className={activeView === 'Card View' ? styles.active : styles.inactive}
                                >
                                        Card View
                                    </BrandLink>
                                </Breadcrumbs>
                            </div>}
                        <div className={activeView === 'Card View' ? styles.deck : activeView === 'List View' ? styles.list : null}>
                            {activeView === 'Card View' && questions.length ?
                                <QuestionsDeck questions={questions} setQuestions={setQuestions} />
                                :
                                activeView === 'List View' && questions.length ?
                                    <QuestionsList questions={questions} setQuestions={setQuestions}/>
                                    :
                                    null
                            }
                            {showQuestionForm ?
                                <AddEditQuestionForm toggleQuestionForm={toggleQuestionForm} setQuestions={setQuestions} questions={questions} />
                                :
                                <TertiaryBrandButton
                                    classes={{root: styles.addNewQuestion}}
                                    onClick={toggleQuestionForm}
                                >
                                    Add New Question
                                    <span><AddCircleOutlineIcon /></span>
                                </TertiaryBrandButton>
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.actionsAndAlertContainer}>
                    <div className={styles.actions}>
                        <BrandButton variant='outlined' onClick={onClickCancelAction}>
                            Cancel
                        </BrandButton>
                        <BrandButton disabled={createStudyIsDisabled} onClick={onClickCreateStudy}>
                            Create Study
                        </BrandButton>
                    </div>
                    <BrandAlert>{alert}</BrandAlert>
                </div>
            </div >
        </>
    );
};

export default CreateStudy;
