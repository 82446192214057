/* eslint-disable no-sequences */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { Switch } from 'react-router-dom';
import { Route, Redirect } from 'react-router';
import Home from './Home';
import PanelsPage from './PanelsPage';
import StudiesPage from './StudiesPage';
import { NavigationAvatar } from '../CoreComponents/NavigationComponents/NavigationAvatar';
import { LogoutAvatar } from '../CoreComponents/NavigationComponents/LogoutAvatar';
import Users from './Users';
import { useLocationChange } from '../utils/RouteUtils';
import { BrandModal } from '../CoreComponents/BrandModal';
import { useStoreContext } from '../../store/Store';
import { Typography } from '@material-ui/core';
import { BrandLoaderSpinner } from '../CoreComponents/BrandLoader';
import Chat from '../CoreComponents/Chat';
import { useHistory } from 'react-router-dom';
import Conversation from './Conversation';

const drawerWidth = 121;
const drawerWidthExtended = 270;
const PANELS_BRANCHES = [
    { name: 'Create New', navigationPath: '/panels?create=true' },
    { name: 'View Panels', navigationPath: '/panels' },
    { name: 'empty', navigationPath: '/' }
];
const STUDIES_BRANCHES = [
    { name: 'Create New', navigationPath: '/studies?create=true' },
    { name: 'View Studies', navigationPath: '/studies' },
    { name: 'empty', navigationPath: '/' }
];

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        backgorund: theme.palette.background.darkSecondary
    },
    drawerExtended: {
        width: drawerWidthExtended,
        flexShrink: 0,
    },
    drawerPaper: {
        backgroundColor: theme.palette.background.darkSecondary,
        width: drawerWidth,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center',
        '& .MuiIconButton-label': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        }
    },
    drawerPaperExtended: {
        backgroundColor: theme.palette.background.dark,
        width: drawerWidthExtended,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'start',
        paddingLeft: '6px',
        '& .MuiIconButton-label': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        }
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentExtended: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidthExtended,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    square: {
        height: theme.spacing(9),
        width: theme.spacing(9),
        backgroundColor: theme.palette.background.avatar,
        borderRadius: theme.spacing(1),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(7),
    },
    navigation: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    navigationExtended: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
        paddingLeft: '6px',
    },
    squareExtended: {
        marginLeft: '13px',
    },
    logoutButton: {
        marginLeft: '6px',
    },
    removePadding: {
        padding: 0
    },
    statusModalBody: {
        marginBottom: theme.spacing(4),
        marginRight: theme.spacing(7)
    },
    chatDrawer: {
        backgroundColor: theme.palette.background.dark,
        // width: drawerWidthExtended,
        display: 'flex',
        justifyContent: 'space-between',
        borderLeft: `2px solid ${theme.palette.border.success}`,
        flexDirection: 'column',
        alignItems: 'start',
        paddingLeft: '6px',
        '& .MuiIconButton-label': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        }
    },
    squareAvatar: {
        width: '72px',
        height: '72px',
        borderRadius: '10px',
        background: theme.palette.background.black,
        marginTop: theme.spacing(4)
    }
}));

export default function PageLayout({ showUsers }) {
    const history = useHistory();
    const [state, setState] = useStoreContext();
    const styles = useStyles();
    const [removePadding, setRemovePadding] = useState(false);
    const [extended, setExtended] = useState({
        panels: false,
        studies: false
    });

    useLocationChange(() => {
        setRemovePadding(window.location.href.includes(`/panels?create=true`));
    })

    useEffect(() => {
        if (state.user) {
            const fromReload = localStorage.getItem('fromReload');
            const pageBeforeReload = localStorage.getItem('pageBeforeReload');
            if (fromReload === 'true' && pageBeforeReload) {
                history.push(pageBeforeReload);
            }
            localStorage.removeItem('fromReload');
            localStorage.removeItem('pageBeforeReload');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.user]);

    return (
        <div className={styles.root}>
            <BrandModal
                open={Boolean(state.isLoading)}
                onClose={() => setState(state => (state.isLoading = false, { ...state }))}
                notClosable={true}
                isLoaderModal={true}
            >
                <BrandLoaderSpinner />
            </BrandModal>
            <BrandModal
                open={Boolean(state.toggleStatusModal)}
                onClose={() => {
                    if (state.toggleStatusModal?.message === 'Server authentication error!') {
                        setState(state => (state.user = undefined, { ...state }));
                        localStorage.clear();
                        history.push('/');
                    }
                    setState(state => (state.toggleStatusModal = null, { ...state }));
                }}
                title={state.toggleStatusModal?.title}
                titleWithDot={true}
                isSuccessModal={state.toggleStatusModal?.isSuccessModal}
                isWarningModal={!state.toggleStatusModal?.isSuccessModal}
                leftBtnText={state.toggleStatusModal?.btnLabel || 'Close'}
                leftBtnAction={() => {
                    if (state.toggleStatusModal?.message === 'Server authentication error!') {
                        setState(state => (state.user = undefined, { ...state }));
                        localStorage.clear();
                        history.push('/');
                    }
                    setState(state => (state.toggleStatusModal = null, { ...state }));
                }}
            >
                <Typography variant="body1" className={styles.statusModalBody}>{state.toggleStatusModal?.message}</Typography>
            </BrandModal>
            <Drawer
                anchor='right'
                open={Boolean(state.persona)}
                onClose={() => setState(state => (state.persona = null, { ...state }))}
                classes={{ paper: styles.chatDrawer }}
                styles={{ flexShrink: 0 }}
            >
                <Chat persona={state.persona} />
            </Drawer>
            <Drawer
                className={extended.panels || extended.studies ? styles.drawerExtended : styles.drawer}
                variant="persistent"
                anchor="left"
                open={true}
                classes={{ paper: extended.panels || extended.studies ? styles.drawerPaperExtended : styles.drawerPaper }}
            >
                <div className={extended.panels || extended.studies ? styles.navigationExtended : styles.navigation}>
                    <div className={styles.squareAvatar}/>
                    <div className={clsx(styles.square, extended.panels || extended.studies ? styles.squareExtended : null)} />
                    <NavigationAvatar label='home' />
                    <NavigationAvatar
                        label='panels'
                        branches={PANELS_BRANCHES}
                        setExtended={setExtended}
                        extended={extended}
                    />
                    <NavigationAvatar
                        label='studies'
                        branches={STUDIES_BRANCHES}
                        setExtended={setExtended}
                        extended={extended}
                    />
                    {showUsers && <NavigationAvatar label='users' />}
                </div>
                <div className={extended.panels || extended.studies ? styles.logoutButton : null} >
                    <LogoutAvatar />
                </div>
            </Drawer>
            <main className={clsx(
                extended.panels || extended.studies ? styles.contentExtended : styles.content,
                removePadding ? styles.removePadding : null,
                { [styles.contentShift]: true })}>
                <Switch>
                    <Route exact path="/home" component={Home} />
                    <Route exact path="/panels" component={PanelsPage} />
                    <Route exact path="/studies" component={StudiesPage} />
                    <Route exact path="/conversation" component={Conversation} />
                    {showUsers ? <Route exact path="/users" component={Users} /> : null}
                    <Redirect from="*" to="/home" />
                </Switch>
            </main>
        </div>
    );
}


