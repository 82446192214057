/* eslint-disable no-sequences */
import { useEffect, useState } from "react";
import { BrandInput, BrandPasswordInput } from "../BrandInput";
import makeStyles from '@material-ui/core/styles/makeStyles';
import { BrandSelect, BrandMenuItem } from '../BrandSelect'
import { BrandButton } from "../BrandButton";
import { editUser } from "../../services/UserService";
import { BrandLoaderDots } from "../BrandLoader";
import { useStoreContext } from "../../../store/Store";

const useStyles = makeStyles((theme) => ({
    form: {
        width: '324px',
        padding: theme.spacing(3),
    },
    requiredIndicator: {
        color: theme.palette.text.error
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(1),
        marginTop: theme.spacing(6),
        '& .MuiButton-root': {
            width: 'fit-content',
        },
        '& .MuiButton-root:nth-child(1)': {
            '& .MuiButton-label': {
                color: theme.palette.text.white,
            }
        }
    },
}));
const EditUserForm = ({ userForEdit, onClose, toggleUserInvitedSuccessfully, refreshUsers }) => {
    const [state, setState] = useStoreContext();
    const styles = useStyles();
    const $firstName = useState('');
    const $lastName = useState('');
    const $username = useState('');
    const $email = useState('');
    const $password = useState('');
    const $confirmPassword = useState('');
    const $selectedUserType = useState('User');
    const $apiKey = useState('');
    const [userTypes] = useState(['User', 'Admin']);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (userForEdit) {
            $firstName[1](userForEdit.first_name);
            $lastName[1](userForEdit.last_name);
            $username[1](userForEdit.username);
            $email[1](userForEdit.email);
            $selectedUserType[1](userForEdit.user_type);
            $apiKey[1](userForEdit.api_key);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userForEdit])

    const onSubmit = async (e) => {
        e.preventDefault();
        let changePassword = false;
        if ($password[0]) {
            if ($confirmPassword[0] !== $password[0]) {
                console.error('Password and Confirm Password do not match');
                return;
            }
            changePassword = true;
        } else if ($confirmPassword[0]) {
            console.error('Password and Confirm Password do not match');
            return;
        }

        try {
            setLoading(true);
            const data = await editUser(userForEdit.id, $firstName[0], $lastName[0], $username[0], $email[0], $password[0], $selectedUserType[0], changePassword, $apiKey[0]);
            if (Array.isArray(data) && data.length) {
                toggleUserInvitedSuccessfully();
                refreshUsers();
                if (state.user.id === userForEdit.id) {
                    const userData = state.user
                    userData.first_name = $firstName[0];
                    userData.last_name = $lastName[0];
                    userData.username = $username[0];
                    userData.email = $email[0];
                    userData.user_type = $selectedUserType[0];
                    setState(state => (state.user = userData, { ...state }));
                }
            } else {
                throw new Error('Failed to edit user');
            }
        } catch (e) {
            setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
            console.error(e?.response?.data || e);
        } finally {
            onClose();
            setLoading(false);
        }
    };

    return (
        <form className={styles.form} onSubmit={onSubmit}>
            <BrandInput
                label={<span>First Name<span className={styles.requiredIndicator}>*</span></span>}
                placeholder='First Name'
                required
                $value={$firstName}
            />
            <BrandInput
                label={<span>Last Name<span className={styles.requiredIndicator}>*</span></span>}
                placeholder='Last Name'
                required
                $value={$lastName}
            />
            <BrandInput
                label={<span>Username<span className={styles.requiredIndicator}>*</span></span>}
                placeholder='Username'
                required
                $value={$username}
            />
            {state.user.id === userForEdit?.id ?
                null
                :
                <div>
                    <BrandSelect
                        $value={$selectedUserType}
                        label={<span>User Type<span className={styles.requiredIndicator}>*</span></span>}
                    >
                        {userTypes.map(userType => (
                            <BrandMenuItem key={userType} value={userType}>
                                {userType}
                            </BrandMenuItem>
                        ))}
                    </BrandSelect>
                </div>
            }
            <BrandInput
                label={<span>Email<span className={styles.requiredIndicator}>*</span></span>}
                placeholder='Email'
                type='email'
                required
                $value={$email}
            />
            <BrandPasswordInput
                label={<span>Password<span className={styles.requiredIndicator}>*</span></span>}
                placeholder='Password'
                $value={$password}
            />
            <BrandPasswordInput
                label={<span>Confirm Password<span className={styles.requiredIndicator}>*</span></span>}
                placeholder='Confirm Password'
                $value={$confirmPassword}
            />
            <BrandInput
                label={<span>Api Key<span className={styles.requiredIndicator}>*</span></span>}
                placeholder='Api Key'
                required
                $value={$apiKey}
            />
            <div className={styles.actions}>
                <BrandButton variant='outlined' onClick={onClose}>
                    Cancel
                </BrandButton>
                <BrandButton type='submit'>
                    {loading ?
                        <BrandLoaderDots />
                        :
                        'Save'
                    }
                </BrandButton>
            </div>
        </form>
    );
}

export default EditUserForm;