import { Checkbox, Popover } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { ListItemText } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TertiaryBrandButton } from '../BrandButton';

const useStyles = makeStyles((theme) => ({
    listItem: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1.5, 2),
        borderBottom: `1px solid ${theme.palette.border.primary}`,
        '& .MuiListItemText-root': {
            display: 'flex',
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        '& .MuiFormControl-root': {
            width: '100%'
        },
        '&:last-child': {
            borderBottom: 'none'
        },
        '& .MuiRadio-colorSecondary.Mui-checked': {
            color: theme.palette.text.primary
        },
        '& .MuiRadio-colorSecondary': {
            color: theme.palette.text.primary
        }
    },
    hr: {
        margin: theme.spacing(0),
        padding: theme.spacing(0),
        border: 'none',
        borderBottom: `1px solid ${theme.palette.border.primary}`,
        width: '350px',
    },
    list: {
        width: '350px'
    },
    modalActions: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: theme.spacing(2),
        '& .MuiButtonBase-root': {
            width: 'fit-content',
        },
        '& .MuiButton-label': {
            gap: theme.spacing(0.5),
            alignItems: 'center',
            justifyContent: 'center',
            '& .MuiSvgIcon-root': {
                height: '20px',
                width: '20px',
            }
        },
        '& .MuiButtonBase-root:first-child': {
            color: theme.palette.text.successPrimary,
            '&:hover': {
            color: theme.palette.text.successSecondary,
            }
        },
        '& .MuiButtonBase-root:nth-child(2)': {
            color: theme.palette.text.errorSecondary,
            '&:hover': {
                color: theme.palette.text.error,
            }
        }
    },
    optionsContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: theme.spacing(4)
    },
    checked: {
        color: theme.palette.text.primary
    },
    notChecked: {
        color: theme.palette.text.disabled
    },
    checkBoxStyle: {
        color: theme.palette.text.primary,
    },
    formControl: {
        '& .MuiCheckbox-colorPrimary': {
            color: theme.palette.text.primary,
        },
    }
}));

const PersonasFilterModal = ({ openFilter, setOpenFilter, filtersOptions,  selectedFiltersOptions, setSelectedFiltersOptions }) => {
    const styles = useStyles();

    const handleClickCheckbox = (key, option) => {
        const selectedFilters = { ...selectedFiltersOptions };

        if (selectedFilters[key] && selectedFilters[key].includes(option)) {
            selectedFilters[key] = selectedFilters[key].filter((item) => item.id !== option.id);
        } else {
            if (selectedFilters[key]) {
                selectedFilters[key].push(option);
            } else {
                selectedFilters[key] = [option];
            }
        }
        setSelectedFiltersOptions(selectedFilters);
    }

    const handleSelectAll = () => {
        const selectedFilters = { ...selectedFiltersOptions };

        Object.keys(filtersOptions).map(key => {
            filtersOptions[key].map(option => {
                if (selectedFilters[key]) {
                    selectedFilters[key].push(option);
                } else {
                    selectedFilters[key] = [option];
                }
            })
        })

        setSelectedFiltersOptions(selectedFilters);
    };

    const handleRemoveAll = () => {
        setSelectedFiltersOptions({});
    }

    return (
        <Popover
            anchorEl={openFilter}
            keepMounted
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={Boolean(openFilter)}
            onClose={() => setOpenFilter(null)}
        >
            <ListItem className={styles.modalActions}>
                <TertiaryBrandButton onClick={() => handleSelectAll()}>Select all</TertiaryBrandButton>
                <TertiaryBrandButton onClick={handleRemoveAll}>Select None</TertiaryBrandButton>
            </ListItem>
            <List className={styles.list}>
                {Object.keys(filtersOptions).map((option, index) => {
                    return (
                        <ListItem key={index} className={styles.listItem}>
                            <ListItemText primary={option} />
                                <hr className={styles.hr} />
                            <div className={styles.optionsContainer}>
                                {filtersOptions[option].map((opt, idx) => {
                                    return <FormControlLabel 
                                                className={styles.formControl}
                                                key={idx}
                                                control={<Checkbox
                                                className={styles.checkBoxStyle}
                                                checked={selectedFiltersOptions[option] ? Boolean(selectedFiltersOptions[option].includes(opt)) : false}
                                                color="primary"
                                                name={option.attribute}
                                                onClick={() => handleClickCheckbox(option, opt)}
                                                />}
                                                label={<span className={selectedFiltersOptions[option] && Boolean(selectedFiltersOptions[option].includes(opt)) ? styles.checked : styles.notChecked}>{opt.option}</span>}
                                            />
                                }
                    )}
                            </div>
                        </ListItem>
                    );
                })}
            </List>
        </Popover>
    );
}

export default PersonasFilterModal;