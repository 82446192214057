/* eslint-disable no-sequences */
import React, { } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ListItemIcon, ListItemSecondaryAction, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import clsx from 'clsx';
import { BrandLink } from '../BrandLink';
import { useHistory } from 'react-router-dom';
import { useStoreContext } from '../../../store/Store';

const useStyles = makeStyles((theme) => ({
    accentColoredTitle: {
        color: theme.palette.text.primary,
        fontSize: theme.palette.text.size.sm
    },
    listItemLeftSection: {
        display: 'flex',
        gap: theme.spacing(2),
        alignItems: 'center',
    },
    date: {
        '& .MuiTypography-root': {
            color: theme.palette.text.primary
        }
    },
    listItem: {
        background: theme.palette.background.primary,
        borderRadius: theme.spacing(1),
        marginBottom: theme.spacing(1.5),
    },
    listContainer: {
        boxShadow: theme.palette.shadow.large,
        borderRadius: '10px',
        background: theme.palette.background.white,
        width: '519px',
        height: '482px',
        padding: theme.spacing(2.5),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    listTitle: {
        marginBottom: theme.spacing(2),
        fontSize: theme.palette.text.size.lg,
        fontWeight:  theme.palette.text.fontWeight.semiBold,
    },
    linkContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& .MuiLink-underlineHover': {
            color: theme.palette.text.successPrimary,
            fontWeight:  theme.palette.text.fontWeight.bold,
            fontSize: theme.palette.text.size.lg,
            '&:hover': {
                color: theme.palette.text.successSecondary
            }
        }
    },
    listItemIconButton: {
        cursor: 'pointer',
        color: theme.palette.iconColor.success,
        '&:hover': {
            opacity: 0.8
        },
    },
    listItemIcon: {
        color: theme.palette.iconColor.success,
        '&:hover': {
            color: theme.palette.iconColor.successSecondary,
        }
    },
    emptyListMessage: {
        fontSize: theme.palette.text.size.sm,
        color: theme.palette.text.primary,
        fontWeight:  theme.palette.text.fontWeight.bold,
        fontFamily: theme.palette.text.fontFamily.primary
    },
    personasNumber: {
        color: theme.palette.text.successPrimary,
        fontSize: theme.palette.text.size.sm
    },
    panelName: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(1)
    }
}));

export const HistoryLists = ({ title, listItems, navigationPath }) => {
    const history = useHistory();
    const styles = useStyles();
    const [, setState] = useStoreContext();

    const onClick = (listItem) => {
        if (navigationPath === '/studies') {
            setState(state => (state.study = listItem, { ...state }));
        } else {
            setState(state => (state.panel = listItem, { ...state }));
        }

        history.push(`${navigationPath}?id=${listItem.id}`);
    };

    return (
        <div className={styles.listContainer}>
            <div>
                <Typography variant="h6" className={clsx(styles.accentColoredTitle, styles.listTitle)}>
                    {title}
                </Typography>
                {listItems.length === 0 ?
                    <Typography className={styles.emptyListMessage} variant="body1">
                        There are no {navigationPath.split('/')[1]} to display.
                    </Typography>
                    :
                    <List>
                        {listItems.map((listItem) =>
                            <ListItem key={listItem.id} className={styles.listItem} >
                                <div className={styles.listItemLeftSection}>
                                    <ListItemText primary={<Typography className={styles.panelName}>{listItem.name} {listItem.number_of_personas ? <Typography className={styles.personasNumber} variant='h6'>({listItem.number_of_personas} Personas)</Typography> : ''}</Typography>} />
                                    <ListItemIcon className={styles.listItemIconButton} onClick={() => onClick(listItem)}>
                                        <VisibilityOutlinedIcon color='primary' className={styles.listItemIcon} />
                                    </ListItemIcon>
                                </div>
                                <ListItemSecondaryAction>
                                    <ListItemText className={styles.date} primary={listItem.date.split('T')[0]} />
                                </ListItemSecondaryAction>
                            </ListItem>
                        )}
                    </List>
                }
            </div>
            <div className={styles.linkContainer}>
                <BrandLink to={navigationPath} variant='body1'>See All</BrandLink>
            </div>
        </div >
    );
};

