/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
import React, { useState, useEffect, useMemo } from 'react';
import BrandTable from '../CoreComponents/BrandTable';
import { BrandModal } from '../CoreComponents/BrandModal';
import { BrandSearchInput } from '../CoreComponents/BrandInput';
import { IconButton, Typography } from '@material-ui/core';
import { getUsers, deleteUser } from '../services/UserService';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { debounce } from '../utils/FetchUtils'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { BrandButton } from '../CoreComponents/BrandButton';
import AddIcon from '@material-ui/icons/Add';
import InviteUserForm from '../CoreComponents/UsersComponents.js/InviteUserForm';
import EditUserForm from '../CoreComponents/UsersComponents.js/EditUserForm';
import { useStoreContext } from '../../store/Store';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import clsx from 'clsx';

const GREEN = '#379F6D';
const RED = '#FF6363';

const parseApiKey = (apiKey) => {
    if (!apiKey) {
        return '';
    }
    return `${apiKey.slice(0, 4)}...${apiKey.slice(-3)}`;
}

const useStyles = makeStyles((theme) => ({
    addUserBtn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        background: theme.palette.background.secondary,
        color: theme.palette.text.secondary,
        fontSize: theme.palette.text.size.md,
        fontWeight:  theme.palette.text.fontWeight.semiBold,
        borderRadius: '42px'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
        '& .MuiButtonBase-root': {
            width: 'fit-content',
        }
    },
    statusModalBody: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(7),
        color: theme.palette.text.primary,
        fontSize: theme.palette.text.size.lg,
        fontWeight:  theme.palette.text.fontWeight.semiBold,
    },
    error: {
        color: '#FF6363'
    },
    pageTitle: {
        color: theme.palette.text.primary,
        fontSize: '32px',
        fontWeight:  theme.palette.text.fontWeight.bold,
    },
    addUserIcon: {
        color: theme.palette.background.primary
    },
    apiKeyText: {
        color: theme.palette.text.primary,
    }
}));


const prepareUsers = async (setState, setRows, sortBy, sortType, searchFilter) => {
    try {
        setState(state => (state.isLoading = true, { ...state }))
        const res = await getUsers(sortBy, sortType, searchFilter);
        setRows(res);
    }
    catch (e) {
        setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
        console.error(e?.response?.data || e);
    }
    finally {
        setState(state => (state.isLoading = false, { ...state }))
    }
};

const Users = () => {
    const styles = useStyles();
    const [state, setState] = useStoreContext();
    const [rows, setRows] = useState([]);
    const [selectedRowForDeletion, setSelectedRowForDeletion] = useState(null);
    const [selectedRowForEdit, setSelectedRowForEdit] = useState(null);
    const [showInviteUserForm, setShowInviteUserForm] = useState(false);
    const [search, setSearch] = useState('');
    const [searchFilter, setFilterSearch] = useState('');
    const [sortBy, setSortBy] = useState('id');
    const [sortType, setSortType] = useState('ASC');
    const [refreshUsers, setRefreshUsers] = useState({});
    const [headCells] = useState([
        {
            id: 'first_name',
            label: 'First Name',
        },
        {
            id: 'last_name',
            label: 'Last Name',
        },
        {
            id: 'username',
            label: 'Username',
        },
        {
            id: 'email',
            label: 'Email',
        },
        {
            id: 'api_key',
            label: 'API Key',
            CellRender: ({ rowData }) => {
                if (!rowData.api_key) {
                    return '';
                }
                return <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                    <Typography className={styles.apiKeyText} variant="body1">
                        {parseApiKey(rowData?.api_key || '')}
                    </Typography>
                    <IconButton
                        color='primary'
                        style={{ padding: 0, color: GREEN}}
                        onClick={() => navigator.clipboard.writeText(rowData.api_key)}
                    >
                        <FileCopyOutlinedIcon fontSize='small' />
                    </IconButton>
                </div>
            }
        },
        {
            id: 'active_status',
            label: 'Status',
            CellRender: ({ rowData }) => {
                return <Typography variant="body1" style={{ color: rowData.active_status === 'active' ? GREEN : RED }}>
                    {rowData.active_status}
                </Typography>
            }
        },
        {
            id: 'user_type',
            label: 'Type',
        },
        {
            id: 'created_on',
            label: 'Created on',
            CellRender: ({ rowData }) => {
                return rowData.created_on.split('T')[0];
            }
        },
        {
            id: 'updated_on',
            label: 'Last Updated on',
            CellRender: ({ rowData }) => {
                return rowData.updated_on.split('T')[0];
            }
        },
        {
            id: '',
            label: '',
            hideHeadLabel: true,
            CellRender: (props) => {
                const rowData = props.rowData;
                const isDisabled = state.user.id === rowData.id;
                return <div
                    style={
                        isDisabled ?
                            {
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                color: RED,
                                opacity: 0.5
                            }
                            :
                            {
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                color: RED,
                                cursor: 'pointer',
                                '&:hover': {
                                    opacity: 0.8
                                }
                            }
                    }
                    onClick={() => isDisabled ? null : setSelectedRowForDeletion(rowData)}
                >
                    <div>Delete</div>
                    <DeleteOutlineOutlinedIcon />
                </div>
            }
        },
        {
            id: '',
            label: '',
            hideHeadLabel: true,
            CellRender: (props) => {
                const rowData = props.rowData;
                return <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        color: GREEN,
                        cursor: 'pointer',
                        '&:hover': {
                            opacity: 0.8
                        }
                    }}
                    onClick={() => setSelectedRowForEdit(rowData)}
                >
                    <div>Edit</div>
                    <EditOutlinedIcon />
                </div>
            }
        }
    ]);

    useEffect(() => prepareUsers(setState, setRows, sortBy, sortType, searchFilter), [sortBy, sortType, searchFilter, refreshUsers]);
    const toggleInviteUserForm = () => setShowInviteUserForm(!showInviteUserForm);

    const onClickDeleteUser = async () => {
        try {
            await deleteUser(selectedRowForDeletion.id);
            setSelectedRowForDeletion(null);
            setRefreshUsers({});
            setState(state => (state.toggleStatusModal = { message: 'User deleted successfully', isSuccessModal: true, title: 'Success' }, { ...state }));
        } catch (e) {
            setSelectedRowForDeletion(null);
            setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
            console.error(e?.response?.data || e);
        }
    };

    const debounceMemo = useMemo(
        () => debounce(
            (event) => {
                setSearch(event.target.value);
            }, (event) => {
                setFilterSearch(event.target.value);
            }, 500),
        [],
    );

    return (
        <>
            <BrandModal
                open={Boolean(selectedRowForDeletion)}
                onClose={() => setSelectedRowForDeletion(null)}
                title='Warning'
                titleWithDot={true}
                isWarningModal={true}
                leftBtnText='Cancel'
                leftBtnAction={() => setSelectedRowForDeletion(null)}
                rightBtnText='Delete User'
                rightBtnAction={onClickDeleteUser}
            >
                <Typography variant="body1" className={styles.statusModalBody}>
                    Are you sure you want to delete User {selectedRowForDeletion?.username}?
                </Typography>
                <Typography variant="body1" className={clsx(styles.statusModalBody, styles.error)}>
                    This action cannot be undone.
                </Typography>
                <br/>
                <br/>
            </BrandModal>
            <BrandModal
                open={showInviteUserForm}
                onClose={toggleInviteUserForm}
                title='User Invite'
                titleWithDot={true}
            >
                <InviteUserForm
                    onClose={toggleInviteUserForm}
                    toggleUserInvitedSuccessfully={() => setState(state => (state.toggleStatusModal = { message: 'User Invited Successfully', isSuccessModal: true, title: 'Success' }, { ...state }))}
                    refreshUsers={() => setRefreshUsers({})}
                />
            </BrandModal>
            <BrandModal
                open={Boolean(selectedRowForEdit)}
                onClose={() => setSelectedRowForEdit(null)}
                title='User Edit'
                titleWithDot={true}
            >
                <EditUserForm
                    userForEdit={selectedRowForEdit}
                    onClose={() => setSelectedRowForEdit(null)}
                    toggleUserInvitedSuccessfully={() => setState(state => (state.toggleStatusModal = { message: 'User Edited Successfully', isSuccessModal: true, title: 'Success' }, { ...state }))}
                    refreshUsers={() => setRefreshUsers({})}
                />
            </BrandModal>
            <div>
                <Typography className={styles.pageTitle} variant="h4" color='primary'>Users</Typography>
                <div className={styles.toolbar}>
                    <BrandSearchInput style={{background: 'transparent', border: '2px solid #848C9F'}} variant='outlined' placeholder='Search in Users' value={search} onChange={debounceMemo} />
                    <BrandButton className={styles.addUserBtn} onClick={toggleInviteUserForm}>
                            <AddIcon className={styles.addUserIcon} color='primary' />
                            <div>Add User</div>
                    </BrandButton>
                </div>
                <BrandTable
                    rows={rows}
                    headCells={headCells}
                    checkboxless={true}
                    hidePagination={true}
                    rowIsClickable={false}
                    setSortType={setSortType}
                    setSortBy={setSortBy}
                />
            </div>
        </>
    );
}

export default Users;