/* eslint-disable no-use-before-define */
import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { BrandInput } from '../BrandInput';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    autoComplete: {
        '& .MuiInputBase-root': {
            backgroundColor: theme.palette.background.primary,
            color: theme.palette.text.primary,
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.text.primary,
        }
    },
}));

export default function AttributesDeckAutocomplete({
    options,
    setSelectedAttribute,
    placeholder,
    property,
    selectedAttributes
}) {
    const styles = useStyles();

    return (
        <Autocomplete
            className={styles.autoComplete}
            options={options}
            autoHighlight
            disableClearable={true}
            onChange={(event, newValue) => setSelectedAttribute(newValue)}
            getOptionLabel={(option) => option[property] || ''}
            renderOption={(option) => <span>{option[property]}</span>}
            filterOptions={(options, params) => {
                const filtered = options.filter((option) => {
                    const inputValue = params.inputValue;

                    let _selectedAttributes = selectedAttributes && Object.values(selectedAttributes);
                    if (_selectedAttributes && _selectedAttributes.length > 0) {
                        let _selectedAttributeIds = _selectedAttributes.map(attr => attr.id);
                        if (_selectedAttributeIds.includes(option.id)) {
                            return false;
                        }
                    }

                    return option[property].toLowerCase().includes(inputValue.toLowerCase());
                });
                return filtered;
            }}
            renderInput={(params) => (
                <BrandInput
                    {...params}
                    placeholder={placeholder}
                    inputProps={{
                        ...params.inputProps,
                    }}
                />
            )}
        />
    );
};