/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */

import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { IconButton, ListItemIcon, ListItemSecondaryAction, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { TertiaryBrandButton } from '../BrandButton';
import ChatRoundedIcon from '@material-ui/icons/ChatRounded';
import Checkbox from '@material-ui/core/Checkbox';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { useStoreContext } from '../../../store/Store';


const useStyles = makeStyles((theme) => ({
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& .MuiButtonBase-root': {
            width: 'fit-content',
        },
        '& .MuiButton-label': {
            gap: theme.spacing(0.5),
            alignItems: 'flex-start',
            justifyContent: 'center',
            '& .MuiSvgIcon-root': {
                height: '20px',
                width: '20px',
            }
        },
        '&:hover': {
            '& .MuiButtonBase-root:first-child': {
              color: theme.palette.text.successSecondary,
            },
            '& .MuiButtonBase-root:nth-child(2)': {
                color: theme.palette.text.error,
            }
          },
          '& .MuiButtonBase-root:first-child': {
            color: theme.palette.text.successPrimary,
          },
          '& .MuiButtonBase-root:nth-child(2)': {
            color: theme.palette.text.errorSecondary,
          }
    },
    additionalQuestionsAnswered: {
        marginLeft: theme.spacing(2.5),
        color: theme.palette.text.primary
    },
    list: {
        width: '100%',
        height: '100%',
        borderRadius: theme.spacing(1.5),
        backgroundColor: theme.palette.background.white,
        boxShadow: theme.palette.shadow.large,
        marginTop: '14px',
        overflowY: 'auto',
        '& .MuiListItemIcon-root': {
            minWidth: 'auto',
            width: 'fit-content',
            marginRight: theme.spacing(1),
        },
    },
    listItem: {
        borderBottom: `1px solid ${theme.palette.border.primary}`,
        minHeight: '35px',
        '& .MuiCheckbox-colorPrimary': {
            color: theme.palette.text.primary
        },
    },
    personaTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        marginBottom: theme.spacing(1),
        '& .MuiIconButton-root': {
            padding: 0,
        }
    },
    personaTitle: {
        fontSize: theme.palette.text.size.lg,
        fontWeight: theme.palette.text.fontWeight.semiBold,
    },
    iconColor: {
        color: theme.palette.iconColor.success,
        '&:hover': {
            color: theme.palette.iconColor.successSecondary,
        }
    },
    chatButton: {
        '&:hover': {
            '& .MuiButtonBase-root:first-child': {
              color: theme.palette.text.successSecondary,
            },
          },
          '& .MuiButtonBase-root:first-child': {
            color: theme.palette.text.successPrimary,
          }
    }
}));

const PersonasList = ({ personas, setSelectedPersonas,
    selectedPersonas,
    currentStep,
    setTogglePersonaModal,
    studyId,
    pendingAdditionalQuestions,
    additionalQuestions,
    personaAnswersCount,
    answeredAdditionalQuestions,
    selectedAdditionalQuestions,
    refreshSelectedPersonas
}) => {
    const styles = useStyles();
    const [allAreSelected, setAllAreSelected] = useState(false);
    const [, setState] = useStoreContext();
    const [disablePersonas, setDisablePersonas] = useState({});

    useEffect(() => {
        if (selectedAdditionalQuestions && personas.length > 0) {
            const personasObject = {};
            personas.forEach(persona => {
                personasObject[persona.id] = checkIfPersonaIsAlreadyAsked(persona);
            })

            setDisablePersonas(personasObject);
        }
    }, [selectedAdditionalQuestions]);

    const checkIfPersonaIsAlreadyAsked = (persona) => {
        //check if persona id is in one of the selected questions answers.//
        const isInInaswers = answeredAdditionalQuestions.some(answer => Object.keys(selectedAdditionalQuestions).includes(answer.question_id.toString()) && answer.persona_id === persona.id);
        const isInPendingAnswers = pendingAdditionalQuestions.some(answer => Object.keys(selectedAdditionalQuestions).includes(answer.question_id.toString()) && answer.persona_id === persona.id);

        //remove checkbox if persona is disabled and it is checked//
        if (isInInaswers || isInPendingAnswers) {

            const selectedPersonasCopy = { ...selectedPersonas };

            if (selectedPersonasCopy[persona.id] && setSelectedPersonas) {
                delete selectedPersonasCopy[persona.id];
                setSelectedPersonas(selectedPersonasCopy);
            }

        }

        if (isInInaswers || isInPendingAnswers) return true;
        return false;
    };

    useEffect(() => {
        if (refreshSelectedPersonas) {
            const selectedPersonasCopy = {};
            personas.forEach(persona => {
                if (selectedPersonas[persona.id]) {
                    selectedPersonasCopy[persona.id] = persona;
                }
            });
            setAllAreSelected(Object.keys(selectedPersonasCopy).length === personas.length && personas.length > 0);
        }
    }, [refreshSelectedPersonas])

    return (
        <List className={styles.list} id='personasList'>
            < ListItem className={styles.listItem}>
                {currentStep === 1 ?
                    <>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={allAreSelected}
                                onClick={() => {
                                    if (!allAreSelected) {
                                        const selectedPersonasCopy = {};
                                        personas.forEach(persona => {
                                            selectedPersonasCopy[persona.id] = persona;
                                        });
                                        setSelectedPersonas(selectedPersonasCopy);
                                    } else {
                                        setSelectedPersonas({});
                                    }
                                    setAllAreSelected(!allAreSelected);
                                }}
                                tabIndex={-1}
                                disableRipple
                                color='primary'
                            />
                        </ListItemIcon>
                        <ListItemText
                            primary='Select all available'
                        />
                    </>
                    :
                    null
                }
            </ListItem>
            {personas.map((persona, index) => {
                return (
                    <ListItem key={index} className={styles.listItem} dense disabled={disablePersonas[persona.id]}>
                        {currentStep === 1 ?
                            <ListItemIcon>
                                <Checkbox
                                    disabled={disablePersonas[persona.id]}
                                    edge="start"
                                    checked={Boolean(selectedPersonas[persona.id])}
                                    onClick={() => {
                                        const selectedPersonasCopy = { ...selectedPersonas };
                                        if (selectedPersonasCopy[persona.id]) {
                                            delete selectedPersonasCopy[persona.id];
                                        } else {
                                            selectedPersonasCopy[persona.id] = persona;
                                        }
                                        setAllAreSelected(Object.keys(selectedPersonasCopy).length === personas.length)
                                        setSelectedPersonas(selectedPersonasCopy);
                                    }}
                                    tabIndex={-1}
                                    disableRipple
                                    color='primary'
                                />
                            </ListItemIcon>
                            :
                            null
                        }
                        <ListItemText
                            primary={
                                <div className={styles.personaTitleContainer}>
                                    <Typography className={styles.personaTitle} variant="body1" component='div'>
                                        {persona.Name}
                                    </Typography>
                                    <IconButton onClick={() => setTogglePersonaModal(persona)}>
                                        <VisibilityOutlinedIcon className={styles.iconColor} color='primary' />
                                    </IconButton>
                                </div>
                            }
                            secondary={
                                <Typography key={index} variant="body1" component='div' color='primary' className={styles.additionalQuestionsAnswered} >
                                    Additional Questions Answered {personaAnswersCount[persona.id] ? personaAnswersCount[persona.id].count : 0}/{additionalQuestions.length}
                                </Typography>
                            }
                        />
                        <ListItemSecondaryAction className={styles.actions}>
                            <TertiaryBrandButton
                                className={styles.chatButton}
                                onClick={() => setState(state => (state.persona = {
                                    ...persona,
                                    studyId: studyId
                                }, { ...state }))}
                            >
                                <div>Chat</div>
                                <ChatRoundedIcon />
                            </TertiaryBrandButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            })}
        </List >
    );
}

export default PersonasList;