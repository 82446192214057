import React from 'react';
import { useQuery } from '../utils/RouteUtils';
import CreatePanel from './CreatePanel';
import ViewPanel from './ViewPanel';
import Panels from './Panels';

const PanelsPage = () => {
    const { create, id } = useQuery();

    if (create) return <CreatePanel />
    if (id) return <ViewPanel id={id} />
    return <Panels />
}

export default PanelsPage;






