import React, { useEffect, useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import IconButton from '@material-ui/core/IconButton';
import { InputAdornment, InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { BrandAlert } from './BrandAlert'
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';

// magic...
const InternalInput = React.forwardRef(function Input(props, ref) {
    const {
        disableUnderline,
        classes = {},
        fullWidth = false,
        inputComponent = 'input',
        multiline = false,
        type = 'text',
        ...other
    } = props;

    return (
        <InputBase
            classes={{
                ...classes,
                root: clsx(classes.root, {
                    [classes.underline]: !disableUnderline,
                }),
                underline: null,
            }}
            fullWidth={fullWidth}
            inputComponent={inputComponent}
            multiline={multiline}
            ref={ref}
            type={type}
            {...other}
        />
    );
});

export const BrandInput = withStyles(theme => ({
    root: {
        width: '100%',
        position: 'relative',
        boxSizing: 'content-box',
        display: 'flex',
        flexDirection: 'column',
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(1),
        fontWeight:  theme.palette.text.fontWeight.semiBold,
    },
    label: {
        margin: theme.spacing(0, 'auto', 1, 0),
        fontSIze: theme.palette.text.size.sm,
    },
    input: {
        height: theme.spacing(5),
        border: `2px solid ${theme.palette.border.default}`,
        borderRadius: theme.spacing(2.5),
        boxSizing: 'border-box',
        padding: theme.spacing(1, 2),
        backgroundColor: theme.palette.background.primary,
        color: theme.palette.text.disabledSecondary,
        '&::placeholder': {
            color: theme.palette.text.primary
        }
    },
    error: {
        borderColor: theme.palette.border.error
    },
    outlined: {
        border: '1px solid',
        borderColor: theme.palette.text.primary,
        backgroundColor: theme.palette.background.white,
    }
}))(({ classes, ...props }) => {
    const {
        variant,
        label,
        InputLabelProps,
        inputProps,
        InputProps,
        $value,
        validator,
        validatorPassed,
        ...other
    } = props;
    const [validationErr, setValidationErr] = useState([]);

    const onChange = (e) => {
        const value = e.target.value;
        if (validator) {
            setValidationErr(validator(value))
            if (validatorPassed) {
                if (validator(value).length) {
                    validatorPassed[1](false);
                } else {
                    validatorPassed[1](true);
                }
            }
        }
        $value[1](value)
    }

    return (label ?
        <label className={classes.root} {...InputLabelProps}>
            <span className={classes.label}>{label}</span>
            {$value ?
                <InternalInput
                    inputProps={inputProps}
                    {...InputProps}
                    {...other}
                    value={$value[0]}
                    onChange={onChange}
                    className={clsx(classes.input, validationErr.length ? classes.error : null, variant === 'outlined' ? classes.outlined : null)}
                />
                :
                <InternalInput inputProps={inputProps} {...InputProps} {...other} className={clsx(classes.input, variant === 'outlined' ? classes.outlined : null)} />
            }
            {validationErr.map(x => <BrandAlert key={x}>{x}</BrandAlert>)}
        </label>
        : $value ?
            <InternalInput
                inputProps={inputProps}
                {...InputProps}
                {...other}
                value={$value[0]}
                onChange={onChange}
                className={clsx(classes.input, validationErr.length ? classes.error : null, variant === 'outlined' ? classes.outlined : null)}
            />
            :
            <InternalInput inputProps={inputProps} {...InputProps} {...other} className={clsx(classes.input, variant === 'outlined' ? classes.outlined : null)} />
    );
});

const useBrandPasswordInputStyles = makeStyles((theme) => ({
    iconBtn: {
        position: 'absolute',
        right: theme.spacing(1),
        padding: 0,
        color: '#FFFFFF99'
    },
    icon: {
        color: theme.palette.iconColor.success,
        '&:hover': {
            color: theme.palette.iconColor.successSecondary,
        }
    },
}));

export function BrandPasswordInput(props) {
    const styles = useBrandPasswordInputStyles();
    const [show, setShow] = useState(false);
    const [inputType, setInputType] = useState('password')

    useEffect(() => {
        if (show) {
            setInputType('text')
        } else {
            setInputType('password')
        }
    }, [show]);

    function handleClickShowPassword(e) {
        setShow(!show);
    }

    return (
        <BrandInput type={inputType}
            InputProps={{
                endAdornment: (
                    <IconButton onClick={handleClickShowPassword} className={styles.iconBtn}>
                        {show ? <VisibilityOffOutlinedIcon className={styles.icon} /> : <VisibilityOutlinedIcon className={styles.icon} />}
                    </IconButton>
                )
            }}
            {...props}
        />
    );
}


export function BrandSearchInput(props) {
    return (
        <BrandInput
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start"><SearchIcon style={{ color: '#848C9F' }} /></InputAdornment>
                )
            }}
            {...props}
        />
    );
}

const useBrandTextAreaStyles = makeStyles((theme) => ({
    textArea: {
        backgroundColor: theme.palette.background.white,
        borderRadius: '10px',
        border: `2px solid ${theme.palette.border.primary}`,
        padding: theme.spacing(1, 2),
        margin: theme.spacing(2, 0),
        color: theme.palette.text.primary,
        width: '100%',
    }
}));

export function BrandTextArea({ onSubmitEnter, ...props }) {
    const styles = useBrandTextAreaStyles();

    const handleInputChange = (e) => {
        const value = e.target.value;
        const maxLength = props.limit;
        if (maxLength === undefined) {
            props.onChange(e);
            return;
        }
        if (value.length <= maxLength) {
            props.onChange(e);
        }
    };

    return <InputBase
        multiline
        {...props}
        onKeyDownCapture={(e) => {
            if(onSubmitEnter){
                if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    onSubmitEnter(e);
                }
            }
        }}
        className={styles.textArea}
        onChange={handleInputChange}
    />
}