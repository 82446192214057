/* eslint-disable no-sequences */
import { getMe } from "./UserService";

export const signInAutomatically = async (setStoreState, setIsLoading) => {
  setIsLoading(true);
  try {
    const myData = await getMe();
    setStoreState(state => (state.user = myData, { ...state }));
  } catch (e) {
    setStoreState(state => (state.user = undefined, { ...state }));
    localStorage.clear();
    console.error(e?.response?.data || e);
  }
  setIsLoading(false);
};