import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';
import { BrandButton, TertiaryBrandButton } from '../CoreComponents/BrandButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import FolderOpenRoundedIcon from '@material-ui/icons/FolderOpenRounded';

const useStyles = makeStyles((theme) => ({
    dateLabel: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        height: theme.spacing(6),
    },
    dateLabelAtrribute: {
        color: theme.palette.text.primary,
        fontWeight:  theme.palette.text.fontWeight.bold,
    },
    date: {
        color: theme.palette.text.primary,
        fontWeight:  theme.palette.text.fontWeight.normal,
    },
    descriptionLabel: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(0.5),
        paddingLeft: theme.spacing(2),
        height: theme.spacing(6),
        backgroundColor: theme.palette.background.primary,
        marginBottom: theme.spacing(1),
        cursor: 'pointer',
        // '&:hover': {
        //     opacity: 0.8
        // }
    },
    cardTitle: {
        paddingLeft: theme.spacing(2),
        height: theme.spacing(6),
        borderBottom: `1px solid ${theme.palette.border.primary}`,
        color: theme.palette.text.primary,
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    rightActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        paddingRight: theme.spacing(1),
        '& .MuiButtonBase-root': {
            width: 'fit-content',
        },
        '& .MuiButton-label': {
            gap: theme.spacing(0.5),
            alignItems: 'flex-start',
            justifyContent: 'center',
            '& .MuiSvgIcon-root': {
                height: '20px',
                width: '20px',
            }
        },
        '& .MuiButtonBase-root:first-child': {
            color: theme.palette.text.errorSecondary,
            '&:hover': {
            color: theme.palette.text.error,
            }
        },
        '& .MuiButtonBase-root:nth-child(2)': {
            color: theme.palette.text.successPrimary,
            '&:hover': {
                color: theme.palette.text.successSecondary,
            }
        }
    },
    card: {
        backgroundColor: theme.palette.background.white,
        width: '450px',
        borderRadius: '6px',
        boxShadow: theme.palette.shadow.large,
        paddingTop: theme.spacing(0.5),
        height: 'fit-content',
    },
    cardContent: {
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.background.white,
        display: 'flex',
        flexDirection: 'column',
    },
    secondaryActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(1),
        marginTop: theme.spacing(2),
        paddingRight: theme.spacing(2),
        '& .MuiButton-root': {
            width: 'fit-content',
        },
        '& .MuiButton-root:nth-child(1)': {
            '& .MuiButton-label': {
                color: theme.palette.text.white,
            }
        }
    },
    description: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
    },
    secondaryAction: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(1),
        marginTop: theme.spacing(2),
        paddingRight: theme.spacing(2),
        '& .MuiButton-root': {
            width: 'fit-content',
        },
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.border.primary}`,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        width: '100%',
    },
    hidden: {
        display: 'none'
    },
    resultsBtn: {
        '& .MuiButtonBase-root:first-child': {
            color: theme.palette.text.blue
        },
    },
    descriptionButton: {
        background: theme.palette.background.primary
    },
    descriptionButtonText: {
        color: theme.palette.text.primary
    },
    personasNumber: {
        color: theme.palette.text.successPrimary
    }
}));

const BrandCard = ({
    card,
    setToggleCardDescriptionDic,
    toggleCardDescriptionDic,
    leftButtonLabel,
    leftButtonAction,
    rightButtonLabel,
    rightButtonAction,
    onClickDelete,
    onClickClone,
    onClickResults,
    isNew,
    showResults = true
}) => {
    const styles = useStyles();
    return (
        <div className={styles.card}>
            {!onClickDelete && !onClickClone && isNew ?
                null
                :
                <div className={styles.actions}>
                    {!isNew && showResults &&
                        <div className={styles.resultsBtn}>
                            <TertiaryBrandButton onClick={onClickResults} disabled={isNew}>
                                <div>Results</div>
                                <FolderOpenRoundedIcon />
                            </TertiaryBrandButton>
                        </div>
                    }
                    <div className={styles.rightActions}>
                        <TertiaryBrandButton onClick={onClickDelete} disabled={!isNew} className={onClickDelete ? null : styles.hidden}>
                            <div>Delete</div>
                            <DeleteOutlineOutlinedIcon />
                        </TertiaryBrandButton>
                        <TertiaryBrandButton onClick={onClickClone} className={onClickClone ? null : styles.hidden}>
                            <div>Copy</div>
                            <FileCopyOutlinedIcon />
                        </TertiaryBrandButton>
                    </div>
                </div>
            }
            <div className={styles.cardContent}>
                <div className={styles.cardTitle}>
                    <Typography variant="h6" component='div'>{card.name}</Typography>
                    {card.number_of_personas ?
                        <Typography className={styles.personasNumber} variant="h6" component='div' color='primary'>({card.number_of_personas} Personas)</Typography>
                        :
                        null
                    }
                </div>
                <div className={styles.dateLabel}>
                    <Typography className={styles.dateLabelAtrribute} variant="body1" color='primary' component='div'>Date</Typography>
                    <Typography className={styles.date} variant="body1" component='div'>{card.date.split('T')[0]}</Typography>
                </div>
                <div
                    className={styles.descriptionLabel}
                    onClick={() => setToggleCardDescriptionDic({ ...toggleCardDescriptionDic, [card.id]: !toggleCardDescriptionDic[card.id] })}
                >
                    <Typography className={styles.descriptionButtonText} variant="body1" color='primary' component='div'>View Description</Typography>
                    {toggleCardDescriptionDic[card.id] ?
                        <RemoveRoundedIcon className={styles.descriptionButtonText} color='default' />
                        :
                        <AddIcon className={styles.descriptionButtonText} color='default' />
                    }
                </div>
                {toggleCardDescriptionDic[card.id] ?
                    <Typography variant="body1" component='div' className={styles.description}>{card.description}</Typography>
                    :
                    null
                }
                <div className={rightButtonLabel ? styles.secondaryActions : styles.secondaryAction}>
                    <BrandButton
                        variant={rightButtonLabel && 'outlined'}
                        onClick={leftButtonAction}
                    >
                        {leftButtonLabel}
                    </BrandButton>
                    {rightButtonLabel ?
                        <BrandButton onClick={rightButtonAction}>
                            {rightButtonLabel}
                        </BrandButton>
                        :
                        null
                    }
                </div>
            </div>
        </div >
    );
}

export default BrandCard;