import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        width: 150,
        height: 150,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    circle: {
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    outerCircle: {
        width: 150,
        height: 150,
        zIndex: 0,
    },
    innerCircle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        minWidth: 55,
        minHeight: 55,
        zIndex: 1,
    },
    secondaryCircle: {
        backgroundColor: theme.palette.background.success,
    },
    primaryCircle: {
        backgroundColor: theme.palette.background.white,
    },
    primaryCircleOnTop: {
        position: 'absolute',
        bottom: '50%',
        right: '50%',
        minWidth: 55,
        minHeight: 55,
        zIndex: 1,
    },
    containerOfContainer:{
        minWidth: '325px',
        minHeight: '200px',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    circleValue: {
        color: theme.palette.text.black
    }
}));

const CirclesComponent = ({ circle1, circle2 }) => {
    const classes = useStyles();

    const biggerNumber = Math.max(circle1, circle2);
    const ratio = 150 / biggerNumber;

    const calculateCircleSize = (number) => {
        if (number === biggerNumber) {
            return 150;
        } else {
            return Math.max(55, number * ratio);
        }
    };
    
    return (
        <div className={classes.containerOfContainer}>
            <div className={classes.container}>
                <Paper
                    className={clsx(
                        classes.circle,
                        // classes.outerCircle,
                        circle1 > circle2 ? classes.outerCircle : classes.primaryCircleOnTop,
                        classes.primaryCircle,
                    )}
                    style={{
                        width: calculateCircleSize(circle1),
                        height: calculateCircleSize(circle1),
                    }}
                >
                    <Typography className={classes.circleValue} variant="h4">{circle1}</Typography>
                </Paper>
                <Paper
                    className={clsx(
                        classes.circle,
                        // classes.innerCircle,
                        circle2 >= circle1 ? classes.outerCircle : classes.innerCircle,
                        classes.secondaryCircle,
                    )}
                    style={{
                        width: calculateCircleSize(circle2),
                        height: calculateCircleSize(circle2)
                    }}
                >
                    <Typography className={classes.circleValue} variant="h4">{circle2}</Typography>
                </Paper>
            </div>
        </div>
    );
};

export default CirclesComponent;