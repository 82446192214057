/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
import { useQuery } from '../utils/RouteUtils';
import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useStoreContext } from '../../store/Store';
import { Breadcrumbs, IconButton, Typography } from '@material-ui/core';
import { BrandSearchInput } from '../CoreComponents/BrandInput';
import { debounce } from '../utils/FetchUtils';
import BrandCard from '../CoreComponents/BrandCard';
import SortRoundedIcon from '@material-ui/icons/SortRounded';
import BrandSortOptions from '../CoreComponents/BrandSortOptions';
import { onGetPanels } from '../services/PanelService';
import { BrandLink } from '../CoreComponents/BrandLink';
import { onGetStudies } from '../services/StudyService';
import clsx from 'clsx';

const SORT_OPTIONS = [
    {
        label: 'Date',
        type: 'radio',
        options: [
            {
                label: 'Newest',
                sortType: 'DESC'
            },
            {
                label: 'Oldest',
                sortType: 'ASC'
            }
        ]
    },
    {
        label: 'Name',
        type: 'radio',
        options: [
            {
                label: 'A-Z',
                sortType: 'ASC'
            },
            {
                label: 'Z-A',
                sortType: 'DESC'
            }
        ]
    }
]

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3)
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        paddingBottom: theme.spacing(1.5),
        color: theme.palette.text.primary,
        '& div:first-child': {
            color: theme.palette.text.primary,
        },
    },
    toolbar: {
        marginTop: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    leftSide: {
        display: 'flex',
        gap: theme.spacing(1),
        '& .MuiButtonBase-root': {
            width: theme.spacing(20)
        },
        '& .MuiInputBase-root': {
            width: 'fit-content'
        }
    },
    breadcrumbs: {
        color: theme.palette.text.primary,
        fontSize: theme.spacing(1.6),
        '& .MuiTypography-root': {
            fontSize: theme.spacing(1.6),
        }
    },
    breadcrumb: {
        color: theme.palette.text.primary,
    },
    boldBreadcrumb: {
        fontWeight: theme.palette.text.fontWeight.bold
    },
    deck: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(4),
        paddingTop: theme.spacing(2),
    },
    sortIcon: {
        color: theme.palette.iconColor.secondary
     }
}));

const INPUT_BACKGROUND = 'transparent'
const StartConversation = () => {
    const styles = useStyles();
    const { id } = useQuery();
    const history = useHistory();
    const [, setState] = useStoreContext();
    const [panels, setPanels] = useState([])
    const [search, setSearch] = useState('');
    const [searchFilter, setFilterSearch] = useState('');
    const [toggleCardDescriptionDic, setToggleCardDescriptionDic] = useState({});
    const [openDrawer, setOpenDrawer] = useState(null);
    const [selectedSortOptions, setSelectedSortOptions] = useState({ date: 'ASC', name: 'ASC' });
    const [studyName, setStudyName] = useState('');

    const prepare = async () => {
        try {
            const panelData = await onGetPanels(null, null, searchFilter, selectedSortOptions.date, selectedSortOptions.name);
            const studyData = await onGetStudies(id);
            
            if (!studyData.length) {
                history.push('/studies');
            }

            if (studyData[0]?.panel_id) {
                history.push(`/conversation?id=${id}&panelId=${studyData[0]?.panel_id}`);
            }
            setStudyName(studyData[0]?.name);
            setPanels(panelData || []);
        } catch (e) {
            setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
            console.error(e?.response?.data || e);
        }
    };

    useEffect(() => prepare(), [searchFilter, selectedSortOptions]);

    const debounceMemo = useMemo(
        () => debounce(
            (event) => {
                setSearch(event.target.value);
            }, (event) => {
                setFilterSearch(event.target.value);
            }, 500),
        [],
    );

    const refresh = (e) => e.preventDefault();

    return (
        <div className={styles.container}>
            <BrandSortOptions
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
                sortOptions={SORT_OPTIONS}
                selectedSortOptions={selectedSortOptions}
                setSelectedSortOptions={setSelectedSortOptions}
            />
            <Breadcrumbs separator=">" aria-label="breadcrumb" className={styles.breadcrumbs}>
                <BrandLink className={styles.breadcrumb} to='/studies' color='primary' variant='body1'>Studies</BrandLink>
                <BrandLink className={styles.breadcrumb} to={`/studies?id=${id}`} color='primary' variant='body1'>View Study</BrandLink>
                <BrandLink className={clsx(styles.breadcrumb, styles.boldBreadcrumb)} to={`/conversation?id=${id}`} onClick={refresh} color='primary' variant='body1'>Start Conversation</BrandLink>
            </Breadcrumbs>
            <Typography variant="h6" component='div' className={styles.title}>
                <div>Selected Study:</div>
                <div>{studyName}</div>
            </Typography>
            <Typography variant="body1" component='div'>
                Select a panel to start a conversation:
            </Typography>

            <div className={styles.toolbar}>
                <div className={styles.leftSide}>
                    <BrandSearchInput
                        style={{background: INPUT_BACKGROUND}}
                        variant='outlined'
                        placeholder='Search in Panels'
                        value={search}
                        onChange={debounceMemo}
                    />
                </div>
                <div>
                    <IconButton color='primary' onClick={(e) => setOpenDrawer(e.currentTarget)}>
                        <SortRoundedIcon className={styles.sortIcon} fontSize='large' />
                    </IconButton>
                </div>
            </div>
            <div className={styles.deck}>
                {panels.map((panel) => {
                    return (
                        <BrandCard
                            key={panel.id}
                            card={panel}
                            setToggleCardDescriptionDic={setToggleCardDescriptionDic}
                            toggleCardDescriptionDic={toggleCardDescriptionDic}
                            leftButtonLabel='Select Study'
                            leftButtonAction={() => history.push(`/conversation?id=${id}&panelId=${panel.id}`)}
                            isNew={true}
                        // onClickClone={() => onClickClone(study)} // TODO: AFTER MVP ADD CLONE FUNCTIONALITY
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default StartConversation;




