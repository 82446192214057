/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ChatRoundedIcon from '@material-ui/icons/ChatRounded';
import { TertiaryBrandButton } from "../BrandButton";
import { BrandModal } from "../BrandModal";
import { useStoreContext } from "../../../store/Store";
import { onDeletePersonas } from "../../services/PanelService";
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  orangeTypography: {
    color: theme.palette.text.primary,
    fontWeight:  theme.palette.text.fontWeight.bold,
    marginTop: theme.spacing(3),
  },
  subTitle: {
    color: theme.palette.text.primary,
  },
  card: {
    width: '500px',
    height: 'fit-content',
    borderRadius: '10px',
    backgroundColor: theme.palette.background.white,
    boxShadow: theme.palette.shadow.large
  },
  deck: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'start',
    paddingTop: theme.spacing(4),
    gap: theme.spacing(4),
  },
  cardTitle: {
    width: '100%',
    textAlign: 'start',
    color: theme.palette.text.primary,
    borderBottom: `1px solid ${theme.palette.border.primary}`,
    paddingLeft: theme.spacing(1),
  },
  attribute: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1.5),
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.border.primary}`,
  },
  firstTypography: {
    whiteSpace: 'nowrap', /* This prevents text wrapping */
    color: theme.palette.text.primary
  },
  typographyText: {
    color: theme.palette.text.primary
  },
  toggleAttributes: {
    textAlign: 'center',
    cursor: 'pointer',
    background: theme.palette.background.primary,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    color: theme.palette.text.primary,
    '&:hover': {
      opacity: 0.7
    }
  },
  description: {
    padding: theme.spacing(1),
    color: theme.palette.text.primary
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    borderBottom: `1px solid ${theme.palette.border.primary}`,
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.6),
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(0.6),
    '& .MuiButtonBase-root': {
      width: 'fit-content',
    },
    '& .MuiButton-label': {
      gap: theme.spacing(0.5),
      alignItems: 'flex-start',
      justifyContent: 'center',
      '& .MuiSvgIcon-root': {
        height: '20px',
        width: '20px',
      }
    },
    '&:hover': {
      '& .MuiButtonBase-root:first-child': {
        color: theme.palette.text.successSecondary,
      },
      '& .MuiButtonBase-root:nth-child(2)': {
          color: theme.palette.text.error,
      }
    },
    '& .MuiButtonBase-root:first-child': {
      color: theme.palette.text.successPrimary,
    },
    '& .MuiButtonBase-root:nth-child(2)': {
      color: theme.palette.text.errorSecondary,
    }
  },
  warning: {
    marginBottom: theme.spacing(5),
    marginRight: theme.spacing(5),
    fontSize: theme.palette.text.size.md
  },
  warningSubText: {
    color: theme.palette.text.error
  },
  numberOfPersonas: {
    marginLeft: theme.spacing(0.6),
    color: theme.palette.text.succesPrimary,
  },
  titleContainer:{
    display: 'flex',
    alignItems: 'center',
  }
}));

const PersonasDeck = ({ generatedPersonas, setGeneratedPersonas, panelId, disableDelete, hideTitle }) => {
  const styles = useStyles();
  const [filteredPersonas, setFilteredPersonas] = useState([]);
  const [personaForDeletion, setPersonaForDeletion] = useState(null);
  const [, setState] = useStoreContext();

  useEffect(() => {
    setFilteredPersonas([...generatedPersonas]);
  }, [generatedPersonas]); // replace with filters in the future

  const onClickDelete = async (persona) => {
    try {
      setPersonaForDeletion(null);
      await onDeletePersonas([persona.id], panelId);
      setState(state => (state.toggleStatusModal = { message: 'Panel deleted successfully', isSuccessModal: true, title: 'Success' }, { ...state }));
      setGeneratedPersonas(generatedPersonas.filter(p => p.id !== persona.id));
    } catch (e) {
      setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
      console.error(e?.response?.data || e);
    }
  };

  return (
    <>
      <BrandModal
        onClose={() => setPersonaForDeletion(null)}
        open={Boolean(personaForDeletion)}
        clickAwayClosable={true}
        title='Delete Persona'
        titleWithDot={true}
        isWarningModal={true}
        leftBtnText='Cancel'
        rightBtnText='Delete Persona'
        leftBtnAction={() => setPersonaForDeletion(null)}
        rightBtnAction={() => onClickDelete(personaForDeletion)}
      >
        <Typography variant='body2' component='div' className={styles.warning}>
          Are you sure you want to delete this persona?<br />
          <span className={styles.warningSubText}>Your conversation will be lost</span><br />
          This action cannot be undone.
        </Typography>
      </BrandModal>
      <div>
        {hideTitle ? null :
          <div className={styles.toolbar}>
            <div>
              <div className={styles.titleContainer}>
                <Typography
                  className={styles.orangeTypography}
                  variant='h6'
                  component='div'
                >
                  3. View Generated Personas
                </Typography>
                <Typography
                  className={clsx(styles.orangeTypography, styles.numberOfPersonas)}
                  variant='h6'
                  component='div'
                >
                  ({generatedPersonas.length} Personas)
                </Typography>
              </div>
              <Typography
                variant='body1'
                component='div'
              >
                View, chat and delete Personas
              </Typography>
            </div>
          </div>}
        <div className={styles.deck}>
          {filteredPersonas.map(persona => {
            const keys = Object.keys(persona);
            return <PersonaCard
              key={persona.id + persona.Name}
              persona={persona}
              personaKeys={keys}
              setPersonaForDeletion={setPersonaForDeletion}
              panelId={panelId}
              disableDelete={disableDelete}
            />
          })}
        </div>
      </div>
    </>
  );
}

export default PersonasDeck;

const PersonaCard = ({ persona, personaKeys, setPersonaForDeletion, panelId, disableDelete }) => {
  const styles = useStyles();
  const [, setState] = useStoreContext();
  const [togglePersonaAttributes, setTogglePersonaAttributes] = useState(false);
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    const _keys = personaKeys.filter(key => key !== 'id' && key !== 'templateId' && key !== 'Name' && key !== 'Description' && key !== 'specifications');
    setKeys(_keys);
  }, [personaKeys]);

  return (
    <div className={styles.card}>
      <div>
        <div className={styles.actions}>
          <TertiaryBrandButton
            onClick={() => setState(state => (state.persona = { ...persona, panelId: panelId }, { ...state }))}
          >
            <div>Chat</div>
            <ChatRoundedIcon />
          </TertiaryBrandButton>
          {!disableDelete &&
            <TertiaryBrandButton onClick={() => setPersonaForDeletion(persona)}>
              <div>Delete</div>
              <DeleteOutlineOutlinedIcon />
            </TertiaryBrandButton>
          }
        </div>
        <Typography variant='h6' component='div' className={styles.cardTitle}>
          {persona.Name}
        </Typography>
      </div>
      <div>
        {keys.map((key, index) => {
          if (index > 3) return null;
          return (
            <div className={styles.attribute} key={persona.id + key}>
              <Typography variant='body1' component='div' color='primary' className={clsx(styles.firstTypography, styles.typographyText)}>
                {key}
              </Typography>
              <Typography className={styles.typographyText} variant='body1' component='div'>
                {persona[key]}
              </Typography>
            </div>
          );
        })}
        {togglePersonaAttributes ?
          keys.map((key, index) => {
            if (index <= 3) return null;
            return (
              <div className={styles.attribute} key={persona.id + key}>
                <Typography variant='body1' component='div' color='primary' className={clsx(styles.firstTypography, styles.typographyText)}>
                  {key}
                </Typography>
                <Typography className={styles.typographyText} variant='body1' component='div'>
                  {persona[key]}
                </Typography>
              </div>
            );
          })
          :
          null
        }
        <Typography
          variant='h6'
          component='div'
          onClick={() => setTogglePersonaAttributes(!togglePersonaAttributes)}
          className={styles.toggleAttributes}
        >
          View All Attributes {togglePersonaAttributes ? '-' : '+'}
        </Typography>
        <Typography variant='body1' component='div' className={styles.description}>
          {persona.Description}
        </Typography>
      </div>
    </div>
  );
}
