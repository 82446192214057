/* eslint-disable no-sequences */
import { useState } from "react";
import { BrandInput, BrandPasswordInput } from "../BrandInput";
import makeStyles from '@material-ui/core/styles/makeStyles';
import { BrandSelect, BrandMenuItem } from '../BrandSelect'
import { BrandButton } from "../BrandButton";
import { inviteUser } from "../../services/UserService";
import { BrandLoaderDots } from "../BrandLoader";
import { useStoreContext } from "../../../store/Store";

const useStyles = makeStyles((theme) => ({
    form: {
        width: '324px',
        padding: theme.spacing(3),
        backgroundColor: theme.palette.background.white
    },
    requiredIndicator: {
        color: theme.palette.text.error
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(1),
        marginTop: theme.spacing(6),
        '& .MuiButton-root': {
            width: 'fit-content',
        },
        '& .MuiButton-root:nth-child(1)': {
            '& .MuiButton-label': {
                color: theme.palette.text.primary,
            }
        }
    },
    placeholder: {
        color: theme.palette.text.primary
    }
}));
const InviteUserForm = ({ onClose, toggleUserInvitedSuccessfully, refreshUsers }) => {
    const styles = useStyles();
    const [, setState] = useStoreContext();
    const $firstName = useState('');
    const $lastName = useState('');
    const $username = useState('');
    const $email = useState('');
    const $password = useState('');
    const $confirmPassword = useState('');
    const $selectedUserType = useState('User');
    const $apiKey = useState('');
    const [userTypes] = useState(['User', 'Admin']);
    const [loading, setLoading] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();
        if ($password[0] === $confirmPassword[0]) {
            try {
                setLoading(true);
                const data = await inviteUser($firstName[0], $lastName[0], $username[0], $email[0], $password[0], $selectedUserType[0], $apiKey[0]);
                if (Array.isArray(data) && data.length) {
                    toggleUserInvitedSuccessfully();
                    refreshUsers();
                } else {
                    throw new Error('Failed to invite user');
                }
            } catch (e) {
                setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
                console.error(e?.response?.data || e);
            } finally {
                onClose();
                setLoading(false);
            }
        }
    };

    return (
        <form className={styles.form} onSubmit={onSubmit}>
            <BrandInput
                label={<span>First Name<span className={styles.requiredIndicator}>*</span></span>}
                placeholder='First Name'
                required
                $value={$firstName}
            />
            <BrandInput
                label={<span>Last Name<span className={styles.requiredIndicator}>*</span></span>}
                placeholder='Last Name'
                required
                $value={$lastName}
            />
            <BrandInput
                label={<span>Username<span className={styles.requiredIndicator}>*</span></span>}
                placeholder='Username'
                required
                $value={$username}
            />
            <div>
                <BrandSelect
                    $value={$selectedUserType}
                    label={<span>User Type<span className={styles.requiredIndicator}>*</span></span>}
                >
                    {userTypes.map(userType => (
                        <BrandMenuItem key={userType} value={userType}>
                            {userType}
                        </BrandMenuItem>
                    ))}
                </BrandSelect>
            </div>
            <BrandInput
                label={<span>Email<span className={styles.requiredIndicator}>*</span></span>}
                placeholder='Email'
                type='email'
                required
                $value={$email}
            />
            <BrandPasswordInput
                label={<span>Password<span className={styles.requiredIndicator}>*</span></span>}
                placeholder='Password'
                required
                $value={$password}
            />
            <BrandPasswordInput
                label={<span>Confirm Password<span className={styles.requiredIndicator}>*</span></span>}
                placeholder='Confirm Password'
                required
                $value={$confirmPassword}
            />
            <BrandInput
                label={<span>Api Key<span className={styles.requiredIndicator}>*</span></span>}
                placeholder='Api Key'
                required
                $value={$apiKey}
            />
            <div className={styles.actions}>
                <BrandButton variant='outlined' onClick={onClose}>
                    Cancel
                </BrandButton>
                <BrandButton type='submit'>
                    {loading ?
                        <BrandLoaderDots />
                        :
                        'Invite'
                    }
                </BrandButton>
            </div>
        </form>
    );
}

export default InviteUserForm;