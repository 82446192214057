import React from "react";
import Loader from 'react-loader-spinner';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles({
    root: {
        height: 30,
        marginBottom: -6,
        background: '#FFFFFFE6',
        borderRadius: '2px',
        '& .MuiLinearProgress-bar': {
            background: 'linear-gradient(270deg,#62E162, #03B56D);'
        }
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
    },
    label: {
        fontSize: '18px',
        letterSpacing: '0.9px',
        //fontFamily: 'Roboto Light,Sans-serif',
    },
    dot: {
        marginLeft: '-5px',
        color: '#F96C00',
        marginTop: 10,
        fontSize: '16px',
        letterSpacing: '0.8px',
        //fontFamily: 'Roboto Light,Sans-serif',
    }
});

export function BrandLoaderDots(params) {
    return (
        <BrandLoader
            type='ThreeDots'
            height={14}
            width={70}
        />
    );
}

export function BrandLoaderSpinner(params) {
    return (
        <BrandLoader />
    );
}

export function BrandLoader({ type = 'TailSpin', color = '#D7CDE5', height = 80, width = 80 }) {
    return (
        <Loader
            type={type}
            color={color}
            height={height}
            width={width}
        />
    );
}

function LinearProgressWithLabel(props) {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.label}>
                <span>{`Data Completion: ${Math.round(
                    props.value,
                )}%`}</span>
                <div className={classes.dot}>
                    <FiberManualRecordIcon style={{ color: '#F96C00', marginBottom: -5 }} />
                    See Identified Missing Data
                </div>
            </div>
            <Box width="300px" marginLeft={-3}>
                <LinearProgress className={classes.root} variant="determinate" {...props} />
            </Box>

        </div>
    );
}

export function BrandProgressBar({ progress }) {
    return (
        <LinearProgressWithLabel value={progress} />
    );
}