/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { TertiaryBrandButton } from "../BrandButton";
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
        width: '100%',
        '& .MuiButtonBase-root': {
            width: 'fit-content',
        },
        '& .MuiButton-label': {
            gap: theme.spacing(0.5),
            alignItems: 'center',
            justifyContent: 'center',
            '& .MuiSvgIcon-root': {
                height: '20px',
                width: '20px',
            }
        },
        '& .MuiButtonBase-root:first-child': {
            color: theme.palette.text.successPrimary,
            '&:hover': {
                color: theme.palette.text.successSecondary,
            }
        },
        '& .MuiButtonBase-root:nth-child(2)': {
            color: theme.palette.text.errorSecondary,
            '&:hover': {
                color: theme.palette.text.error,
            }
        }
    },
    formControlContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'baseline',
        gap: theme.spacing(2),
    },
    formGroup:{
        marginLeft: theme.spacing(3),
        '& .MuiCheckbox-colorPrimary': {
            color: theme.palette.iconColor.white,
            '& .MuiCheckbox-Typography': {
                color: theme.palette.text.error

            },
        },
        '& .MuiCheckbox-root': {
            '& .MuiButtonBase-root:nth-child(1)': {
                color: theme.palette.text.error
            }
        }

    },
    secondaryText:{
        borderBottom: `1px solid ${theme.palette.border.success}`,
        paddingBottom: theme.spacing(1),
        color: theme.palette.text.white,
        fontSize: theme.palette.text.size.xs,
        fontWeight: theme.palette.text.fontWeight.bold,

    },
    primaryText: {
        color: theme.palette.text.successSecondary,
        fontWeight: theme.palette.text.fontWeight.bold,
        fontSize: theme.palette.text.size.lg
    },
    selectAllBtn: {
        color: 'red',
    },
    selectNoneBtn: {
        color: theme.palette.text.error
    },
    filterLabel: {
        color: theme.palette.text.disabledSecondary
    },
    checkboxColor: {
        color: theme.palette.iconColor.white
    }
}));

const TemplatesFiltersList = ({
    filters,
    setFilters,
    selectedFilters,
    setSelectedFilters,
    attributes,
    showLeftSide,
}) => {
    const styles = useStyles();

    useEffect(() => {
        const _filters = [];
        const selectedFilters = {};
        for (const key in attributes) {
            if (Object.hasOwnProperty.call(attributes, key)) {
                const attribute = attributes[key];
                if (!attribute.selectedOptions || attribute.selectedOptions.length === 0) continue;
                const filter = {
                    name: attribute.name,
                    attributeId: attribute.id,
                    options: [...attribute.selectedOptions]
                };
                selectedFilters[attribute.id] = [];
                _filters.push(filter);
            }
        }
        setSelectedFilters(selectedFilters);
        setFilters(_filters);
    }, [attributes]);

    const onClickSelectAll = () => {
        const _selectedFilters = { ...selectedFilters };
        for (const key in _selectedFilters) {
            if (Object.hasOwnProperty.call(_selectedFilters, key)) {
                // eslint-disable-next-line eqeqeq
                _selectedFilters[key] = filters.find(filter => filter.attributeId == key).options;
            }
        }
        setSelectedFilters(_selectedFilters);
    };

    const onClickSelectNone = () => {
        const _selectedFilters = { ...selectedFilters };
        for (const key in _selectedFilters) {
            if (Object.hasOwnProperty.call(_selectedFilters, key)) {
                _selectedFilters[key] = [];
            }
        }
        setSelectedFilters(_selectedFilters);
    };

    if (!showLeftSide) {
        return null;
    }

    return (
        <div className={styles.container}>
            <Typography className={styles.primaryText} variant="h6" color='primary' component='div'>Filters</Typography>
            <Typography variant="body1" component='div' className={styles.secondaryText}>Filter through your personas</Typography>
            <div className={styles.actions}>
                <TertiaryBrandButton onClick={onClickSelectAll}>
                    Select All
                </TertiaryBrandButton>
                <TertiaryBrandButton onClick={onClickSelectNone}>
                    Select None
                </TertiaryBrandButton>
            </div>
            <div className={styles.formControlContainer}>
                {filters.map((filter) => {
                    return (
                        <FormControl key={filter.name} component="fieldset">
                            <Typography className={styles.filterLabel} variant="body1" color='primary' component='legend'>{filter.name}</Typography>
                            <FormGroup className={styles.formGroup}>
                                {filter.options.map((option) => {
                                    return (
                                        <FormControlLabel
                                            key={option.id + '-' + option.attributeId}
                                            control={<Checkbox
                                                color="primary"
                                                checked={selectedFilters[filter.attributeId] && selectedFilters[filter.attributeId].includes(option)}
                                                onChange={() => {
                                                    const _selectedFilters = { ...selectedFilters };
                                                    if (_selectedFilters[filter.attributeId].includes(option)) {
                                                        _selectedFilters[filter.attributeId] = _selectedFilters[filter.attributeId].filter((item) => item !== option);
                                                    } else {
                                                        _selectedFilters[filter.attributeId].push(option);
                                                    }
                                                    setSelectedFilters(_selectedFilters);
                                                }}
                                                name={option.option}
                                            />}
                                            label={<span className={selectedFilters[filter.attributeId] && selectedFilters[filter.attributeId].includes(option) && styles.checkboxColor}>{option.option}</span>}
                                        />
                                    );
                                })}
                            </FormGroup>
                        </FormControl>
                    );
                })}
            </div>
        </div>
    );
}

export default TemplatesFiltersList;