import React from "react";
import { Button } from "@material-ui/core";
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        textTransform: 'none',
        borderRadius: theme.spacing(5),
        padding: theme.spacing(1, 2),
        background: 'transparent',
        whiteSpace: 'nowrap',
        border: `2px solid ${theme.palette.border.default}`,
        '&:disabled': {
            color: theme.palette.text.disabled,
            background: theme.palette.background.disabled,
            border: theme.palette.border.disabled
        },
        // label
        '& .MuiButton-label': {
            fontWeight:  theme.palette.text.fontWeight.semiBold,
            fontSize: theme.palette.text.size.md,
        },
    },
    tertiaryRoot: {
        display: 'flex',
        textTransform: 'none',
        borderRadius: theme.spacing(1.1),
        padding: theme.spacing(0.5),
        background: 'transparent',
        border: 'none',
        boxShadow: 'none',
        '& .MuiButton-label': {
            justifyContent: 'start',
        },
        '&:disabled': {
            color: theme.palette.text.disabled,
            background: 'transparent',
            opacity: 0.6,
        },
    },
    tertiaryRootHoverEffect: {
        '&:hover': {
            background: 'none',
            boxShadow: 'none',
        },
    },
    tertiaryRootHoverlessEffect: {
        '&:hover': {
            background: 'none',
            boxShadow: 'none',
        },
    },
    brandButtonWithBackground: {
        background: theme.palette.background.secondary,
        border: 'none',
        color: `${theme.palette.text.secondary} !important`,
        '&:hover': {
            background: theme.palette.background.blue,
        }
    },
    brandButtonWithoutBackground: {
        background: 'transparent',
        border: `2px solid ${theme.palette.border.secondary}`,
        '& .MuiButton-label': {
            color: `${theme.palette.text.primary} !important`,
        },
        '&:hover': {
            border: `2px solid ${theme.palette.border.secondary}`,
            color: theme.palette.text.primary,
        }
    }
}));

export function BrandButton(params) {
    const styles = useStyles();
    return (
        <Button variant="contained" color="primary" classes={{ root: !params.variant ? clsx(styles.root, styles.brandButtonWithBackground) : clsx(styles.root, styles.brandButtonWithoutBackground) }} {...params} />
    );
}

export function SecondaryBrandButton(params) {
    const styles = useStyles();
    return (
        <Button variant="contained" color="secondary" classes={{ root: styles.secondaryRoot }} {...params} />
    );
}

export function TertiaryBrandButton(params) {
    const styles = useStyles();
    return (
        <Button variant="contained" classes={{ root: clsx(styles.tertiaryRoot, params.hoverless ? styles.tertiaryRootHoverlessEffect : styles.tertiaryRootHoverEffect) }} {...params} />
    );
}

export function BrandInformationalButton(params) {
    const styles = useStyles();
    return (
        <Button variant="outlined" style={{
            backgroundColor: '#1B1B1B',
            color: 'white', alignItems: 'center', textAlign: 'center'
        }} classes={{ root: styles.root }} {...params} />
    );
}

export function BrandNeutralButton(params) {
    const styles = useStyles();
    return (
        <Button variant="outlined" style={{ background: '#343434 0% 0% no-repeat padding-box', borderColor: '1px solid #F4F4F48A', color: '#62E162', alignItems: 'center', textAlign: 'center' }} classes={{ root: styles.root }} {...params} />
    );
}

export function DeleteRowButton(params) {
    return (
        <Button variant="contained" {...params} />
    );
}
