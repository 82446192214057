import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ListItem, Typography } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    closeEndCardContainer: {
        width: '100%',
        height: 'max-content',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: theme.palette.background.primary,
        border: `2px solid ${theme.palette.border.primary}`,
        borderRadius: '10px',
        margin: theme.spacing(2,0)
    },
    tableHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        borderBottom: `2px solid ${theme.palette.border.primary}`,
    },
    headerQuestion: {
        borderRight: `2px solid ${theme.palette.border.primary}`,
    },
    rowWIdth: {
        width: '50%',
        height: 'max-content',
        textAlign: 'center',
        padding: theme.spacing(2,1),
        fontWeight: theme.palette.text.fontWeight.bold
    },
    tableContent: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    answersCell: {
        width: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        flexWrap: 'wrap',
        gap: theme.spacing(.5),
        borderLeft: `2px solid ${theme.palette.border.primary}`,
    },
    questionCell: {
        width: '50%',
    },
    answerText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: theme.spacing(.5),
    },
    quesiton: {
        margin: theme.spacing(1,2),
        textAlign: 'center',
    },
    tableRow: {
        display: 'flex',
        width: '100%',
        borderBottom: `2px solid ${theme.palette.border.primary}`,
    }
}));

const SummaryCard = ({data}) => {
    const styles = useStyles();

  return (
    <div className={styles.closeEndCardContainer}>
        <div className={styles.tableHeader}>
            <Typography className={clsx(styles.rowWIdth, styles.headerQuestion)}>Questions</Typography>
            <Typography className={styles.rowWIdth}>Answers</Typography>
        </div>
        <div className={styles.tableContent}>
            {
                data && data.length > 0 &&
                data.map(((d, index) => {
                    return (
                        <div className={styles.tableRow}>
                            <div className={styles.questionCell}>
                                <Typography className={styles.quesiton}>{index + 1}.{d.name}</Typography>
                            </div>
                            <div className={styles.answersCell}>
                                {
                                    d.answers.map(answer => {
                                        return (
                                            <ListItem key={answer.answerId} className={styles.answerText}>
                                                <Typography>{Math.round((answer.answeredPersonas * 100) / d.personas)}%</Typography>
                                                <Typography>{answer.answerName}</Typography>
                                                <Typography>({answer.answeredPersonas} Personas)</Typography>
                                            </ListItem>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                }))
            }
        </div>
    </div>
  )
}

export default SummaryCard