import React from 'react';
import { useQuery } from '../utils/RouteUtils';
import CreateStudy from './CreateStudy';
import ViewStudy from './ViewStudy';
import Studies from './Studies';

const StudiesPage = () => {
    const { create, id } = useQuery();

    if (create) return <CreateStudy />
    if (id) return <ViewStudy id={id} />
    return <Studies />
}

export default StudiesPage;






