/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { BrandModal } from '../CoreComponents/BrandModal';
import { useHistory } from 'react-router-dom';
import { onGeneratePersonas, onGetPersonas, onPanelSetup } from '../services/PanelService';
import { useStoreContext } from '../../store/Store';
import LeftPanel from '../CoreComponents/PanelsComponents/LeftPanel';
import RightPanel from '../CoreComponents/PanelsComponents/RightPanel';
import MiddlePanel from '../CoreComponents/PanelsComponents/MiddlePanel';
import { useQuery } from '../utils/RouteUtils';

const generateRandomIndexes = (length, maxX) => {
    const indexes = [];
    const usedIndexes = new Set();

    while (indexes.length < length) {
        const randomIndex = Math.floor(Math.random() * maxX) + 1;
        if (!usedIndexes.has(randomIndex)) {
            indexes.push(randomIndex);
            usedIndexes.add(randomIndex);
        }
    }

    return indexes;
};

const useStyles = makeStyles((theme) => ({
    createPanelPageLayout: {
        display: 'flex',
        height: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    warning: {
        marginBottom: theme.spacing(5),
        marginRight: theme.spacing(5),
        fontSize: theme.palette.text.size.md
    },
    warningSubText: {
        color: theme.palette.text.error
    },
}));

const CreatePanel = () => {
    const styles = useStyles();
    const history = useHistory();
    const [customAttributeForDeletion, setCustomAttributeForDeletion] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [state, setState] = useStoreContext();
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showLeftSide, setShowLeftSide] = useState(true);
    const numberOfPersonas = useRef(0);
    const [numberOfPersonasLimit, setNumberOfPersonasLimit] = useState('0');
    const [filters, setFilters] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [attributes, setAttributes] = useState({});
    const [attributesList, setAttributesList] = useState([]);
    const $panelName = useState('');
    const [savedDescription, setSavedDescription] = useState('');
    const [possibleCombinations, setPossibleCombinations] = useState([]);
    const [generatedPersonas, setGeneratedPersonas] = useState([]);
    const [panelId, setPanelId] = useState(null);
    const [step2Metadata, setStep2Metadata] = useState(null);
    const [step3Metadata, setStep3Metadata] = useState(null);
    const [showNoticeModal, setShowNoticeModal] = useState(false);
    const [disableNext, setDisableNext] = useState(false);
    const [useTheNewWay, setUseTheNewWay] = useState(false);
    const { id } = useQuery();

    const onClickCancelPanel = () => history.push('/panels');
    const toggleLeftSide = () => setShowLeftSide(!showLeftSide);
    const toggleCancelModal = () => setShowCancelModal(!showCancelModal);
    const toggleNoticeModal = () => setShowNoticeModal(!showNoticeModal);
    const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

    const onClickPrevious = () => {
        switch (activeStep) {
            case 0:
                if ($panelName[0] || savedDescription || possibleCombinations.length) {
                    toggleCancelModal();
                } else {
                    onClickCancelPanel();
                }
                break;
            case 1:
                setStep2Metadata({
                    possibleCombinations: JSON.stringify(possibleCombinations),
                    attributes: JSON.stringify(attributes),
                    numberOfPersonas: numberOfPersonas.current,
                    numberOfPersonasLimit: Number(numberOfPersonasLimit)
                });
                handleBack();
                break;
            case 2:
                setStep3Metadata({
                    generatedPersonas: JSON.stringify(generatedPersonas),
                    panelId: panelId,
                    name: $panelName[0],
                    description: savedDescription,
                    possibleCombinations: JSON.stringify(possibleCombinations),
                    attributes: JSON.stringify(attributes),
                    numberOfPersonas: numberOfPersonas.current,
                    numberOfPersonasLimit: Number(numberOfPersonasLimit)
                });
                handleBack();
                break;
            default:
                break;
        }
    };

    const onClickNext = async () => {
        switch (activeStep) {
            case 0:
                if (step2Metadata && step2Metadata.attributes === JSON.stringify(attributes)) {
                    numberOfPersonas.current = step2Metadata.numberOfPersonas;
                    setNumberOfPersonasLimit(Number(step2Metadata.numberOfPersonasLimit));
                    setStep2Metadata(null);
                    handleNext();
                } else {
                    setPossiblePersonasLimit();
                    handleNext();
                }
                break;
            case 1:
                if (step3Metadata && step3Metadata.generatedPersonas === JSON.stringify(generatedPersonas)) {
                    toggleNoticeModal();
                    return;
                }
                await generatePersonas();
                break;
            case 2:
                setState(state => (state.panel = {
                    id: panelId,
                    name: $panelName[0],
                    description: savedDescription,
                    attributes: attributes,
                    possibleCombinations: possibleCombinations,
                    generatedPersonas: generatedPersonas,
                    numberOfPersonas: numberOfPersonas.current,
                    numberOfPersonasLimit: Number(numberOfPersonasLimit)
                }, { ...state }));
                history.push(`/panels?id=${panelId}`)
                break;
            default:
                break;
        }
    };

    const setPossiblePersonasLimit = () => {
        setNumberOfPersonasLimit(possibleCombinations.length <= 250 ? possibleCombinations.length : 250);
    }

    const generatePersonas = async (goToNext = true, panelsAttributesWereEdited = false, panelNameOrDescriptionWasEdited = false) => {
        try {
            setState(state => (state.isLoading = true, { ...state }))
            const _possibleCombinations = JSON.parse(JSON.stringify(possibleCombinations));
            const ratio = numberOfPersonasLimit / numberOfPersonas.current;

            let newSum = 0
            for (const _possibleCombination of _possibleCombinations) {
                _possibleCombination.numberOfPersonasBeforeGeneration = _possibleCombination.numberOfPersonas;
                _possibleCombination.numberOfPersonas = Math.floor(_possibleCombination.numberOfPersonas * ratio);
                newSum += _possibleCombination.numberOfPersonas;
            }

            if (newSum < numberOfPersonasLimit) {
                const indexes = generateRandomIndexes(numberOfPersonasLimit - newSum, _possibleCombinations.length - 1)
                for (const index of indexes) {
                    _possibleCombinations[index].numberOfPersonas++;
                }
            }

            const res = await onGeneratePersonas(
                $panelName[0],
                savedDescription,
                attributes,
                _possibleCombinations,
                panelId,
                panelsAttributesWereEdited,
                panelNameOrDescriptionWasEdited,
                useTheNewWay
            );
            setPanelId(res.panelId);
            fetchStartingQuestionsAnswers(res.panelId, goToNext);
        } catch (e) {
            setState(state => (state.isLoading = false, state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
            console.error(e?.response?.data || e);
        }
    };

    const fetchStartingQuestionsAnswers = async (_panelId, goToNext) => {
        try {
            const __panelId = _panelId || panelId;

            const res = await onGetPersonas(__panelId);
            if (res.length === 0) {
                setTimeout(() => fetchStartingQuestionsAnswers(_panelId, goToNext), 10000);
            } else {
                setGeneratedPersonas(res);
                setState(state => (state.isLoading = false, { ...state }))
                if (goToNext) {
                    handleNext();
                }
            }
        } catch (e) {
            setState(state => (state.isLoading = false, state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
            console.error(e?.response?.data || e);
        }
    };

    const nextIsDisabled = () => {
        switch (activeStep) {
            case 0:
                const requiredAttributeIds = attributesList.filter(attribute => attribute.required).map(attribute => attribute.id);
                possibleCombinations.forEach(combination => {
                    if (requiredAttributeIds.length) {
                        combination.builtFrom.forEach(attributeOption => {
                            if (requiredAttributeIds.includes(attributeOption.attributeId)) {
                                requiredAttributeIds.splice(requiredAttributeIds.indexOf(attributeOption.attributeId), 1)
                            }
                        });
                    }
                });

                for (const id in attributes) {
                    const attribute = attributes[id];
                    if (!attribute.selectedOptions || !attribute.selectedOptions.length) {
                        return true;
                    }
                    for (const selectedOption of attribute.selectedOptions) {
                        if (selectedOption.isCustom && !selectedOption.option) {
                            return true;
                        }

                        if (!Object.keys(selectedOption).length) {
                            return true;
                        }
                    }
                }

                return !$panelName[0] || Boolean(requiredAttributeIds.length);
            case 1:
                return numberOfPersonas.current === 0;
            case 2:
                return false;
            default:
                return false;
        }
    };

    const onClickContinueWithoutGenerating = () => {
        $panelName[1](step3Metadata.name);
        setSavedDescription(step3Metadata.description);
        setGeneratedPersonas(JSON.parse(step3Metadata.generatedPersonas));
        setPanelId(step3Metadata.panelId);
        setPossibleCombinations(JSON.parse(step3Metadata.possibleCombinations));
        numberOfPersonas.current = step3Metadata.numberOfPersonas;
        setNumberOfPersonasLimit(Number(step3Metadata.numberOfPersonasLimit));
        setStep3Metadata(null);
        toggleNoticeModal();
        handleNext();
    };

    const onClickRegeneratePersonas = async () => {
        let panelsAttributesWereEdited = false;
        let panelNameOrDescriptionWasEdited = false;

        if (step3Metadata.name !== $panelName[0] || step3Metadata.description !== savedDescription) {
            panelNameOrDescriptionWasEdited = true;
        }

        if (step3Metadata.attributes !== JSON.stringify(attributes)
            || step3Metadata.possibleCombinations !== JSON.stringify(possibleCombinations)) {
            panelsAttributesWereEdited = true;
        }

        toggleNoticeModal();
        await generatePersonas(true, panelsAttributesWereEdited, panelNameOrDescriptionWasEdited);
        setStep3Metadata(null);
    };

    useEffect(() => {
        if (!state.fetchingPanelSetupData) {
            setDisableNext(nextIsDisabled())
        }
    }, [activeStep, possibleCombinations, $panelName, attributes, state.fetchingPanelSetupData]);

    useEffect(async () => {
        if (state.fetchingPanelSetupData && id) {
            try {
                const res = await onPanelSetup(id);
                numberOfPersonas.current = res.numberOfPersonas;
                setNumberOfPersonasLimit(res.numberOfPersonasLimit);
                setAttributes(res.attributes);
                setAttributesList(res.attributesList);
                setPossibleCombinations(res.possibleCombinations);
                setGeneratedPersonas(res.personas);
                $panelName[1](res.panelName);
                setSavedDescription(res.panelDescription);



                setPanelId(id);
                setActiveStep(2);
                // filters generate like in TemplatesFiltersList
                // selectedFilters generate like in TemplatesFiltersList
            } catch (e) {
                setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
                console.error(e?.response?.data || e);
                history.push('/panels');
            } finally {
                setState(state => (state.fetchingPanelSetupData = false, state.isLoading = false, { ...state }))
            }
        }
    }, [state.fetchingPanelSetupData])

    if (state.fetchingPanelSetupData && id) {
        return <></>;
    }

    return (
        <>
            <BrandModal
                onClose={toggleNoticeModal}
                open={showNoticeModal}
                title='Notice'
                titleWithDot={true}
                leftBtnText='Continue without generating'
                rightBtnText='Generate Personas'
                leftBtnAction={onClickContinueWithoutGenerating}
                rightBtnAction={onClickRegeneratePersonas}
            >
                <Typography variant='body2' component='div' className={styles.warning}>
                    You already have generated Personas.<br />
                    Generating new Personas will override the existing ones.<br />
                    Do you want to generate new Personas or continue without generating?<br />
                </Typography >
            </BrandModal >
            <BrandModal
                onClose={toggleCancelModal}
                open={showCancelModal}
                clickAwayClosable={true}
                title='Cancel Panel'
                titleWithDot={true}
                leftBtnText='Stay on page'
                rightBtnText='Cancel Panel'
                leftBtnAction={toggleCancelModal}
                rightBtnAction={onClickCancelPanel}
            >
                <Typography variant='body2' component='div' className={styles.warning}>
                    Are you sure you want to cancel this panel?<br />
                    Your attributes will be lost.
                </Typography>
            </BrandModal>
            <div className={styles.createPanelPageLayout}>
                {activeStep === 2 ? //TODO: AFTER MVP ADD FILTRATION FOR PERSONAS
                    null
                    :
                    <LeftPanel
                        activeStep={activeStep}
                        toggleLeftSide={toggleLeftSide}
                        attributesList={attributesList}
                        attributes={attributes}
                        setAttributes={setAttributes}
                        showLeftSide={showLeftSide}
                        customAttributeForDeletion={customAttributeForDeletion}
                        setCustomAttributeForDeletion={setCustomAttributeForDeletion}
                        filters={filters}
                        selectedFilters={selectedFilters}
                        setFilters={setFilters}
                        setSelectedFilters={setSelectedFilters}
                        generatedPersonas={generatedPersonas}
                        setGeneratedPersonas={setGeneratedPersonas}
                    />
                }
                <MiddlePanel
                    showLeftSide={showLeftSide}
                    activeStep={activeStep}
                    $panelName={$panelName}
                    savedDescription={savedDescription}
                    setSavedDescription={setSavedDescription}
                    attributesList={attributesList}
                    setAttributesList={setAttributesList}
                    attributes={attributes}
                    setAttributes={setAttributes}
                    setCustomAttributeForDeletion={setCustomAttributeForDeletion}
                    selectedFilters={selectedFilters}
                    possibleCombinations={possibleCombinations}
                    setPossibleCombinations={setPossibleCombinations}
                    step2Metadata={step2Metadata}
                    generatedPersonas={generatedPersonas}
                    setGeneratedPersonas={setGeneratedPersonas}
                    setActiveStep={setActiveStep}
                    panelId={panelId}
                    numberOfPersonas={numberOfPersonas}
                />
                <RightPanel
                    activeStep={activeStep}
                    possibleCombinations={possibleCombinations}
                    onClickPrevious={onClickPrevious}
                    onClickNext={onClickNext}
                    nextIsDisabled={disableNext}
                    numberOfPersonas={numberOfPersonas}
                    numberOfPersonasLimit={numberOfPersonasLimit}
                    setNumberOfPersonasLimit={setNumberOfPersonasLimit}
                    onClickRegenerate={() => generatePersonas(false)}
                    useTheNewWay={useTheNewWay}
                    setUseTheNewWay={setUseTheNewWay}
                />
            </div >
        </>
    );
};

export default CreatePanel;

