import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import { Typography } from '@material-ui/core';
import { IconSwitch } from './IconSwitch';
import { useLocationChange } from '../../utils/RouteUtils';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { TertiaryBrandButton } from '../BrandButton';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    typography: {
        textTransform: 'capitalize',
        color: theme.palette.text.white
    },
    avatar: {
        backgroundColor: theme.palette.background.black,
        border: '1px solid',
        width: theme.spacing(9),
        height: theme.spacing(9),
        '& .MuiSvgIcon-root': {
            width: '36px',
            height: '36px'
        }
    },
    activeAvatar: {
        borderColor: theme.palette.border.primary,
        '& .MuiSvgIcon-root': {
            color: theme.palette.iconColor.white
        }
    },
    inactiveAvatar: {
        borderColor: 'transparent'
    },
    avatarContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiButtonBase-root': {
            '&:hover': {
                opacity: 0.8
            }
        }
    },
    tree: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(1),
    },
    firstBranch: {
        width: '25px',
        backgroundColor: 'transparent',
        borderRadius: '3px',
        borderTop: `2px solid ${theme.palette.border.success}`,
        marginBottom: '-3px'
    },
    branch: {
        width: '25px',
        height: '43px',
        backgroundColor: 'transparent',
        borderRadius: '3px',
        borderLeft: `2px solid ${theme.palette.border.success}`,
        borderBottom: `2px solid ${theme.palette.border.success}`,
        marginTop: '-3px',
        marginLeft: '25px'
    },
    hiddenLineBranch: {
        width: '25px',
        height: '43px',
        borderRadius: '3px',
        backgroundColor: 'transparent',
        borderLeft: '2px solid transparent',
        borderBottom: '2px solid transparent',
        marginTop: '-3px',
        marginLeft: '25px'
    },
    smallBranchLine: {
        width: '17px',
        backgroundColor: 'transparent',
        borderBottom: `2px solid ${theme.palette.border.success}`,
        marginTop: '1px',
        marginLeft: '35px'
    },
    hiddenBranch: {
        height: '32.5px',
    },
    branchesContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '-10px',
    },
    activeBranch: {
        color: theme.palette.background.primary
    },
    inactiveBranch: {
        color: theme.palette.background.primary
    }
}));

export const NavigationAvatar = ({ label, branches, setExtended, extended }) => {
    const styles = useStyles();
    const [isActive, setIsActive] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const history = useHistory();

    useLocationChange(() => {
        setIsActive(window.location.href.includes(`/${label}`));
    })

    const onClick = () => {
        if (branches) {
            const _extended = { ...extended };
            Object.keys(_extended).forEach(key => _extended[key] = false);
            _extended[label] = true;
            setExtended(_extended);
            setIsClicked(true);
            return;
        }
        history.push(`/${label}`);
    }

    const onClickAway = () => {
        setIsClicked(false);
        if (branches) {
            setExtended({ ...extended, [label]: false });
        }
    };

    const onClickBranch = (branch) => {
        history.push(branch.navigationPath);
        onClickAway();
    };

    return (
        <ClickAwayListener onClickAway={onClickAway}>
            <div className={styles.avatarContainer}>
                <IconButton disableFocusRipple disableRipple disableTouchRipple onClick={onClick}>
                    <Avatar className={clsx(styles.avatar, isActive ? styles.activeAvatar : styles.inactiveAvatar)}>
                        <IconSwitch label={label} />
                    </Avatar>
                    <Typography className={styles.typography} variant='subtitle2' component='div'>{label}</Typography>
                </IconButton>
                {branches && isClicked ?
                    <div className={styles.branchesContainer}>
                        <div>
                            {branches.map((branch, index) =>
                                branch.name !== 'empty' ?
                                    <div key={label + branch.name + 'branch'}>
                                        <div className={index === 0 ? styles.firstBranch : styles.branch} />
                                        {index === 0 ?
                                            <div className={styles.smallBranchLine} />
                                            :
                                            null
                                        }
                                    </div>
                                    :
                                    <div key={label + branch.name + 'branch'} className={styles.hiddenLineBranch} />
                            )}
                        </div>
                        <div className={styles.tree}>
                            {branches.map((branch) => {
                                const isActive = window.location.pathname+window.location.search === branch.navigationPath;
                                return (
                                    branch.name !== 'empty' ?
                                        <TertiaryBrandButton className={isActive ? styles.activeBranch : styles.inactiveBranch} key={label + branch.name} onClick={() => onClickBranch(branch)}>{branch.name}</TertiaryBrandButton>
                                        :
                                        <div key={label + branch.name} className={styles.hiddenBranch} />
                                )
                            })}
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        </ClickAwayListener>
    );
}