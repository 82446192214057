/* eslint-disable no-sequences */
import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { signInAutomatically } from './components/services/AppService';
import { AppTheme } from './AppTheme';
import PageLayout from './components/pages/PageLayout';
import { StoreContext, initialStoreState } from './store/Store';
import LoggedOutPageLayout from './components/pages/LoggedOutPageLayout';

const App = () => {
  const [storeState, setStoreState] = useState(initialStoreState);
  const [isLoading, setIsLoading] = useState(false);
  const previousPage = useRef([]);

  previousPage.current.push(window.location.pathname + window.location.search);

  useEffect(() => {
    signInAutomatically(setStoreState, setIsLoading)
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      localStorage.setItem('fromReload', 'true');
      localStorage.setItem('pageBeforeReload', window.location.pathname + window.location.search);
      previousPage.current = [];
      setStoreState(state => (state.study = null, { ...state }))
    };

    const pageChange = () => {
      localStorage.setItem('fromReload', 'true');
      localStorage.setItem('pageBeforeReload', previousPage.current[0]);
      previousPage.current = [];
      setStoreState(state => (state.study = null, { ...state }))
    }

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('DOMContentLoaded', pageChange);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('DOMContentLoaded', pageChange);
    }
  }, []);

  if (isLoading) {
    return <div>Loading...</div>
  }

  return (
    <ThemeProvider theme={AppTheme}>
      <StoreContext.Provider value={[storeState, setStoreState]}>
        <BrowserRouter>
          {storeState.user ?
            <PageLayout showUsers={storeState.user.user_type === 'Admin'} />
            :
            <LoggedOutPageLayout />
          }
        </BrowserRouter>
      </StoreContext.Provider>
    </ThemeProvider>
  );
}

export default App;

