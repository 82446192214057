import { createTheme } from '@material-ui/core';

export const AppTheme = createTheme({
    palette: {
      shadow: {
        small: '0 1px 2px 0 #0E16211A',
        mid: '0 4px 6px -1px #0E16211A, 0 2px 4px -2px #0E16211A',
        large: '0 10px 15px -3px #0E16211A, 0 4px 6px -4px #0E16211A',
        extraLarge: 'box-shadow: 0 20px 25px -5px #0E16211A, 0 8px 10px -6px #0E16211A',
      },
      border: {
        default: '#575F73',
        primary: '#DCDFE8',
        secondary: '#848C9F',
        error: '#FF6363',
        success: '#49C087',
        disabled: '#A0A2AF',
        blue: '#586FA9',
      },
      text: {
        default: '#292B31',
        primary: '#575F73',
        secondary: '#FAFBFF',
        successPrimary: '#379F6D',
        successSecondary: '#49C087',
        black: '#1C1C1F',
        white: '#EBEDF3',
        lightGrey: '#DCDFE8',
        error: '#FF6363',
        errorSecondary: '#F53F3F',
        warning: '',
        disabled: '#A0A2AF',
        disabledSecondary: '#848C9F',
        blue: '#586FA9',
        dark: '#1C1C1F',
        size: {
          xs: '14px',
          sm: '16px',
          md: '18px',
          lg: '20px',
          xl: '24px',
          xxl: '32px',
          xxxl: '48px'
        },
        fontWeight: {
          normal: '400',
          semiBold: '500',
          bold: '700',
        },
        fontFamily: {
          primary: 'Roboto, sans-serif',
        }
      },
      background: {
        default: '#DCDFE8',
        primary: '#EBEDF3',
        secondary: '#575F73',
        success: '#49C087',
        error: '#FF6363',
        dark: '#212021',
        darkSecondary: '#1C1C1F',
        blue: '#586FA9',
        black: '#292B31',
        white: '#FAFBFF',
        disabled: '#A0A2AF',
        gray: '#848C9F'
      },
      iconColor: {
        success: '#379F6D',
        successSecondary: '#49C087',
        primary: '#DCDFE8',
        secondary: '#575F73',
        error: '#FF6363',
        white: '#EBEDF3',
        default: '#A0A2AF',
        black: '#1C1C1F',
        blue: '#586FA9',
      },
    },
    typography: {
      allVariants: {
        color: '#575F73',
        fontWeight: '500',
      },
    },
    overrides: {
      MuiPaper: {
        root: {
          backgroundColor: '#EBEDF3',
          color: 'Roboto, sans-serif',
        },
      }
    }
  });