/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {  } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import AttributesList from '../PanelsComponents/AttributesList';
import KeyboardArrowLeftRoundedIcon from '@material-ui/icons/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import clsx from 'clsx';
import TemplatesFiltersList from '../PanelsComponents/TemplatesFiltersList';
import PersonasFiltersList from '../PanelsComponents/PersonasFiltersList';

const useStyles = makeStyles((theme) => ({
    toggledAttributesListContainer: {
        width: '354px',
        borderLeft: `2px solid ${theme.palette.border.success}`,
        display: 'flex',
        height: '100%',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.background.darkSecondary
    },
    hiddenAttributesListContainer: {
        width: '48px',

    },
    toggleBtnContainer: {
        width: '48px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    toggleContainerIcon: {
        color: theme.palette.iconColor.success,
    }
}));

const LeftPanel = ({
    activeStep,
    toggleLeftSide,
    attributesList,
    attributes,
    setAttributes,
    showLeftSide,
    customAttributeForDeletion,
    setCustomAttributeForDeletion,
    filters,
    selectedFilters,
    setFilters,
    setSelectedFilters,
    generatedPersonas,
    setGeneratedPersonas
}) => {
    const styles = useStyles();
    return (
        <div className={clsx(styles.toggledAttributesListContainer, showLeftSide ? null : styles.hiddenAttributesListContainer)}>
            {activeStep === 0 && <AttributesList
                attributesList={attributesList}
                selectedAttributes={attributes}
                setSelectedAttributes={setAttributes}
                showLeftSide={showLeftSide}
                customAttributeForDeletion={customAttributeForDeletion}
                setCustomAttributeForDeletion={setCustomAttributeForDeletion}
            />}
            {activeStep === 1 && <TemplatesFiltersList
                filters={filters}
                selectedFilters={selectedFilters}
                setFilters={setFilters}
                setSelectedFilters={setSelectedFilters}
                attributes={attributes}
                showLeftSide={showLeftSide}
            />}
            {activeStep === 2 &&
                <PersonasFiltersList
                    generatedPersonas={generatedPersonas}
                    setGeneratedPersonas={setGeneratedPersonas}
                    showLeftSide={showLeftSide}
                />
            }
            <div className={styles.toggleBtnContainer}>
                <IconButton onClick={toggleLeftSide}>
                    {showLeftSide ?
                        <KeyboardArrowLeftRoundedIcon className={styles.toggleContainerIcon} />
                        :
                        <KeyboardArrowRightRoundedIcon className={styles.toggleContainerIcon} />
                    }
                </IconButton>
            </div>
        </div>
    );
};

export default LeftPanel;
