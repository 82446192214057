/* eslint-disable no-sequences */
import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useStoreContext } from '../../store/Store';
import { onGetStudies, onDeleteStudy } from '../services/StudyService';
import { IconButton, Typography } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import { BrandButton } from '../CoreComponents/BrandButton';
import { BrandSearchInput } from '../CoreComponents/BrandInput';
import { debounce } from '../utils/FetchUtils';
import BrandCard from '../CoreComponents/BrandCard';
import SortRoundedIcon from '@material-ui/icons/SortRounded';
import { BrandModal } from '../CoreComponents/BrandModal';
import BrandSortOptions from '../CoreComponents/BrandSortOptions';

const SORT_OPTIONS = [
    {
        label: 'Date',
        type: 'radio',
        options: [
            {
                label: 'Newest',
                sortType: 'DESC'
            },
            {
                label: 'Oldest',
                sortType: 'ASC'
            }
        ]
    },
    {
        label: 'Name',
        type: 'radio',
        options: [
            {
                label: 'A-Z',
                sortType: 'ASC'
            },
            {
                label: 'Z-A',
                sortType: 'DESC'
            }
        ]
    }
]

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3)
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        paddingBottom: theme.spacing(1.5),
        borderBottom: `2px solid ${theme.palette.border.primary}`,
        color: theme.palette.text.primary,
        fontWeight:  theme.palette.text.fontWeight.bold,
        '& .MuiSvgIcon-root': {
            width: '36px',
            height: '36px'
        }
    },
    toolbar: {
        marginTop: theme.spacing(5),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    leftSide: {
        display: 'flex',
        gap: theme.spacing(1),
        '& .MuiButtonBase-root': {
            width: theme.spacing(20)
        },
        '& .MuiInputBase-root': {
            width: 'fit-content'
        }
    },
    deck: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(4),
        paddingTop: theme.spacing(4),
    },
    warning: {
        marginBottom: theme.spacing(5),
        marginRight: theme.spacing(5),
        fontSize: theme.palette.text.size.md
    },
    warningSubText: {
        color: theme.palette.text.error
    },
    pageIcon: {
        color: theme.palette.iconColor.secondary
    },
    sortIcon: {
       color: theme.palette.iconColor.secondary
    }
}));

const INPUTBACKGROUND = 'transparent';

const Studies = () => {
    const styles = useStyles();
    const history = useHistory();
    const [, setState] = useStoreContext();
    const [studies, setStudies] = useState([])
    const [search, setSearch] = useState('');
    const [searchFilter, setFilterSearch] = useState('');
    const [toggleCardDescriptionDic, setToggleCardDescriptionDic] = useState({});
    const [openDrawer, setOpenDrawer] = useState(null);
    const [selectedSortOptions, setSelectedSortOptions] = useState({ date: 'ASC', name: 'ASC' });
    const [studyForDeletion, setStudyForDeletion] = useState(null);

    const prepare = async () => {
        try {
            const studyData = await onGetStudies(null, null, searchFilter, selectedSortOptions.date, selectedSortOptions.name);
            setStudies(studyData || []);
        } catch (e) {
            setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
            console.error(e?.response?.data || e);
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => prepare(), [searchFilter, selectedSortOptions]);

    const debounceMemo = useMemo(
        () => debounce(
            (event) => {
                setSearch(event.target.value);
            }, (event) => {
                setFilterSearch(event.target.value);
            }, 500),
        [],
    );

    const openStudy = (study) => {
        setState(state => (state.study = study, { ...state }));
        history.push(`/studies?id=${study.id}`);
    };

    const onClickDelete = async (study) => {
        try {
            await onDeleteStudy(study.id);
            setState(state => (state.toggleStatusModal = { message: 'Study deleted successfully', isSuccessModal: true, title: 'Success' }, { ...state }));
            prepare();
        } catch (e) {
            setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
            console.error(e?.response?.data || e);;
        }
        finally {
            setStudyForDeletion(null);
        }
    };

    return (
        <div className={styles.container}>
            <BrandModal
                onClose={() => setStudyForDeletion(null)}
                open={Boolean(studyForDeletion)}
                clickAwayClosable={true}
                title='Delete Study'
                titleWithDot={true}
                isWarningModal={true}
                leftBtnText='Cancel'
                rightBtnText='Delete Study'
                leftBtnAction={() => setStudyForDeletion(null)}
                rightBtnAction={() => onClickDelete(studyForDeletion)}
            >
                <Typography variant='body2' component='div' className={styles.warning}>
                    Are you sure you want to delete this study?<br />
                    <span className={ styles.warningSubText }>Your questions will be lost.</span>
                </Typography>
            </BrandModal>
            <BrandSortOptions
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
                sortOptions={SORT_OPTIONS}
                selectedSortOptions={selectedSortOptions}
                setSelectedSortOptions={setSelectedSortOptions}
            />
            <Typography variant="h3" component='div' className={styles.title}>
                <ListIcon className={styles.pageIcon} color='primary' />
                <div>Studies</div>
            </Typography>
            <div className={styles.toolbar}>
                <div className={styles.leftSide}>
                    <BrandButton onClick={() => history.push('/studies?create=true')}>
                        Create Study
                    </BrandButton>
                    <BrandSearchInput
                        style={{background: INPUTBACKGROUND}}
                        variant='outlined'
                        placeholder='Search in Studies'
                        value={search}
                        onChange={debounceMemo}
                    />
                </div>
                <div>
                    <IconButton color='primary' onClick={(e) => setOpenDrawer(e.currentTarget)}>
                        <SortRoundedIcon className={styles.sortIcon} color='primary' fontSize='large' />
                    </IconButton>
                </div>
            </div>
            <div className={styles.deck}>
                {studies.map((study) => {
                    return (
                        <BrandCard
                            key={study.id}
                            card={study}
                            setToggleCardDescriptionDic={setToggleCardDescriptionDic}
                            toggleCardDescriptionDic={toggleCardDescriptionDic}
                            leftButtonLabel='Open Study'
                            leftButtonAction={() => openStudy(study)}
                            onClickDelete={() => setStudyForDeletion(study)}
                            onClickResults={() => history.push(`/conversation?id=${study.id}${study.panel_id ? `&panelId=${study.panel_id}` : ''}`)}
                            isNew={study.isNew}
                            // onClickClone={() => onClickClone(study)} // TODO: AFTER MVP ADD CLONE FUNCTIONALITY
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default Studies;




