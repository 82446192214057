/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ListItemSecondaryAction, Typography, ListItemIcon } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { TertiaryBrandButton } from '../BrandButton';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import AddEditQuestionListItemForm from './AddEditQuestionListItemForm';
import clsx from 'clsx';
import Checkbox from '@material-ui/core/Checkbox';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

const useStyles = makeStyles((theme) => ({
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexWrap: 'wrap',
        marginBottom: theme.spacing(1),
        '& .MuiButtonBase-root': {
            width: 'fit-content',
        },
        '& .MuiButton-label': {
            gap: theme.spacing(0.5),
            alignItems: 'flex-start',
            justifyContent: 'center',
            '& .MuiSvgIcon-root': {
                height: '20px',
                width: '20px',
            }
        },
        '& .MuiButtonBase-root:first-child': {
            color: theme.palette.text.errorSecondary,
            '&:hover': {
                color: theme.palette.text.error,
            }
        },
        '& .MuiButtonBase-root:first-child(2)': {
            color: theme.palette.text.successPirmary,
            '&:hover': {
                color: theme.palette.text.successSecondary,
            }
        },
    },
    answerOptions: {
        display: 'flex',
        gap: theme.spacing(2.5),
        marginLeft: theme.spacing(2.5),
        marginTop: theme.spacing(1.5),
    },
    answerOption: {
        display: 'flex',
        gap: theme.spacing(0.5),
        color: theme.palette.text.primary,
        alignItems: 'center',
        '& .MuiSvgIcon-root': {
            color: theme.palette.text.primary,
            height: '5px',
            width: '5px',
        }
    },
    list: {
        width: '73%',
        borderRadius: theme.spacing(1.5),
        boxShadow: theme.palette.shadow.large,
        backgroundColor: theme.palette.background.white,
        marginTop: theme.spacing(2)
    },
    listItem: {
        borderBottom: `2px solid ${theme.palette.border.primary}`,
    },
    listTitle: {
        color: theme.palette.text.primary,
        fontWeight: theme.palette.text.fontWeight.semiBold,
        fontSize: theme.palette.text.size.sm
    },
    visibilityIcon: {
        color: theme.palette.iconColor.success,
        marginLeft: theme.spacing(2),
        '&:hover': {
            color: theme.palette.iconColor.successSecondary,
        }
    },
    answered: {
        padding: theme.spacing(3,0),
        color: theme.palette.text.primary,
        fontWeight: theme.palette.text.fontWeight.bold
    },
    no: {
        marginRight: theme.spacing(6.3),
    },
    openAnswers: {
        color: theme.palette.text.primary,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        gap: theme.spacing(1)
    },
    option: {
        color: theme.palette.text.primary
    },
    additionalQuestionActions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        wordBreak: "break-all",
    },
    checkboxContainer: {
        '& .MuiCheckbox-colorPrimary': {
            color: theme.palette.text.primary,
        },
    }
}));

const BUTTON_STYLE = '#379F6D';

const QuestionsList = ({
    questions,
    setQuestions,
    studyIsNew = true,
    selectedAdditionalQuestions,
    setSelectedAdditionalQuestions,
    handleOpenQuestionModal,
    questionsWithAnswers,
    inConversationPage,
    onCustomSubmitForm,
    onCustomDeleteQuestion,
    tab,
    refreshSelectedQuestions,
    personas,
    questionAnswersCount,
}) => {
    const styles = useStyles();
    const [questionForEditDic, setQuestionForEditDic] = useState([]);
    const [allAreSelected, setAllAreSelected] = useState(false);

    const handleDeleteQuestion = (index) => {
        if (onCustomDeleteQuestion) {
            onCustomDeleteQuestion(questions[index].id, index)
            return;
        }
        const newQuestions = [...questions];
        newQuestions.splice(index, 1);
        setQuestions(newQuestions);
    }

    const handleEditQuestion = (index) => {
        setQuestionForEditDic({ ...questionForEditDic, [index]: true });
    }

    useEffect(() => {
        if (setSelectedAdditionalQuestions) {
            setAllAreSelected(Object.keys(selectedAdditionalQuestions).length === questions.length);
        }
    }, [questions, refreshSelectedQuestions]);

    return (
        <List className={styles.list} id='questionsList'>
            {inConversationPage ?
                <ListItem className={clsx(styles.listItem, setSelectedAdditionalQuestions ? null : styles.answered)} >
                    {setSelectedAdditionalQuestions ?
                        <>
                            <ListItemIcon className={styles.checkboxContainer}>
                                <Checkbox
                                    edge="start"
                                    checked={allAreSelected}
                                    onClick={() => {
                                        if (!allAreSelected) {
                                            const selectedAdditionalQuestionsCopy = {};
                                            questions.forEach(question => {
                                                selectedAdditionalQuestionsCopy[question.id] = question;
                                            });
                                            setSelectedAdditionalQuestions(selectedAdditionalQuestionsCopy);
                                        } else {
                                            setSelectedAdditionalQuestions({});
                                        }
                                        setAllAreSelected(!allAreSelected);
                                    }}
                                    tabIndex={-1}
                                    disableRipple
                                    color='primary'
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary='Select all available'
                            />
                        </>
                        :
                        null
                    }
                    <ListItemSecondaryAction >
                        <ListItemText primary={<Typography className={styles.answered} variant="body1" component='div' color='primary'>
                            Answered
                        </Typography>} />
                    </ListItemSecondaryAction>
                </ListItem>
                :
                null
            }
            {questions.map((question, index) => {
                if (questionForEditDic[index] === true) {
                    return <AddEditQuestionListItemForm
                        key={index}
                        toggleQuestionForm={() => setQuestionForEditDic({ ...questionForEditDic, [index]: false })}
                        setQuestions={setQuestions}
                        questions={questions}
                        questionForEdit={question} q
                        questionForEditIndex={index}
                        onCustomSubmitForm={onCustomSubmitForm}
                    />
                }
                return (
                    <ListItem key={index} className={index + 1 !== questions.length || setSelectedAdditionalQuestions ? styles.listItem : null} >
                        {setSelectedAdditionalQuestions ?
                            <ListItemIcon className={styles.checkboxContainer}>
                                <Checkbox
                                    edge="start"
                                    checked={Boolean(selectedAdditionalQuestions[question.id])}
                                    onClick={() => {
                                        const selectedAdditionalQuestionsCopy = { ...selectedAdditionalQuestions };
                                        if (selectedAdditionalQuestionsCopy[question.id]) {
                                            delete selectedAdditionalQuestionsCopy[question.id];
                                        } else {
                                            selectedAdditionalQuestionsCopy[question.id] = question;
                                        }
                                        setAllAreSelected(Object.keys(selectedAdditionalQuestionsCopy).length === questions.length)
                                        setSelectedAdditionalQuestions(selectedAdditionalQuestionsCopy);
                                    }}
                                    tabIndex={-1}
                                    disableRipple
                                    color='primary'
                                />
                            </ListItemIcon>
                            :
                            null
                        }
                        <ListItemText
                            primary={<Typography className={styles.listTitle}>{index + 1}. {question.question}</Typography>}
                            secondary={
                                <div className={styles.answerOptions}>
                                    {question.answerOptions.map((option, index) =>
                                        <div key={index} className={styles.answerOption}>
                                            <FiberManualRecordIcon />
                                            <Typography className={styles.option} variant="body1" component='div' color='primary'>{option}</Typography>
                                        </div>
                                    )}
                                </div>
                            }
                        />
                        {!inConversationPage || tab === 2 ? null :
                            questionsWithAnswers && questionsWithAnswers.length && questionsWithAnswers.includes(question.questionid) ?
                                <ListItemSecondaryAction className={styles.openAnswers} onClick={() => handleOpenQuestionModal(question.question, index, question.questionid, question.answerOptions)}>
                                    <ListItemText primary={'Yes'} />
                                    <VisibilityOutlinedIcon className={styles.visibilityIcon} />
                                </ListItemSecondaryAction>
                                :
                                <ListItemSecondaryAction >
                                    <ListItemText primary={'No'} className={styles.no} />
                                </ListItemSecondaryAction>
                        }
                        {!studyIsNew ? null :
                            <ListItemSecondaryAction className={styles.actions}>
                                <TertiaryBrandButton disabled={inConversationPage && tab === 2 && questionAnswersCount && questionAnswersCount[question.id]?.count > 0} onClick={() => handleDeleteQuestion(index)}>
                                    <div>Delete</div>
                                    <DeleteOutlineOutlinedIcon />
                                </TertiaryBrandButton>
                                <TertiaryBrandButton style={{color: BUTTON_STYLE}} disabled={inConversationPage && tab === 2 && questionAnswersCount && questionAnswersCount[question.id]?.count > 0} onClick={() => handleEditQuestion(index)}>
                                    <div>Edit</div>
                                    <EditOutlinedIcon />
                                </TertiaryBrandButton>
                                {!inConversationPage || tab === 1 ? null :
                                            questionsWithAnswers && questionsWithAnswers.length && questionsWithAnswers.includes(question.id) ?
                                            <ListItemText 
                                            className={styles.additionalQuestionActions} 
                                            primary={<span className={styles.openAnswers}>{questionAnswersCount[question.id] ? questionAnswersCount[question.id]?.count || 0 : 0}/{personas.length}</span>}
                                             secondary={<VisibilityOutlinedIcon className={styles.visibilityIcon} onClick={() => handleOpenQuestionModal(question.question, index, question.id, question.answerOptions)}/>}
                                            />
                                            :
                                            <ListItemText primary={'No'} />
                                }
                            </ListItemSecondaryAction>
                        }
                    </ListItem>);
            })}
        </List>
    );
}

export default QuestionsList;