

import React, { useEffect, useState } from 'react';
import { BrandTextArea } from '../BrandInput';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Typography } from '@material-ui/core';
import { BrandButton, TertiaryBrandButton } from '../BrandButton';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
    action: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(2),
        gap: theme.spacing(2),
        '& .MuiButton-root:nth-child(1)': {
            '& .MuiButton-label': {
                color: theme.palette.text.white,
            }
        }
    },
    questionForm: {
        width: '250px',
        borderRadius: theme.spacing(1.5),
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.white,
        marginTop: theme.spacing(2),
        height: 'fit-content',
        '& .MuiInputBase-root': {
            backgroundColor: 'transparent',
        },
        '& .MuiInputBase-root:nth-child(1)': {
            border: `1px solid ${theme.palette.border.primary}`,
        },
    },
    questionFormInputs: {
        marginTop: theme.spacing(1),
    },
    addAnswerOption: {
        color: theme.palette.text.primary,
        width: 'fit-content',
        '&:hover': {
            opacity: 0.8,
        }
    },
    answerNumber: {
        marginLeft: theme.spacing(2.1),
        color: theme.palette.text.primary
    },
    answerNumberContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .MuiButtonBase-root': {
            color: theme.palette.text.error,
        }
    },
    cancelButton: {
        background: 'transparent',
        border: '2px solid #575F73',
        borderRadius: '42px',
        color: '#575F73 !important',
        '&:hover': {
            border: '2px solid #575F73',
            color: '#575F73',
        }
    },
    defaultOption: {
        width: '90%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: theme.spacing(2,1.2),
        borderRadius: '10px',
        background: theme.palette.background.primary,
        border: `2px solid ${theme.palette.border.primary}`
    }

}));

const BACKGROUND_COLOR = '#EBEDF3';
const DEFAULT_OPTION = 'None of the above';

const AddEditQuestionForm = ({ toggleQuestionForm, setQuestions, questions, questionForEdit, questionForEditIndex, onCustomSubmitForm }) => {
    const styles = useStyles();
    const [question, setQuestion] = useState('');
    const [answerOptions, setAnswerOptions] = useState([]);

    const onSubmitForm = (e) => {
        e.preventDefault();
        const answerOptionsWithDefaultOption = [...answerOptions, DEFAULT_OPTION];

        const study = {
            answerOptions: answerOptions.length > 0 ? answerOptionsWithDefaultOption : answerOptions,
            question: question
        }
        if (onCustomSubmitForm) {
            onCustomSubmitForm(study, questionForEdit, questionForEditIndex);
            return;
        }

        if (questionForEdit && questionForEditIndex !== undefined) {
            const newQuestions = [...questions];
            newQuestions[questionForEditIndex] = study;
            setQuestions(newQuestions);
            toggleQuestionForm();
            return;
        }

        setQuestions([...questions, study]);
        toggleQuestionForm();
    };

    const addBlankAnswerOption = (e) => {
        setAnswerOptions([...answerOptions, '']);
    };

    const formIsDisabled = () => {
        if (question.length === 0) {
            return true;
        }

        if (answerOptions.length === 1) {
            return true;
        }

        const foundQuestion = questions.find((q) => q.question === question);
        if (foundQuestion && questionForEditIndex === undefined) {
            return true;
        }

        const uniqueOptions = new Set(answerOptions);
        if (uniqueOptions.size !== answerOptions.length) {
            return true;
        }

        for (let i = 0; i < answerOptions.length; i++) {
            if (answerOptions[i].length === 0) {
                return true;
            }
        }
        return false;
    }

    const onClickDelete = (e) => {
        const newOptions = [...answerOptions];
        newOptions.splice(answerOptions.length - 1, 1);
        setAnswerOptions(newOptions);
    };

    useEffect(() => {
        if (questionForEdit) {
            setQuestion(questionForEdit.question);
            setAnswerOptions(questionForEdit.answerOptions.map((option) => {
                return option;
            }));
        }

    }, [questionForEdit]);

    return (
        <form onSubmit={onSubmitForm} className={styles.questionForm}>
            <BrandTextArea
                style={{background: BACKGROUND_COLOR}}
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder='Type Question Here'
                required
                rows={5}
            />
            {answerOptions.map((option, index) => {
                return (
                    <div key={index} className={styles.questionFormInputs}>
                        <div className={styles.answerNumberContainer}>
                            <Typography component='div' variant='subtitle1' className={styles.answerNumber}>
                                Option {index + 1}
                            </Typography>
                            <IconButton onClick={onClickDelete}>
                                <ClearIcon />
                            </IconButton>
                        </div>
                        <BrandTextArea
                            style={{background: BACKGROUND_COLOR}}
                            value={option}
                            rows={2}
                            onChange={(e) => {
                                const newOptions = [...answerOptions];
                                newOptions[index] = e.target.value;
                                setAnswerOptions(newOptions);
                            }}
                            placeholder='Type a possible answer here:'
                            required
                        />
                    </div>
                );
            })}
            {
                answerOptions.length > 0 &&
                <div className={styles.defaultOption}><Typography>{DEFAULT_OPTION}</Typography></div>
            }
            {answerOptions.length < 4 ?
                <TertiaryBrandButton
                    className={styles.addAnswerOption}
                    hoverless='true'
                    onClick={addBlankAnswerOption}
                >
                    + Add answer option
                </TertiaryBrandButton>
                :
                null
            }
            <div className={styles.action}>
                <BrandButton
                    variant='outlined'
                    onClick={toggleQuestionForm}
                >
                    Cancel
                </BrandButton>
                <BrandButton
                    disabled={formIsDisabled()}
                    type='submit'
                >
                    Confirm
                </BrandButton>
            </div>
        </form>
    );
}

export default AddEditQuestionForm;