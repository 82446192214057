/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useMemo } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { BrandInput } from '../BrandInput';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import { debounce } from '../../utils/FetchUtils';

const useStyles = makeStyles((theme) => ({
    autoComplete: {
        backgroundColor: theme.palette.background.default,
        '& .MuiInputBase-root': {
            backgroundColor: `${theme.palette.background.default} !important`,
            border: `2px solid ${theme.palette.border.primary}`,
            color: theme.palette.text.primary,
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.iconColor.secondary,
        }
    },
    customBtn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: theme.spacing(0.5),
    },
    iconStyle: {
        color: theme.palette.iconColor.success
    }
}));

export default function AttributesDeckAutocomplete({ options, setSelectedAttribute, property, placeholder, value, hasSelectAll, onClickAddNewOption, allAreSelected, selectedAttributes }) {
    const styles = useStyles();
    const [inputValue, setInputValue] = React.useState('');

    const customOptions = [...options];
    if (hasSelectAll) {
        customOptions.unshift({ [property]: 'Select All', id: -1 });
    }

    if (onClickAddNewOption) {
        customOptions.push({ [property]: 'Click here to add as custom', id: 0 });
    }


    const debounceMemo = useMemo(
        () => debounce(
            (event) => {
                setInputValue(event.target.value);
            }, (e) => {
                const inputValue = e.target.value
                const toLowerCaseInputValue = inputValue.toLowerCase();
                const option = customOptions.find(option => option[property].toLowerCase() === toLowerCaseInputValue);
                if (!option && inputValue) {
                    onClickAddNewOption(inputValue)
                }
            }, 500),
        [],
    );

    return (
        <Autocomplete
            className={styles.autoComplete}
            options={customOptions}
            autoHighlight
            value={Object.values(value).length ? value : null}
            disableClearable={true}
            onChange={(event, newValue) => setSelectedAttribute(newValue)}
            getOptionLabel={(option) => option[property] || ''}
            renderOption={(option) => {
                if (option.id === 0) {
                    return (
                        <div className={styles.customBtn} onClick={() => onClickAddNewOption(inputValue)}>
                            <div>{option[property]}</div>
                            <AddCircleOutlineRoundedIcon className={styles.iconStyle} />
                        </div>);
                }

                return (<span>{option[property]}</span>);
            }}
            onKeyDownCapture={(e) => {
                if (e.key === 'Enter' || e.key === 'Tab') {
                    e.preventDefault();
                    e.stopPropagation();
                    const inputValue = e.target.value
                    const toLowerCaseInputValue = inputValue.toLowerCase();
                    const option = customOptions.find(option => option[property].toLowerCase() === toLowerCaseInputValue);
                    if (!option && inputValue) {
                        onClickAddNewOption(inputValue)
                    }
                }
            }}
            filterOptions={(options, params) => {
                const filtered = options.filter((option) => {
                    const inputValue = params.inputValue;
                    const toLowerCaseInputValue = inputValue.toLowerCase();

                    if (selectedAttributes && selectedAttributes.find(attr => attr.id === option.id)) {
                        return false;
                    }

                    if (option[property] === 'Select All' && (inputValue || allAreSelected)) {
                        return false;
                    }

                    if (option.id === 0) {
                        if (option[property].toLowerCase().includes(toLowerCaseInputValue)) {
                            return false
                        } else if (inputValue) {
                            return true;
                        } else {
                            return false;
                        }
                    }

                    if (inputValue === '') {
                        return true;
                    }

                    return option[property].toLowerCase().includes(toLowerCaseInputValue);
                });

                return filtered;
            }}
            renderInput={(params) => (
                <BrandInput
                    {...params}
                    onChange={debounceMemo}
                    placeholder={placeholder}
                    inputProps={{
                        ...params.inputProps,
                    }}
                />
            )}
        />
    );
};