import React from 'react';
import { useQuery } from '../utils/RouteUtils';
import StartConversation from './StartConversation';
import SetupConversation from './SetupConversation';
import AnswersAndSummary from './AnswersAndSummary';

const Conversation = () => {
    const { panelId, id, summary } = useQuery();

    if (panelId && id && summary) return <AnswersAndSummary />
    if (panelId && id) return <SetupConversation />
    if (id) return <StartConversation />
}

export default Conversation;






