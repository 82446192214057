import axios from 'axios';
import { getToken } from '../utils/FetchUtils';

export const onLogin = async (username, password) => {
  const res = await axios.post(`${process.env.REACT_APP_HOST}/api/user/login`, {
    username,
    password
  });
  return res.data;
};

export const logout = async () => {
  const authorizationToken = getToken();
  const res = await axios.post(`${process.env.REACT_APP_HOST}/api/user/logout`, {}, {
    headers: { Authorization: authorizationToken }
  });
  if (res.status === 200) {
    localStorage.removeItem('token');
  }
  return res.data;
};

export const getMe = async () => {
  const authorizationToken = getToken(true);
  const res = await axios.get(`${process.env.REACT_APP_HOST}/api/user/me`, {
    headers: { Authorization: authorizationToken }
  });
  return res.data;
};

export const getUsers = async (sortBy, sortType, filter) => {
  const authorizationToken = getToken();
  const res = await axios.get(`${process.env.REACT_APP_HOST}/api/user/users?sortBy=${sortBy}&sortType=${sortType}&filter=${filter}`, {
    headers: { Authorization: authorizationToken }
  });
  return res.data;
};

export const inviteUser = async (
  firstName,
  lastName,
  username,
  email,
  password,
  selectedUserType,
  apiKey
) => {
  const authorizationToken = getToken();
  const res = await axios.post(`${process.env.REACT_APP_HOST}/api/user/invite-user`, {
    firstName,
    lastName,
    username,
    email,
    password,
    selectedUserType,
    apiKey
  }, {
    headers: { Authorization: authorizationToken }
  });
  return res.data;
};

export const deleteUser = async (id) => {
  const authorizationToken = getToken();
  const res = await axios.delete(`${process.env.REACT_APP_HOST}/api/user/delete-user`, {
    data: { id },
    headers: { Authorization: authorizationToken }
  });
  return res.data;
};

export const editUser = async (
  id,
  firstName,
  lastName,
  username,
  email,
  password,
  selectedUserType,
  changePassword,
  apiKey
) => {
  const authorizationToken = getToken();

  const res = await axios.put(`${process.env.REACT_APP_HOST}/api/user/edit-user`, {
    id,
    firstName,
    lastName,
    username,
    email,
    password,
    selectedUserType,
    changePasswordFlag: changePassword,
    apiKey
  }, {
    headers: { Authorization: authorizationToken }
  });
  return res.data;
};

export const resetPassword = async (password) => {
  const tempToken = localStorage.getItem('tempToken');
  if (!tempToken) {
    throw new Error('No token found in localStorage');
  }

  const res = await axios.put(`${process.env.REACT_APP_HOST}/api/user/reset-password`, {
    password
  }, {
    headers: { Authorization: tempToken }
  });
  return res.data;
};
