/* eslint-disable no-sequences */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BrandPasswordInput } from '../CoreComponents/BrandInput'
import { BrandButton } from '../CoreComponents/BrandButton'
import { BrandLoaderDots } from '../CoreComponents/BrandLoader'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography } from '@material-ui/core';
import { resetPassword } from '../services/UserService';
import { useStoreContext } from '../../store/Store';
import { BrandAlert } from '../CoreComponents/BrandAlert';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
    },
    form: {
        width: theme.spacing(38.75),
        height: theme.spacing(41.125),
        padding: theme.spacing(2.5),
        border: '1px solid',
        borderColor: theme.palette.border.primary,
        borderRadius: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        '& .MuiInputBase-root': {
            marginBottom: theme.spacing(2),
        },
    },
    buttonPosition: {
        '& .MuiButtonBase-root': {
            marginTop: theme.spacing(3),
        }
    },
    title: {
        textAlign: 'center',
        marginBottom: theme.spacing(3.5),
    },
    forgotPassword: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& .MuiLink-underlineHover': {
            color: theme.palette.text.primary,
            textDecoration: 'underline'
        }
    }
}));

const ResetInitialPassword = () => {
    const styles = useStyles();
    const [, setState] = useStoreContext();
    const history = useHistory();
    const $confirmPassword = useState('');
    const $password = useState('');
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState('');

    useEffect(() => {
        if (!localStorage.getItem('tempToken')) {
            history.push('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            if ($password[0] !== $confirmPassword[0]) {
                setAlert('Passwords do not match!')
                return;
            }

            const data = await resetPassword($password[0])
            if (!data) {
                throw Error('Failed to reset password!')
            }

            localStorage.setItem('token', localStorage.getItem('tempToken'));
            localStorage.removeItem('tempToken');
            setState(state => (state.user = { ...state.tempUser }, state.tempUser = null, { ...state }));
            history.push('/');
            setLoading(false);
        } catch (e) {
            localStorage.clear('');
            setState(state => (state.user = null, state.tempUser = null, { ...state }));
            console.error(e?.response?.data || e.message);
            setAlert(e?.response?.data?.msg || e.message);
            setLoading(false);
        }
    };

    return (
        <div className={styles.formContainer}>
            <form onSubmit={onSubmit} className={styles.form}>
                <Typography variant='h6' component='div' className={styles.title}>Reset your password</Typography>
                <BrandPasswordInput
                    label="Password"
                    placeholder="Enter Password"
                    required
                    $value={$password}
                />
                <BrandPasswordInput
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    required
                    $value={$confirmPassword}
                />
                <BrandAlert>{alert}</BrandAlert>
                <div className={styles.buttonPosition}>
                    <BrandButton type='submit'>
                        {loading ?
                            <BrandLoaderDots />
                            :
                            'Cofnrim'
                        }
                    </BrandButton>
                </div>
            </form>
        </div>
    );
}

export default ResetInitialPassword;