/* eslint-disable eqeqeq */
/* eslint-disable no-sequences */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, makeStyles } from "@material-ui/core";
import { useEffect, useMemo, useRef, useState } from "react";
import { BrandInput } from "../BrandInput";
import { debounce } from '../../utils/FetchUtils';
import clsx from "clsx";
import { BrandButton } from "../BrandButton";

function filterCombinations(array, filters) {
    return array.filter(combination => {
        return Object.entries(filters).every(([attributeId, attributeFilters]) => {
            return attributeFilters.length === 0 || attributeFilters.some(filter => {
                return combination.builtFrom.some(item => {
                    return item.attributeId === filter.attributeId && item.id === filter.id;
                });
            });
        });
    });
}

const useStyles = makeStyles((theme) => ({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
    },
    orangeTypography: {
        color: theme.palette.text.primary,
        fontWeight:  theme.palette.text.fontWeight.bold,
        marginTop: theme.spacing(3),
        fontSize: theme.palette.text.size.lg
    },
    subTitle: {
        color: theme.palette.text.primary,
    },
    card: {
        width: '290px',
        borderRadius: '10px',
        height: 'fit-content',
        backgroundColor: theme.palette.background.white,
        boxShadow: theme.palette.shadow.large
    },
    deck: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(4),
        paddingTop: theme.spacing(4),
        marginTop: theme.spacing(4),
    },
    cardInput: {
        padding: theme.spacing(1),
        borderRadius: '0px 0px 10px 10px',

        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.background.primary,
        '& .MuiTypography-root': {
            minWidth: '200px',
            color: theme.palette.text.primary,
            fontWeight:  theme.palette.text.fontWeight.bold,
        },
        '& .MuiInputBase-root': {
            width: '75px'
        }
    },
    cardTitle: {
        width: '100%',
        textAlign: 'center',
        color: theme.palette.text.primary,
        borderBottom: `2px solid ${theme.palette.border.primary}`,
    },
    attribute: {
        display: 'flex',
        gap: theme.spacing(1.5),
        padding: theme.spacing(1),
        '& .MuiTypography-root': {
            color: theme.palette.text.primary,
        }
    },
    attributeName: {
        fontWeight:  theme.palette.text.fontWeight.bold,
    },
    errorCard: {
        border: `2px solid ${theme.palette.border.error}`
    },
    toolbarButton: {
        display: 'flex',
        gap: theme.spacing(2),
        '& .MuiButton-root': {
            width: theme.spacing(20)
        }
    }
}));

const TemplatesDeck = ({
    selectedFilters,
    possibleCombinations,
    setPossibleCombinations,
    attributes,
    numberOfPersonas
}) => {
    const styles = useStyles();
    const [filteredPossibleCombinations, setFilteredPossibleCombinations] = useState([]);

    useEffect(() => {
        const _filteredPossibleCombinations = filterCombinations(possibleCombinations, selectedFilters);
        setFilteredPossibleCombinations(_filteredPossibleCombinations);
    }, [selectedFilters, possibleCombinations]);

    return (
        <div>
            <div className={styles.toolbar}>
                <div>
                    <Typography
                        className={styles.orangeTypography}
                        variant='h6'
                        component='div'
                    >
                        2. Adjust Personas
                    </Typography>
                    <Typography
                    className={styles.subTitle}
                        variant='body1'
                        component='div'
                    >
                        Review your Personas here.
                    </Typography>
                </div>
                <div className={styles.toolbarButton}>
                    <BrandButton
                        onClick={() => {
                            setPossibleCombinations(possibleCombinations.map(item => {
                                return {
                                    ...item,
                                    numberOfPersonas: 1
                                }
                            }));
                            numberOfPersonas.current = possibleCombinations.length;
                        }}
                    >
                        Set all to 1
                    </BrandButton>
                    <BrandButton
                        onClick={() => {
                            setPossibleCombinations(possibleCombinations.map(item => {
                                return {
                                    ...item,
                                    numberOfPersonas: 0
                                }
                            }));
                            numberOfPersonas.current = 0;
                        }}
                    >
                        Set all to 0
                    </BrandButton>
                </div>
            </div>
            <div className={styles.deck}>
                {filteredPossibleCombinations.map((combination, index) => {
                    return <Template
                        key={combination.combination + '' + index}
                        index={index}
                        combination={combination}
                        attributes={attributes}
                        numberOfPersonas={numberOfPersonas}
                        filteredPossibleCombinations={filteredPossibleCombinations}
                        possibleCombinations={possibleCombinations}
                        setPossibleCombinations={setPossibleCombinations}
                        setFilteredPossibleCombinations={setFilteredPossibleCombinations}
                    />
                })}
            </div>
        </div>
    );
}

const INPUT_STYLES = {background: 'transparent'};

export default TemplatesDeck;


const Template = ({
    index,
    combination,
    attributes,
    filteredPossibleCombinations,
    possibleCombinations,
    setPossibleCombinations,
    setFilteredPossibleCombinations,
    numberOfPersonas
}) => {
    const styles = useStyles();
    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(filteredPossibleCombinations[index].numberOfPersonas == '0'
            || Number(filteredPossibleCombinations[index].numberOfPersonas) >= 0 ?
            filteredPossibleCombinations[index].numberOfPersonas : '');
    }, [filteredPossibleCombinations]);


    const _onChange = (value) => {
        if (Number(value) > 50) {
            value = 50;
        }

        const _filteredPossibleCombinations = [...filteredPossibleCombinations];
        const _possibleCombinations = [...possibleCombinations];

        numberOfPersonas.current = numberOfPersonas.current + value - _filteredPossibleCombinations[index].numberOfPersonas;

        _possibleCombinations.find(item => item.combination === combination.combination).numberOfPersonas = value;
        _filteredPossibleCombinations[index].numberOfPersonas = value;
        setPossibleCombinations(_possibleCombinations);
        setFilteredPossibleCombinations(_filteredPossibleCombinations);
        return true;
    }

    const debounceMemo = useMemo(
        () => debounce(
            (event) => {
                setValue(event.target.value);
            }, (event) => {
                const res = _onChange(Number(event.target.value));
            }, 250),
        [],
    );

    return (
        <div className={ value.length > 0 && Number(value) === 0 ? clsx(styles.card, styles.errorCard) : styles.card} >
            <Typography variant='h6' component='div' className={styles.cardTitle}>
                {combination?.name}
            </Typography>
            <div>
                {combination.builtFrom.map((item) => {
                    const attributeName = attributes[item.attributeId].name;
                    return (
                        <div className={styles.attribute} key={combination.id + '' + item.attributeId + '' + item.id}>
                            <Typography className={styles.attributeName} variant='body1' component='div' color='primary'>
                                {attributeName}
                            </Typography>
                            <Typography className={styles.option} variant='body1' component='div'>
                                {item.option}
                            </Typography>
                        </div>
                    );
                })}
            </div>
            <div className={styles.cardInput}>
                <Typography variant='body1' component='div' color='primary'>
                    Select number of personas
                </Typography>
                <BrandInput
                    style={INPUT_STYLES}
                    value={value}
                    onChange={debounceMemo}
                    variant='outlined'
                    type='number'
                />
            </div>
        </div>
    );
}