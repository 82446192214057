/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-sequences */
import { useQuery } from '../utils/RouteUtils';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useStoreContext } from '../../store/Store';
import { Breadcrumbs, List, Typography, Popover, ListItem, ListItemText, ListItemIcon, IconButton } from '@material-ui/core';
import { BrandLink } from '../CoreComponents/BrandLink';
import { getAnswers, onAskQuestionsFromStudy, onGetStudies, onAddOrEditAdditionalQuestion, onDeleteQuestion, onGetAdditionalQuestions } from '../services/StudyService';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { onGetPanels, onGetPersonas } from '../services/PanelService';
import { BrandButton, TertiaryBrandButton } from '../CoreComponents/BrandButton';
import QuestionsList from '../CoreComponents/StudiesComponents/QuestionsList';
import clsx from 'clsx';
import PersonasList from '../CoreComponents/StudiesComponents/PersonasList';
import AddEditQuestionForm from '../CoreComponents/StudiesComponents/AddEditQuestionForm';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import PersonaModal from '../CoreComponents/StudiesComponents/PersonaModal';
import QuestionModal from '../CoreComponents/StudiesComponents/QuestionModal';
import { BrandModal } from '../CoreComponents/BrandModal';
import Tooltip from '@material-ui/core/Tooltip';
import SortRoundedIcon from '@material-ui/icons/SortRounded';
import PersonasFilterModal from '../CoreComponents/StudiesComponents/PersonasFilterModal';
import LinearDeterminate from '../CoreComponents/BrandLinearDeterminate';

const useStyles = makeStyles((theme) => ({
    listItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: theme.spacing(1.5, 2),
        borderBottom: `1px solid ${theme.palette.border.secondary}`,
    },
    container: {
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '97%',
    },
    breadcrumbs: {
        color: theme.palette.text.primary,
        fontSize: theme.spacing(1.6),
        marginBottom: theme.spacing(1.5),
        '& .MuiTypography-root': {
            fontSize: theme.spacing(1.6),
        }
    },
    titleContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',

    },
    content: {
        display: 'flex',
        gap: theme.spacing(6),
        marginTop: theme.spacing(3),
        width: '100%',
        height: '65vh'
    },
    leftSide: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        '& #simple-tabpanel-0': {
            width: '100%',
            height: '100%',
        },
        '& .MuiList-root': {
            width: '99%',
            height: '100%',
            marginTop: '0px',
            overflowY: 'auto',
        },
        '& .MuiList-padding': {
            padding: 0
        },
        '& .MuiAppBar-root': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            '& .MuiTab-wrapper': {
                color: theme.palette.text.textDisabled,
                fontWeight: 'bold',
                textTransform: 'none',
            },
            '& .Mui-selected': {
                '& .MuiTab-wrapper': {
                    color: theme.palette.text.primary,
                    borderBottom: `1px solid ${theme.palette.border.primary}`,
                },
            }
        }
    },
    leftSideHeight: {
        '& .MuiList-root': {
            height: 'calc(100% - 48px)',
        },
    },
    rightSide: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    actionsAndAlertContainer: {
        borderTop: `2px solid ${theme.palette.border.primary}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(3),
        '& .MuiButton-root': {
            width: 'fit-content',
        },
        '& .MuiButton-root:nth-child(1)': {
            '& .MuiButton-label': {
                color: theme.palette.text.primary,
            }
        }
    },
    personasListTitle: {
        color: theme.palette.text.primary,
        fontWeight: theme.palette.text.fontWeight.bold

    },
    personasNumber: {
        color: theme.palette.text.successPrimary
    },
    addNewQuestion: {
        color: theme.palette.text.white,
        width: '250px',
        paddingTop: theme.spacing(2.5),
        paddingBottom: theme.spacing(2.5),
        backgroundColor: theme.palette.background.secondary,
        boxShadow: theme.palette.shadow.large,
        marginTop: theme.spacing(2),
        height: 'fit-content',
        '& .MuiButton-label': {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center',
            gap: theme.spacing(1),
            '& span': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }
        },
        '&:hover': {
            background: theme.palette.background.blue,
        }
    },
    additionalQuestionsOverflow: {
        overflowY: 'auto',
        overflowX: 'hidden',
        '& .MuiList-root': {
            height: 'fit-content',
        },
    },
    askAllStartingQuestions: {
        position: 'relative',
        '& .MuiSvgIcon-root:nth-child(1)': {
            zIndex: 0,
        },
        '& .MuiSvgIcon-root:nth-child(2)': {
            position: 'absolute',
            top: '7px',
            left: '8px',
            zIndex: 1,
        }
    },
    breadcrumb: {
        color: theme.palette.text.primary,
    },
    boldBreadcrumb: {
        fontWeight: theme.palette.text.fontWeight.bold
    },
    studyTitle: {
        color: theme.palette.text.primary,
        fontSize: theme.palette.text.size.xl,
        fontWeight: theme.palette.text.fontWeight.bold
    },
    activePanel: {
        color: theme.palette.text.primary,
        fontWeight: theme.palette.text.fontWeight.bold,
    },
    inactivePanel: {
        color: theme.palette.text.disabledSecondary
    },
    note: {
        color: theme.palette.text.primary
    },
    actionText: {
        color: theme.palette.text.primary
    },
    icon: {
        color: theme.palette.iconColor.secondary
    },
    tabs: {
        '& .MuiAppBar-root .Mui-selected .MuiTab-wrapper': {
            borderBottom: '2px solid green'
        },
        "& .MuiTabs-indicator": {
            backgroundColor: "transparent",
        },
        "& .Mui-selected .MuiTab-wrapper": {
            borderBottom: `2px solid ${theme.palette.border.secondary} !important`
        }
    },
    exitButton: {
        color: theme.palette.text.primary,
        border: `2px solid ${theme.palette.border.secondary}`,
        background: 'transparent',
        borderRadius: '42px',
        '&:hover': {
            border: `2px solid ${theme.palette.border.secondary}`,
        }
    },
    warning: {
        marginBottom: theme.spacing(5),
        marginRight: theme.spacing(5),
    },
    tooltip: {
        width: '200px',
        fontSize: theme.spacing(1.8),
        background: theme.palette.background.dark,
        border: `1px solid ${theme.palette.text.white}`
    },
    personasHeader: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    iconButton: {
        padding: theme.spacing(.5),
    },
    sortIcon: {
        color: theme.palette.iconColor.secondary
    },
    appBar: {
        paddingBottom: theme.spacing(1),
    },
    personasHeaderTitle: {
        marginTop: theme.spacing(1),
        display: 'flex',
        width: 'fit-content',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(1),
    },
    progressContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: theme.spacing(6),
    },
    leftSideEmptyContainer: {
        width: '50%',
    },
}));

function isScrollBarVisible(element) {
    if (!element) return false;
    return element.scrollHeight > element.clientHeight;
}

const SetupConversation = () => {
    const styles = useStyles();
    const { panelId, id } = useQuery();
    const history = useHistory();
    const [state, setState] = useStoreContext();
    const [value, setValue] = useState(0);
    const [fixQuestionsListHeight, setFixQuestionsListHeight] = useState(false);
    const [fixPersonasListHeight, setFixPersonasListHeight] = useState(false);
    const [studyName, setStudyName] = useState('');
    const [panelName, setPanelName] = useState('');
    const [questions, setQuestions] = useState([]);
    const [answeredQuestions, setAnsweredQuestions] = useState([]);
    const [answeredAdditionalQuestions, setAnsweredAdditionalQuestions] = useState([]);
    const [additionalQuestions, setAdditionalQuestions] = useState([]);
    const [selectedAdditionalQuestions, setSelectedAdditionalQuestions] = useState({});
    const [personas, setPersonas] = useState([]);
    const [selectedPersonas, setSelectedPersonas] = useState({});
    const [showQuestionForm, setShowQuestionForm] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(null);
    const [togglePersonaModal, setTogglePersonaModal] = useState(null);
    const [personaIds, setPersonaIds] = useState([]);
    const [disableAskAllStartingQuestions, setDisableAskAllStartingQuestions] = useState(true);
    const [allStartingQuestionsAreAnswered, setAllStartingQuestionsAreAnswered] = useState(false);
    const [questionForModal, setQuestionForModal] = useState('');
    const [questionIndex, setQuestionIndex] = useState(0);
    const [questionsWithAnswers, setQuestionsWithAnswers] = useState([]);
    const [additionalQuestionsWithAnswers, setAdditionalQuestionsWithAnswers] = useState([]);
    const [answersForSelectedQuestion, setAnswersForSelectedQuestion] = useState([]);
    const [openQuestionModal, setOpenQuestionModal] = useState(false);
    const [answersOptions, setAnswersOptions] = useState([]);
    const [selectedAdditionalQuestionForDeletion, setSelectedAdditionalQuestionForDeletion] = useState(null);
    const [enableAskSelectedQuestions, setEnableAskSelectedQuestions] = useState(false);
    const [refreshSelectedQuestionsAndPersonas, setRefreshSelectedQuestionsAndPersonas] = useState({});
    const [personaAnswersCount, setPersonaAnswersCount] = useState({});
    const [questionAnswersCount, setQuestionsAnswersCount] = useState({});
    const [pendingAdditionalQuestions, setPendingAdditionalQuestions] = useState([]);
    const [openFilterDrawer, setOpenFilterDrawer] = useState(null);
    const [personasFilters, setPersonaFilters] = useState([]);
    const [selectedPersonasFilters, setSelectedPersonasFilters] = useState({});
    const [filteredPersonas, setFilteredPersonas] = useState([]);
    const [pendingStartingQuestions, setPendingStartingQuestions] = useState([]);

    const refresh = (e) => e.preventDefault();
    const handleChange = (event, newValue) => setValue(newValue);
    const toggleQuestionForm = () => setShowQuestionForm(!showQuestionForm);

    const generateFilters = (personas) => {
        const filters = personas.reduce((acc, persona) => {
            persona.specifications.forEach(spec => {
                if (acc[spec.attributeName]) {
                    if (!acc[spec.attributeName].find(f => f.option === spec.option)) {
                        acc[spec.attributeName].push(spec)
                    }
                } else {
                    acc[spec.attributeName] = [spec]
                }
            })
            return acc
        }, {})
        return filters
    }

    const filterPersonas = (allPersonas, filters) => {
        return allPersonas.filter(persona => {
            return Object.entries(filters).every(([attributeId, attributeFilters]) => {
                return attributeFilters.length === 0 || attributeFilters.some(filter => {
                    return persona.specifications.some(specification => {
                        return specification.attributeId === filter.attributeId && specification.id === filter.id;
                    });
                });
            });
        });
    }

    useEffect(() => {
        if (Object.keys(selectedPersonasFilters).length > 0) {
            const filteredPersonas = filterPersonas(personas, selectedPersonasFilters);
            setFilteredPersonas(filteredPersonas);
        } else {
            setFilteredPersonas(personas);
        }
    }, [selectedPersonasFilters]);

    useEffect(() => {
        if (personas && personas.length > 0) {
            const filters = generateFilters(personas);
            setPersonaFilters(filters);
        }
    }, [personas]);

    const handleFindAnswersForQuestions = (quesitonId) => {
        const findAnswersForQuestions = value === 0 ? answeredQuestions.filter(answer => answer.question_id === quesitonId) : answeredAdditionalQuestions.filter(answer => answer.question_id === quesitonId);
        return findAnswersForQuestions;
    }

    const handleOpenQuestionModal = (question, questionIndex, questionId, availableOptions) => {
        setAnswersForSelectedQuestion([]);

        //get answers for the new selected question//
        const answers = handleFindAnswersForQuestions(questionId);
        setAnswersForSelectedQuestion(answers);
        setQuestionForModal(question);
        setQuestionIndex(questionIndex);
        setAnswersOptions(availableOptions);
        setOpenQuestionModal(true);
    };

    const handleCloseQUesitonModal = () => {
        setOpenQuestionModal(false);
    };

    useEffect(() => {
        if (answeredAdditionalQuestions && answeredAdditionalQuestions.length > 0) {

            let personasAnswersLength = fillObjectWithData(personas, false);
            let personasForQuestionsLength = fillObjectWithData(additionalQuestions, true);

            setPersonaAnswersCount(personasAnswersLength);
            setQuestionsAnswersCount(personasForQuestionsLength);

        }
    }, [answeredAdditionalQuestions]);

    const fillObjectWithData = (_array, isForQuestions) => {
        let currentObject = {};

        //create object keys//
        _array.forEach(object => {
            currentObject[object.id] = { count: 0 };
        });

        //fill objects//
        answeredAdditionalQuestions.forEach(answer => {
            if (currentObject[isForQuestions ? answer.question_id : answer.persona_id]) {
                currentObject[isForQuestions ? answer.question_id : answer.persona_id] = { count: currentObject[isForQuestions ? answer.question_id : answer.persona_id].count + 1 };
            }
        })

        return currentObject;
    }

    const prepare = async () => {
        try {
            setState(state => (state.isLoading = true, { ...state }));
            const studyData = await onGetStudies(id);
            if (!studyData.length) {
                history.push('/studies');
            }

            if (studyData[0].panel_id && Number(studyData[0].panel_id) !== Number(panelId)) {
                history.push('/studies');
            }

            studyData[0].questions.forEach(question => {
                if (!question.answeroptions) {
                    return;
                }
                question.answerOptions = question.answeroptions;
                question.answerOptions = question.answerOptions.filter(option => option !== null);
                delete question.answeroptions;
            });
            const panelData = await onGetPanels(panelId);
            const personas = await onGetPersonas(panelId);
            setState(state => (state.personas = personas, { ...state }));

            await prepareAdditionalQuestions(true);

            setPersonas(personas);
            setFilteredPersonas(personas);
            setPersonaIds(personas.map(persona => persona.id));
            setPanelName(panelData[0]?.name);
            setStudyName(studyData[0]?.name);
            setQuestions(studyData[0]?.questions);
        } catch (e) {
            setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
            console.error(e?.response?.data || e);
        } finally {
            setState(state => (state.isLoading = false, { ...state }));
        }
    };

    const prepareAdditionalQuestions = async (fromPrepare) => {
        try {
            setState(state => (state.isLoading = true, { ...state }));
            if (id && panelId) {
                const additionalQuestionsRes = await onGetAdditionalQuestions(id, panelId);
                for (const question of additionalQuestionsRes) {
                    if (!question.answeroptions) {
                        return;
                    }
                    question.answerOptions = question.answeroptions;
                    question.answerOptions = question.answerOptions.filter(option => option !== null);
                    delete question.answeroptions;
                }
                setAdditionalQuestions(additionalQuestionsRes);
            }
        } catch (e) {
            setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
            console.error(e?.response?.data || e);
        } finally {
            if (!fromPrepare) {
                setState(state => (state.isLoading = false, { ...state }));
            }
        }
    }

    useEffect(() => {
        if (value === 1 && additionalQuestions.length === 0) {
            prepareAdditionalQuestions();
        }
    }, [value])

    const onClickAskQuestions = async (type) => {
        try {
            setState(state => (state.isLoading = true, { ...state }));
            const _personaIds = type === 'starting' ? personaIds : Object.keys(selectedPersonas);
            const _questions = (type === 'starting' ? questions : Object.keys(selectedAdditionalQuestions)).map(question => {

                const _id = type === 'starting' ? question.questionid || question.id : question;
                const _question = type === 'starting' ? question.question : selectedAdditionalQuestions[question].question;
                const _type = type === 'starting' ? question.type : selectedAdditionalQuestions[question].type;
                const _order = type === 'starting' ? question.order : selectedAdditionalQuestions[question].order;
                return {
                    id: _id,
                    question: _question,
                    type: _type,
                    order: _order
                }
            });

            await onAskQuestionsFromStudy(id, panelId, _personaIds, _questions);
            if (type === 'starting') {
                setDisableAskAllStartingQuestions(true);
                fetchStartingQuestionsAnswers();
            } else {
                setSelectedAdditionalQuestions({});
                setSelectedPersonas({});
                setRefreshSelectedQuestionsAndPersonas({});
                fetchAdditionalQuestionsAnswers();
            }
            setState(state => (state.toggleStatusModal = { message: `All ${type} questions have been asked. Waiting for personas to answer.`, isSuccessModal: true, title: 'Success' }, { ...state }));
        } catch (e) {
            setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
            console.error(e?.response?.data || e);
        } finally {
            setState(state => (state.isLoading = false, { ...state }));
        }
    }

    const fetchStartingQuestionsAnswers = async () => {
        if (personaIds.length === 0) return;
        if (questions.length === 0) return;

        try {
            const res = await getAnswers(personaIds, id, 'main');
            const respAnswers = res.answers;
            setPendingStartingQuestions([...res.pendingQuestions])
            setAnsweredQuestions(respAnswers);

            if (res.isPending) {
                setDisableAskAllStartingQuestions(true);
                setTimeout(() => fetchStartingQuestionsAnswers(), 10000);
                return;
            }

            if (res.jobDoesNotExist) {
                if (res.answers.length === 0) {
                    setDisableAskAllStartingQuestions(false);
                }
                return;
            }

            if (!res.isPending) {
                setAllStartingQuestionsAreAnswered(true);
                setDisableAskAllStartingQuestions(true);
                return;
            }

            setTimeout(() => fetchStartingQuestionsAnswers(), 10000);
        } catch (e) {
            setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
            console.error(e?.response?.data || e);
        }
    };

    const fetchAdditionalQuestionsAnswers = async () => {
        if (personaIds.length === 0) return;
        if (additionalQuestions.length === 0) return;

        try {
            const res = await getAnswers(personaIds, id, 'additional');
            setPendingAdditionalQuestions(res.pendingQuestions)
            setAnsweredAdditionalQuestions(res.answers);
            const isPending = res.isPending;

            if (!isPending) {
                return;
            }

            setTimeout(() => fetchAdditionalQuestionsAnswers(), 10000);
        } catch (e) {
            setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
            console.error(e?.response?.data || e);
        }
    };

    const onClickConfirmAdditionalQuestions = async (study, questionForEdit, questionForEditIndex, toggleInternalQuestionForm) => {
        try {
            setState(state => (state.isLoading = true, { ...state }));
            const questionId = questionForEdit && questionForEdit.id ? questionForEdit.id : null;
            const questionOrder = additionalQuestions.length + 1;
            const res = await onAddOrEditAdditionalQuestion(study.question, study.answerOptions, id, questionOrder, questionId);

            if (questionForEdit && questionForEditIndex !== undefined) {
                const newAdditionalQuestions = [...additionalQuestions];
                newAdditionalQuestions[questionForEditIndex] = res;
                setAdditionalQuestions(newAdditionalQuestions);
                toggleInternalQuestionForm();
                return;
            }
            setAdditionalQuestions([...additionalQuestions, res]);
            toggleQuestionForm();

        } catch (e) {
            setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
            console.error(e?.response?.data || e);
        } finally {
            setState(state => (state.isLoading = false, { ...state }));
        }
    };

    const onClickDeleteQuestion = (questionId, index) => setSelectedAdditionalQuestionForDeletion({ questionId, index });

    const onClickDelete = async () => {
        const { questionId, index } = selectedAdditionalQuestionForDeletion;
        try {
            setState(state => (state.isLoading = true, { ...state }));
            await onDeleteQuestion(questionId);
            const newAdditionalQuestions = [...additionalQuestions];
            newAdditionalQuestions.splice(index, 1);
            setAdditionalQuestions(newAdditionalQuestions);
            setState(state => (state.toggleStatusModal = { message: 'Question has been deleted.', isSuccessModal: true, title: 'Success' }, { ...state }));
        } catch (e) {
            setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
            console.error(e?.response?.data || e);
        } finally {
            setState(state => (state.isLoading = false, { ...state }));
            setSelectedAdditionalQuestionForDeletion(null);
        }
    }

    const getAnsweredQuestionsIds = (serverAnswers, tabValue) => {
        if (serverAnswers && serverAnswers.length > 0) {
            const questionsWithAnswersIds = [];
            serverAnswers.forEach(answeredQuestion => {
                if (!questionsWithAnswersIds.includes(Number(answeredQuestion.question_id))) {
                    questionsWithAnswersIds.push(answeredQuestion.question_id)
                }
            })
            if (tabValue === 1) {
                setAdditionalQuestionsWithAnswers([...questionsWithAnswersIds]);
                setQuestionsWithAnswers([]);

            } else {
                setQuestionsWithAnswers([...questionsWithAnswersIds]);
                setAdditionalQuestionsWithAnswers([]);
            }
        }
    }
    useEffect(() => {
        value === 1 ? getAnsweredQuestionsIds(answeredAdditionalQuestions, value) : getAnsweredQuestionsIds(answeredQuestions, value)
    }, [value, answeredQuestions, answeredAdditionalQuestions])


    useEffect(() => prepare(), []);

    useEffect(() => {
        if (questions.length === 0) return;
        setFixQuestionsListHeight(isScrollBarVisible(document.getElementById('questionsList')));
    }, [questions]);

    useEffect(() => {
        if (personas.length === 0) return;
        setFixPersonasListHeight(isScrollBarVisible(document.getElementById('personasList')));
    }, [personas]);

    useEffect(() => fetchStartingQuestionsAnswers(), [personaIds, questions])
    useEffect(() => fetchAdditionalQuestionsAnswers(), [personaIds, additionalQuestions])

    useEffect(() => {
        if (allStartingQuestionsAreAnswered) {
            setEnableAskSelectedQuestions(Object.keys(selectedAdditionalQuestions).length && Object.keys(selectedPersonas).length);
        }
    }, [selectedPersonas, selectedAdditionalQuestions, allStartingQuestionsAreAnswered])

    return (
        <div className={styles.container}>
            <BrandModal
                onClose={() => setSelectedAdditionalQuestionForDeletion(null)}
                open={Boolean(selectedAdditionalQuestionForDeletion)}
                clickAwayClosable={true}
                title='Delete Question'
                titleWithDot={true}
                isWarningModal={true}
                leftBtnText='Cancel'
                rightBtnText='Delete Question'
                leftBtnAction={() => setSelectedAdditionalQuestionForDeletion(null)}
                rightBtnAction={() => onClickDelete()}
            >
                <Typography variant='body2' component='div' className={styles.warning}>
                    Are you sure you want to delete this question?
                </Typography>
            </BrandModal>
            <PersonaModal setTogglePersonaModal={setTogglePersonaModal} togglePersonaModal={togglePersonaModal} />
            <QuestionModal
                tabVal={value}
                openQuestionModal={openQuestionModal}
                question={questionForModal}
                questionIndex={questionIndex}
                personas={personas}
                usersAnswers={answersForSelectedQuestion}
                handleCloseQuestionModal={handleCloseQUesitonModal}
                answersOptions={answersOptions}
            />
            <PersonasFilterModal
                openFilter={openFilterDrawer}
                setOpenFilter={setOpenFilterDrawer}
                filtersOptions={personasFilters}
                selectedFiltersOptions={selectedPersonasFilters}
                setSelectedFiltersOptions={setSelectedPersonasFilters}
            />
            <div>
                <Breadcrumbs separator=">" aria-label="breadcrumb" className={styles.breadcrumbs}>
                    <BrandLink className={styles.breadcrumb} to='/studies' color='primary' variant='body1'>Studies</BrandLink>
                    <BrandLink className={styles.breadcrumb} to={`/studies?id=${id}`} color='primary' variant='body1'>View Study</BrandLink>
                    <BrandLink className={clsx(styles.breadcrumb, styles.boldBreadcrumb)} to={`/conversation?id=${id}&panelId=${panelId}`} onClick={refresh} color='primary' variant='body1'>Start Conversation</BrandLink>
                </Breadcrumbs>
                <Typography className={styles.studyTitle} variant="h6" component='div' color='primary'>
                    {studyName}
                </Typography>
                <div className={styles.content}>
                    <div className={clsx(styles.leftSide, fixQuestionsListHeight ? styles.leftSideHeight : null, value === 1 ? styles.additionalQuestionsOverflow : null)}>
                        <AppBar className={styles.appBar} position="static">
                            <Tabs value={value} className={styles.tabs} TabIndicatorProps={{ style: { display: 'none' } }} onChange={handleChange} aria-label="simple tabs example">
                                <Tab label={<span className={value === 0 ? styles.activePanel : styles.inactivePanel}>Starting Questions</span>} {...a11yProps(0)} />
                                {!allStartingQuestionsAreAnswered ?
                                    <Tooltip
                                        classes={{ tooltip: styles.tooltip }}
                                        title='You need to ask the starting questions first'
                                    >
                                        <div>
                                            <Tab label={<span className={value === 1 ? styles.activePanel : styles.inactivePanel}>Additional Questions</span>} {...a11yProps(1)} disabled={!allStartingQuestionsAreAnswered} />
                                        </div>
                                    </Tooltip>
                                    :
                                    <Tab label={<span className={value === 1 ? styles.activePanel : styles.inactivePanel}>Additional Questions</span>} {...a11yProps(1)} disabled={!allStartingQuestionsAreAnswered} />
                                }
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0}>
                            <QuestionsList
                                questions={questions}
                                setQuestions={setQuestions}
                                studyIsNew={false}
                                questionsWithAnswers={questionsWithAnswers}
                                handleOpenQuestionModal={handleOpenQuestionModal}
                                inConversationPage={true}
                                tab={1}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            {additionalQuestions.length ?
                                <QuestionsList
                                    questionAnswersCount={questionAnswersCount}
                                    personas={personas}
                                    questions={additionalQuestions}
                                    setQuestions={setAdditionalQuestions}
                                    selectedAdditionalQuestions={selectedAdditionalQuestions}
                                    questionsWithAnswers={additionalQuestionsWithAnswers}
                                    setSelectedAdditionalQuestions={setSelectedAdditionalQuestions}
                                    onCustomSubmitForm={onClickConfirmAdditionalQuestions}
                                    onCustomDeleteQuestion={onClickDeleteQuestion}
                                    inConversationPage={true}
                                    tab={2}
                                    refreshSelectedQuestions={refreshSelectedQuestionsAndPersonas}
                                    handleOpenQuestionModal={handleOpenQuestionModal}
                                />
                                :
                                null
                            }
                            {showQuestionForm ?
                                <AddEditQuestionForm
                                    toggleQuestionForm={toggleQuestionForm}
                                    setQuestions={setAdditionalQuestions}
                                    questions={additionalQuestions}
                                    onCustomSubmitForm={onClickConfirmAdditionalQuestions}
                                />
                                :
                                <TertiaryBrandButton
                                    classes={{ root: styles.addNewQuestion }}
                                    onClick={toggleQuestionForm}
                                >
                                    Add New Question
                                    <span><AddCircleOutlineIcon /></span>
                                </TertiaryBrandButton>
                            }
                        </TabPanel>
                    </div>
                    <div className={clsx(styles.rightSide, fixPersonasListHeight ? styles.rightSideHeight : null)}>
                        <div className={styles.personasHeader}>
                            <div className={styles.personasHeaderTitle}>
                                <Typography variant='body1' component='div' color='primary' className={styles.personasListTitle}>{panelName}</Typography>
                                <Typography variant='body1' component='div' className={styles.personasNumber}>({personas.length}  Personas)</Typography>
                            </div>
                            <IconButton className={styles.iconButton} color='primary' onClick={(e) => setOpenFilterDrawer(e.currentTarget)}>
                                <SortRoundedIcon className={styles.sortIcon} color='primary' fontSize='large' />
                            </IconButton>
                        </div>
                        <PersonasList
                            personaAnswersCount={personaAnswersCount}
                            selectedAdditionalQuestions={selectedAdditionalQuestions}
                            answeredAdditionalQuestions={answeredAdditionalQuestions}
                            additionalQuestions={additionalQuestions}
                            personas={filteredPersonas}
                            setSelectedPersonas={setSelectedPersonas}
                            selectedPersonas={selectedPersonas}
                            currentStep={value}
                            setTogglePersonaModal={setTogglePersonaModal}
                            studyId={id}
                            pendingAdditionalQuestions={pendingAdditionalQuestions}
                            refreshSelectedPersonas={refreshSelectedQuestionsAndPersonas}
                        />
                    </div>
                </div>
            </div>
            {(pendingStartingQuestions && pendingStartingQuestions.length > 0 && value === 0)
                || (pendingAdditionalQuestions && pendingAdditionalQuestions.length && value === 1) ?
                < div className={styles.progressContainer}>
                    <div className={styles.leftSideEmptyContainer} />
                    <LinearDeterminate
                        label={value === 0 ? 'Replying to starting questions' : 'Replying to additional questions'}
                        value={value === 0 ?
                            ((answeredQuestions.length / (pendingStartingQuestions.length + answeredQuestions.length)) * 100)
                            :
                            ((answeredAdditionalQuestions.length / (pendingAdditionalQuestions.length + answeredAdditionalQuestions.length)) * 100)
                        }
                    />
                </div>
                :
                null
            }
            <div className={styles.actionsAndAlertContainer}>
                <Typography className={styles.note} variant='subtitle2' component='div' color='primary'>
                    Note: All starting questions need to be asked in order to generate a summary or ask additional questions.
                </Typography>
                <div className={styles.actions}>
                    <BrandButton classes={{ root: styles.exitButton }} variant='outlined' onClick={() => history.push('/studies')}>
                        Exit
                    </BrandButton>
                    <BrandButton onClick={(e) => setOpenDrawer(e.currentTarget)}>
                        Actions
                    </BrandButton>
                    <BrandButton
                        disabled={!(answeredQuestions.length > 0
                            && pendingAdditionalQuestions.length === 0
                            && pendingStartingQuestions.length === 0)}
                        onClick={() => history.push(`/conversation?id=${id}&panelId=${panelId}&summary=1`)}
                    >
                        View Answers and Summary
                    </BrandButton>
                </div>
            </div>
            <Popover
                anchorEl={openDrawer}
                keepMounted
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={Boolean(openDrawer)}
                onClose={() => setOpenDrawer(null)}
            >
                <List className={styles.list}>
                    <ListItem
                        className={styles.listItem}
                        button
                        onClick={() => onClickAskQuestions('starting')} disabled={disableAskAllStartingQuestions}
                    >
                        <ListItemIcon className={styles.askAllStartingQuestions}>
                            <CheckBoxOutlineBlankOutlinedIcon className={styles.icon} color='primary' />
                            <LiveHelpOutlinedIcon className={styles.icon} color='primary' />
                        </ListItemIcon>
                        <ListItemText primary={<Typography className={styles.actionText}>Ask all Starting questions</Typography>} />
                    </ListItem>
                    {!enableAskSelectedQuestions ?
                        <Tooltip
                            classes={{ tooltip: styles.tooltip }}
                            title='To ask selected questions you need to have the starting questions answered and processed.'
                        >
                            <div>
                                <ListItem
                                    className={styles.listItem}
                                    button
                                    onClick={() => onClickAskQuestions('additional')}
                                    disabled={!enableAskSelectedQuestions}
                                >
                                    <ListItemIcon>
                                        <LiveHelpOutlinedIcon className={styles.icon} color='primary' />
                                    </ListItemIcon>
                                    <ListItemText primary={<Typography className={styles.actionText}>Ask selected questions</Typography>} />
                                </ListItem>
                            </div>
                        </Tooltip>
                        :
                        <ListItem
                            className={styles.listItem}
                            button
                            onClick={() => onClickAskQuestions('additional')}
                            disabled={!enableAskSelectedQuestions}
                        >
                            <ListItemIcon>
                                <LiveHelpOutlinedIcon className={styles.icon} color='primary' />
                            </ListItemIcon>
                            <ListItemText primary='Ask selected questions' />
                        </ListItem>
                    }
                </List>
            </Popover>
        </div >
    );
}

export default SetupConversation;

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index ?
                children
                :
                null
            }
        </div>
    );
}

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
