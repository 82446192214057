/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { IconButton, Typography } from '@material-ui/core';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { useStoreContext } from '../../store/Store';
import { makeStyles } from '@material-ui/core/styles';
import { BrandTextArea } from './BrandInput';
import { BrandButton } from './BrandButton';
import { getPanelMessages, getStudyMessages, sendMessage, checkPersonaForPendingQuestions } from '../services/ChatService';
import { format } from 'date-fns'
import { BrandLoaderDots } from "./BrandLoader";
import clsx from 'clsx';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { BrandMenuItem, BrandSelect } from './BrandSelect';
import Tooltip from '@material-ui/core/Tooltip';

const formatDate = (date) => {
    return format(new Date(date), 'dd.MM.yy h.mm a');
};

const useStyles = makeStyles((theme) => ({
    closeBtn: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        marginBottom: '10px',
        width: '100%',
        '& .MuiIconButton-label': {
            flexDirection: 'row !important',
            alignItems: 'center',
        },
        '& .MuiIconButton-root': {
            padding: '5px',
            '& .MuiSvgIcon-root': {
                fontSize: '1.5rem',
                color: theme.palette.iconColor.error,
            },
            '& .MuiTypography-root': {
                fontSize: '1rem',
                color: theme.palette.iconColor.error,
                width: 'fit-content',
            }
        }
    },
    chatContainer: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gap: theme.spacing(1),
        overflowX: 'hidden',
        backgroundColor: theme.palette.background.dark
    },
    listContainer: {
        height: '600px',
        overflowY: 'auto',
        width: '600px',
        border: `1px solid ${theme.palette.border.success}`,
        borderRadius: '10px',
        marginTop: theme.spacing(2)
    },
    list: {
        width: '100%'
    },
    actions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        gap: theme.spacing(2),
        marginTop: theme.spacing(2),
        '& .MuiTypography-root': {
            width: 'fit-content',
            color: theme.palette.text.textDisabled
        },
        '& .MuiButton-root': {
            width: 'fit-content'
        }
    },
    humanMessage: {
        justifyContent: 'flex-end',
        '& .MuiListItemText-primary': {
            maxWidth: '80%',
            background: theme.palette.background.success,
            borderRadius: '10px',
            padding: theme.spacing(1),
            width: 'fit-content',
            color: theme.palette.text.default
        },
        '& .MuiListItemText-secondary': {
            color: theme.palette.text.successSecondary,
            borderRadius: '10px',
            padding: theme.spacing(1),
            width: 'fit-content',
        },
    },
    'from_user': {
        '& .MuiListItemText-primary': {
            background: theme.palette.background.success,
            color: theme.palette.text.default
        },
        '& .MuiListItemText-secondary': {
            color: theme.palette.text.successPrimary,
        }
    },
    'from_main_question_from_study': {
        '& .MuiListItemText-primary': {
            background: theme.palette.background.success,
            color: theme.palette.text.default
        },
        '& .MuiListItemText-secondary': {
            color: theme.palette.text.succesPrimary,
        }
    },
    'from_additional_question_from_study': {
        '& .MuiListItemText-primary': {
            background: theme.palette.background.disabled,
            color: theme.palette.text.default
        },
        '& .MuiListItemText-secondary': {
            color: theme.palette.text.disabled,
        }
    },
    'from_user_from_study': {
        '& .MuiListItemText-primary': {
            background: theme.palette.background.default,
            color: theme.palette.text.default
        },
        '& .MuiListItemText-secondary': {
            color: theme.palette.text.white,
        }
    },
    aiMessage: {
        '& .MuiListItemText-primary': {
            background: theme.palette.background.black,
            color: theme.palette.text.white,
            borderRadius: '10px',
            maxWidth: '80%',
            padding: theme.spacing(1),
        },
        '& .MuiListItemText-secondary': {
            color: theme.palette.text.white,
            maxWidth: '80%',
            width: 'fit-content',
            margin: theme.spacing(1, 2)
        }
    },
    humanMessageRoot: {
        display: 'flex',
        justifyContent: 'flex-end !important',
        '& .MuiListItemText-root': {
            maxWidth: '80%',
            width: 'fit-content',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
        }
    },
    orangeTypography: {
        color: theme.palette.text.successSecondary,
        fontSize: theme.palette.text.size.lg,
        fontWeight:  theme.palette.text.fontWeight.bold,
    },
    subChatTitle: {
        color: theme.palette.text.white,
        fontSize: theme.palette.text.size.lg,
        fontWeight:  theme.palette.text.fontWeight.bold,
    },
    message: {
        color: theme.palette.text.white
    },
    sendBtn: {
        borderRadius: '42px',
        backgroundColor: theme.palette.background.secondary,
        '&:hover': {
            backgroundColor: theme.palette.background.blue
        }
    },
    aiMessageRoot: {
        display: 'flex',
        justifyContent: 'flex-start !important',
        '& .MuiListItemText-root': {
            maxWidth: '80%',
            width: 'fit-content',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
        }
    },
    loader: {
        margin: theme.spacing(2),
        background: theme.palette.background.black,
        width: 'max-content',
        borderRadius: '26px',
        padding: theme.spacing(2)
    },
    normalQuestion: {
        color: theme.palette.text.white,
        display: 'flex'
    },
    mainQuestion: {
        color: theme.palette.text.successPrimary,
        '& .MuiTypography-root': {
            color: theme.palette.text.successPrimary,
        },
        display: 'flex'
    },
    additionalQuestions: {
        color: theme.palette.text.disabled,
        '& .MuiTypography-root': {
            color: theme.palette.text.disabled,
        },
        display: 'flex'
    },
    legend: {
        display: 'flex',
        width: '100%',
        gap: theme.spacing(6),
    },
    topSideActions: {
        display: 'flex',
        width: '100%',
        flexDirection: 'space-between',
        alignItems: 'center'
    },
    tooltip:{
        width: '200px',
        fontSize: theme.spacing(1.8),
        background: theme.palette.background.dark,
        border: `1px solid ${theme.palette.text.white}`
    },
    chattingText: {
        color: theme.palette.text.successPrimary,
        fontSize: theme.palette.text.size.xl
    },
    personaName: {
        color: theme.palette.text.white,
        fontSize: theme.palette.text.size.lg
    },
}));
   

const LIMIT = 20; // MUST BE ALWAYS AN EVEN NUMBER
const   CHAT_STYLE = {background: 'transparent', border: '2px solid #49C087', color: '#A0A2AF'};
const SELECT_STYLES = {border: 'none'};

const Chat = ({ persona }) => {
    const styles = useStyles();
    const [state, setState] = useStoreContext();
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const loading = useRef(false);
    const previousScrollTop = useRef(0);
    const [page, setPage] = useState(-1);
    const chatContainerRef = useRef(null);
    const [disableSend, setDisableSend] = useState(false);
    const totalMessages = useRef(0)
    const [refresh, setRefresh] = useState({});


    const [isWaitingForMessages, setIsWaitingForMessages] = useState(false);
    const [disableSendWhileWaiting, setDisableSendWhileWaiting] = useState(false);

    const fetchMessages = async (nextPage) => {
        try {
            const offset = nextPage * LIMIT;
            let response;
            if (persona) {
                if (persona.studyId) {
                    response = await getStudyMessages(persona.id, persona.studyId, offset, LIMIT)
                } else {
                    response = await getPanelMessages(persona.id, persona.panelId, offset, LIMIT)
                }
                return response.data;
            } else {
                return { messages: [], total: 0 };
            }
        } catch (e) {
            setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
            console.error(e?.response?.data || e);
            return { messages: [], total: 0 };
        }
    };

    const loadMessages = async (customPage, customPrevMessages) => {
        if (loading.current) return;
        setDisableSend(true)
        loading.current = true;
        let nextPage = (customPrevMessages ? customPage : page) + 1;
        setPage(prevPage => prevPage + 1);
        const { messages, total } = await fetchMessages(nextPage);
        totalMessages.current = total
        if (customPrevMessages) {
            setMessages(messages);
        } else {
            setMessages(prevMessages => [...messages, ...prevMessages]);
        }

        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight - previousScrollTop.current;
            previousScrollTop.current = chatContainerRef.current.scrollHeight;
        }
        loading.current = false;
        setDisableSend(false)
        return total;
    };

    const handleScroll = () => {
        const { scrollTop } = chatContainerRef.current;
        if (scrollTop === 0 && messages.length < totalMessages.current) {
            loadMessages();
        }
    };

    useEffect(() => {
        chatContainerRef.current.addEventListener('scroll', handleScroll);
        return () => {
            if (chatContainerRef.current) {
                chatContainerRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [messages]);

    const onSendMessage = async (e) => {
        let prevMessages = [...messages];
        let _message = message;

        if (disableSendWhileWaiting || disableSend) return;

        try {
            e.preventDefault();
            if (!message) return;
            setDisableSend(true)
            setMessage('');
            const newMessageObj = {
                id: `new-${messages.length + 1}`,
                message: _message,
                type: `from_user${persona.studyId ? '_from_study' : ''}`,
                createdOn: new Date()
            }
            prevMessages.push(newMessageObj);
            setMessages(prevMessages);
            await new Promise(r => setTimeout(r, 100));
            resetScrollTop();
            const _panelId = persona.studyId ? null : persona.panelId;
            const _studyId = persona.studyId ? persona.studyId : null;
            const reply = await sendMessage(persona.id, _panelId, _message, _studyId);
            prevMessages.push(reply.data[1]);
            setMessages([...prevMessages]);
            resetScrollTop();
            setDisableSend(false);
        } catch (e) {
            setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
            console.error(e?.response?.data || e);
            setDisableSend(false);
            resetScrollTop();
            if (prevMessages[prevMessages.length - 1].message === _message) {
                prevMessages.pop();
                setMessages([...prevMessages]);
            }
        }
    };

    const resetScrollTop = () => {
        if (chatContainerRef && chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
            previousScrollTop.current = chatContainerRef.current.scrollHeight;
        }
    };

    useEffect(async () => {
        if (persona?.studyId) {
            setIsWaitingForMessages(true);
            setDisableSendWhileWaiting(true);
            await loadMessages(-1, true);
            const { hasPendingMainQuestions, hasPendingAdditionalQuestions } = await checkPersonaForPendingQuestions(persona.id, persona.studyId);
            resetScrollTop();
            const hasMessages = totalMessages.current > 0;

            if (totalMessages.current === 0 && !hasPendingMainQuestions && !hasPendingAdditionalQuestions) {
                setIsWaitingForMessages(false);
                setDisableSendWhileWaiting(true);
                return;
            }

            /*
                disabled = !hasMessages && hasPendingMainQuestions
                disabled = !hasMessages && !hasPendingMainQuestions
                disabled = hasMessages && hasPendingAdditionalQuestions
                enabled = hasMessages && !hasPendingAdditionalQuestions && !hasPendingMainQuestions
            */
            const isEnabled = hasMessages && !hasPendingAdditionalQuestions && !hasPendingMainQuestions;
            setDisableSendWhileWaiting(!isEnabled);

            if (isEnabled) {
                setIsWaitingForMessages(false);
            } else {
                setTimeout(() => setRefresh({}), 10000);
                setIsWaitingForMessages(true);
            }
        } else {
            setIsWaitingForMessages(false);
            setDisableSendWhileWaiting(false);
            await loadMessages();
        }
    }, [refresh]);

    return (
        <form className={styles.chatContainer} onSubmit={onSendMessage}>
            <div className={styles.topSideActions}>
                {persona?.studyId ?
                    <div>
                        <BrandSelect
                            inputStyles={SELECT_STYLES}
                            value={persona.id}
                            onChange={async (newPersona) => {
                                const foundPersonaObj = state.personas.find(p => p.id === newPersona)
                                foundPersonaObj.studyId = persona.studyId;
                                setState(state => (state.persona = foundPersonaObj, { ...state }));
                                setRefresh({});
                            }}
                        >
                            {state?.personas.map(persona => (
                                <BrandMenuItem key={persona.id} value={persona.id}>
                                    {persona.Name}
                                </BrandMenuItem>
                            ))}
                        </BrandSelect>
                    </div>
                    :
                    <div />
                }
                <div className={styles.closeBtn}>
                    <IconButton color='primary' onClick={() => setState(state => (state.persona = null, { ...state }))}>
                        <Typography variant="body1" color='primary' component='div'>Close</Typography>
                        <ClearRoundedIcon color='primary' />
                    </IconButton>
                </div>
            </div>
            {persona?.studyId ?
                <>
                    <div className={styles.legend}>
                        <div className={styles.mainQuestion}>
                            <FiberManualRecordIcon />
                            <Typography variant="body1">
                                Main Questions
                            </Typography>
                        </div>
                        <div className={styles.normalQuestion}>
                            <FiberManualRecordIcon className={styles.normalQuestion} />
                            <Typography className={styles.normalQuestion} variant="body1">
                                Chat Questions
                            </Typography>
                        </div>
                    </div>
                    <div className={styles.additionalQuestions}>
                        <FiberManualRecordIcon />
                        <Typography variant="body1">
                            Additional Questions
                        </Typography>
                    </div>
                </>
                :
                <>
                    <Typography className={styles.chattingText} variant="body1" color='primary'>You're chatting with persona:</Typography>
                    <Typography className={styles.personaName} variant="body1">{persona?.Name}</Typography>
                </>
            }
            <div ref={chatContainerRef} className={styles.listContainer}>
                <List className={styles.list}>
                    {messages.map(message => {
                        const isUserMessage = message.type === 'from_user' || message.type === 'from_user_from_study';
                        const isStartingQuestion = message.type === 'from_main_question_from_study';
                        const isAdditionalQuestion = message.type === 'from_additional_question_from_study';
                        return (
                            <ListItem
                                key={message.id}
                                classes={{
                                    root: isUserMessage || isStartingQuestion || isAdditionalQuestion ?
                                        styles.humanMessageRoot : styles.aiMessageRoot
                                }}
                                className={clsx(isUserMessage || isStartingQuestion || isAdditionalQuestion ?
                                    styles.humanMessage : styles.aiMessage,
                                    styles[message.type])}
                            >
                                <ListItemText
                                    primary={message.message}
                                    secondary={formatDate(message?.createdOn || message?.createdon || new Date())}
                                />
                            </ListItem>
                        )
                    })}
                    {isWaitingForMessages || disableSend ?
                        <div className={styles.loader}><BrandLoaderDots size={20} /></div> : null}
                </List>
            </div>
            <BrandTextArea
                style={CHAT_STYLE}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder='Type your message here...'
                minRows={4}
                maxRows={4}
                limit={1000}
                onSubmitEnter={onSendMessage}
            />
            <div className={styles.actions}>
                <Typography classes={{root: styles.message}} variant="subtitle2">Message history will be saved.</Typography>
                {disableSendWhileWaiting ?
                    <Tooltip
                        classes={{ tooltip: styles.tooltip }}
                        title="Cannot send message while there are pending questions or starting questions have not been asked and processed yet"
                    >
                        <div>
                            <BrandButton
                                classes={{root: styles.sendBtn}}
                                type='submit'
                                disabled={disableSendWhileWaiting || disableSend}
                            >
                                Send
                            </BrandButton>
                        </div>
                    </Tooltip>
                    :
                    <BrandButton
                        type='submit'
                        disabled={disableSendWhileWaiting || disableSend}
                    >
                        Send
                    </BrandButton>
                }
            </div>
        </form >
    );
};

export default Chat;
