import { BrandModal } from "../BrandModal";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { List, Typography, ListItem, IconButton } from '@material-ui/core';
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
    modalList: {
        width: '447px',
        '& .MuiListItem-root:first-child': {
            borderBottom: `1px solid ${theme.palette.border.primary}`,
        },
        '& .MuiListItem-root:last-child': {
            borderTop: `1px solid ${theme.palette.border.primary}`,
        }
    },
    closeModal: { 
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(0.3),
        fontSize: '16px',
        color: theme.palette.text.error,
        '& .MuiSvgIcon-root': {
            marginTop: '3px',
            color: theme.palette.iconColor.errorPrimary,
        }
    },
    closeModalContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        borderBottom: `1px solid ${theme.palette.border.primary}`,
    },
    key: {
        color: theme.palette.text.primary,
        fontWeight: theme.palette.text.fontWeight.bold
    },
    listItem: {
        borderBottom: `1px solid ${theme.palette.border.primary}`,
    },
    firstTypography: {
        whiteSpace: 'nowrap',
        color: theme.palette.text.primary
    },
    description: {
        width: '100%',
        textWrap: 'wrap'
    },
    personInfoContainer: {
        borderBottom: `1px solid ${theme.palette.border.primary}`
    },
    personaName: {
        fontSize: theme.palette.text.size.xl,
        fontWeight: theme.palette.text.fontWeight.semiBold,
    }
}));

const PersonaModal = ({ setTogglePersonaModal, togglePersonaModal }) => {
    const styles = useStyles();
    const [personaAttributes, setPersonaAttributes] = useState([]);

    useEffect(() => {
        if (togglePersonaModal) {
            const attributes = Object.keys(togglePersonaModal).filter(key => key !== 'id' && key !== 'templateId' && key !== 'Name' && key !== 'Description' && key !== 'specifications');
            setPersonaAttributes(attributes);
        }
    }, [togglePersonaModal]);

    return (
        <BrandModal
            onClose={() => setTogglePersonaModal(null)}
            open={Boolean(togglePersonaModal)}
            hasPadding={false}
        >
            <div>
                <div className={styles.closeModalContainer}>
                    <IconButton onClick={() => setTogglePersonaModal(null)}>
                        <div className={styles.closeModal}>
                            <div>Close</div>
                            <CloseRoundedIcon />
                        </div>
                    </IconButton>
                </div>
                <List className={styles.modalList}>
                    <ListItem className={styles.listItem}>
                        <Typography className={styles.personaName} variant='body1' component='div'>
                            {togglePersonaModal?.Name}
                        </Typography>
                    </ListItem>
                    {
                         personaAttributes.map((attribute, index) => {
                            return (
                              <ListItem className={styles.personInfoContainer} key={index}>
                                <Typography variant='body1' component='div' color='primary' className={styles.firstTypography}>
                                  {attribute}&nbsp;&nbsp;
                                </Typography>
                                <Typography variant='body1' component='div'>
                                  {togglePersonaModal && togglePersonaModal[attribute]}
                                </Typography>
                              </ListItem>
                            );
                          })
                    }
                    <ListItem>
                        <Typography className={styles.description} variant='body1' component='div'>
                            {togglePersonaModal?.Description}
                        </Typography>
                    </ListItem>
                </List>
            </div>
        </BrandModal >
    );
}

export default PersonaModal;