/* eslint-disable no-sequences */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Breadcrumbs, Checkbox, FormControlLabel, IconButton, List, ListItem, ListItemText, Popover, Typography } from '@material-ui/core';
import { BrandLink } from '../CoreComponents/BrandLink';
import clsx from 'clsx';
import { useQuery } from '../utils/RouteUtils';
import { BrandButton, TertiaryBrandButton } from '../CoreComponents/BrandButton';
import { BrandMenuItem, BrandSelect } from '../CoreComponents/BrandSelect';
import { onGetPersonas } from '../services/PanelService';
import { useStoreContext } from '../../store/Store';
import { useHistory } from 'react-router-dom';
import { getAnswers, onGetAdditionalQuestions, onGetStudies } from '../services/StudyService';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ChatRoundedIcon from '@material-ui/icons/ChatRounded';
import SortRoundedIcon from '@material-ui/icons/SortRounded';
import PersonasFilterModal from '../CoreComponents/StudiesComponents/PersonasFilterModal';
import PersonaModal from '../CoreComponents/StudiesComponents/PersonaModal';
import AnswersAndSummaryQuesitonCard from '../CoreComponents/AnswersAndSummaryComponents/AnswersAndSummaryQuesitonCard';
import AnswersCard from '../CoreComponents/AnswersAndSummaryComponents/AnswersCard';
import SummaryCard from '../CoreComponents/AnswersAndSummaryComponents/SummaryCard';

const useStyles = makeStyles((theme) => ({
  answersContainer: {
    width: '100%',
    height: '100%',
  },
  breadcrumbs: {
    color: theme.palette.text.primary,
    fontSize: theme.spacing(1.6),
    '& .MuiTypography-root': {
      fontSize: theme.spacing(1.6),
    }
  },
  breadcrumb: {
    color: theme.palette.text.primary,
  },
  boldBreadcrumb: {
    fontWeight: theme.palette.text.fontWeight.bold
  },
  pageTitle: {
    fontSize: theme.palette.text.size.xxl,
    fontWeight: theme.palette.text.fontWeight.bold,
    color: theme.palette.text.primary,
    marginTop: theme.spacing(2)
  },
  pageContentContainer: {
    width: '100%',
    height: 'max-content',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingBottom: theme.spacing(1),
  },
  card: {
    backgroundColor: theme.palette.background.white,
    boxShadow: theme.palette.shadow.large,
    borderRadius: '10px',
    height: '721px',
    width: '531px',
    overflowY: 'auto',
    overflowX: 'hidden',
    margin: theme.spacing(1, 0),
    '&::-webkit-scrollbar': {
      width: '8px', // Width of the scrollbar
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.background.secondary, // Color of the thumb
      borderRadius: '4px', // Border radius of the thumb
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent', // Color of the track
      borderRadius: '4px', // Border radius of the track
    },
  },
  sectionTitle: {
    fontWeight: theme.palette.text.fontWeight.semiBold,
    margin: theme.spacing(2, 0)
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    padding: theme.spacing(2, 0)
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'max-content',
    gap: theme.spacing(2),
    marginTop: theme.spacing(4)
  },
  exitButton: {
    color: theme.palette.text.primary,
    border: `2px solid ${theme.palette.border.secondary}`,
    background: 'transparent',
    borderRadius: '42px',
    padding: theme.spacing(1.3, 4),
    '&:hover': {
      border: `2px solid ${theme.palette.border.secondary}`,
    },
  },
  cardHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-start',
    padding: theme.spacing(2, 0),
    borderBottom: `2px solid ${theme.palette.border.primary}`,
  },
  personasCardHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 0),
    borderBottom: `2px solid ${theme.palette.border.primary}`,
  },
  checkBoxStyle: {
    color: theme.palette.text.primary,
  },
  formControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(10),
    '& .MuiCheckbox-colorPrimary': {
      color: theme.palette.text.primary,
    },
  },
  personaCheckBox: {
    '& .MuiCheckbox-colorPrimary': {
      color: theme.palette.text.primary,
    },
  },
  listItem: {
    padding: theme.spacing(2),
    borderBottom: `2px solid ${theme.palette.border.primary}`,
    whiteSpace: 'nowrap'
  },
  popover: {
    background: theme.palette.background.white,
    padding: 0,
    margin: 0
  },
  personaCard: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `2px solid ${theme.palette.border.primary}`
  },
  iconColor: {
    cursor: 'pointer',
    color: theme.palette.iconColor.success,
    '&:hover': {
      color: theme.palette.iconColor.successSecondary,
    }
  },
  chatButton: {
    '&:hover': {
      '& .MuiButtonBase-root:first-child': {
        color: theme.palette.text.successSecondary,
      },
    },
    '& .MuiButtonBase-root:first-child': {
      color: theme.palette.text.successPrimary,
    }
  },
  secondaryActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1),
    '& .MuiButton-root:nth-child(1)': {
      '& .MuiButton-label': {
        color: theme.palette.text.successPrimary,
      },
      '&:hover': {
        color: theme.palette.text.successSecondary,
      }
    }
  },
  chat: {
    margin: theme.spacing(0, 1),
  },
  cardTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  answerOptions: {
    borderBottom: `2px solid ${theme.palette.border.primary}`
  },
  answerOptionColor: {
    color: theme.palette.text.primary,
  },
  answersOptionsContainer: {
    marginLeft: theme.spacing(5),
  },
  questionsSeparator: {
    borderBottom: `2px solid ${theme.palette.border.primary}`,
    fontSize: theme.palette.text.size.lg,
    fontWeight: theme.palette.text.fontWeight.semiBold,
    color: theme.palette.text.primary,
    paddingLeft: theme.spacing(3),
  },
  cardContent: {
    width: '100%',
    height: 'max-content',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    flexDirection: 'column',
    padding: theme.spacing(2, 0)
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    aligItems: 'flex-start',
    width: '90%',
    height: 'max-content'
  },
  answerAndSummaryText: {
    color: theme.palette.text.successPrimary,
    fontSize: theme.palette.text.size.lg,
    fontWeight: theme.palette.text.fontWeight.bold
  },
  selectedPersonas: {
    color: theme.palette.text.successPrimary,
    marginRight: theme.spacing(3)
  }
}));

const SELECT_OPTIONS = [
  {
    id: 1,
    value: 'Open - Ended Questions'
  },
  {
    id: 2,
    value: 'Close - Ended Questions'
  },
]

const SELECT_STYLES = { background: '#DCDFE8', border: 'none', color: '#575F73' };

const AnswersAndSummary = () => {
  const styles = useStyles();

  const [selectValue, setSelectValue] = useState(1);
  const [openPopOver, setOpenPopOver] = useState(null);
  const [studyQuestions, setStudyQuestions] = useState([]);
  const [additionalQuestions, setAdditionalQuestions] = useState([]);
  const [personas, setPersonas] = useState([]);
  const [personasFilters, setPersonaFilters] = useState([]);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(null);
  const [selectedPersonasFilters, setPersonasFilters] = useState({});
  const [filteredPersonas, setFilteredPersonas] = useState([]);
  const [togglePersonaModal, setTogglePersonaModal] = useState(null);
  const [filteredStudyQuestions, setFilteredStudyQuestions] = useState([]);
  const [filteredAdditionalQuestions, setFilteredAdditionalQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState({});
  const [personaIds, setPersonaIds] = useState([]);
  const [additionalQuestionsAnswers, setAdditionalQuestionsAnswers] = useState([]);
  const [studyQuestionsAnswers, setStudyQuestionsAnswers] = useState([]);
  const [filteredPersonasForSelectedQuestions, setFilteredPersonasForSelectedQuestions] = useState({});
  const [selectedPersonas, setSelectedPersonas] = useState({});
  const [questionsAndAnswersForAnswersCard, setQuestionsAndAnswersForAnswersCard] = useState({});
  const [areAllPersonasSelected, setAreAllPersonasSelected] = useState(false);
  const [areAllQUestionsSelected, setAreAllQuestionsSelected] = useState(false);
  const [areAllOptionsSelect, setAreAllOptionsSelect] = useState(false);
  const [summaryData, setSummaryData] = useState(null);
  const [isViewAnswersButtonClicked, setIsViewAnswersButtonClicked] = useState(false);
  const [isLoadSummaryButtonClicked, setIsLoadSummaryButtonClicked] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});

  const [, setState] = useStoreContext();
  const history = useHistory();
  const { id, panelId, summary } = useQuery();

  useEffect(() => {
    const personasObject = {};
    personas.map(persona => {
      personasObject[persona.id] = checkIfPersonasAnswer(persona);
    })

    setFilteredPersonasForSelectedQuestions(personasObject);
    setIsLoadSummaryButtonClicked(false);
    setIsViewAnswersButtonClicked(false);
    setSelectedPersonas({});
    setAreAllPersonasSelected(false);
  }, [selectedQuestions, selectedOptions]);

  useEffect(() => {
    setIsLoadSummaryButtonClicked(false);
    setIsViewAnswersButtonClicked(false);
  }, [selectedPersonas]);

  const checkIfPersonasAnswer = (persona) => {
    const isInAdditionalAnswers = additionalQuestionsAnswers.some(answer => Object.keys(selectedQuestions).includes(answer.question_id.toString()) && answer.persona_id === persona.id);
    const isInStudyAnswers = studyQuestionsAnswers.some(answer => Object.keys(selectedQuestions).includes(answer.question_id.toString()) && answer.persona_id === persona.id);
    if (Object.keys(selectedOptions).length > 0) {
      const isInOptionsForStudyQuesitons = studyQuestionsAnswers.some(answer => Object.keys(selectedOptions).includes(answer.answer_option_id?.toString()) && answer.persona_id === persona.id);
      const isInOptionsForAdditionalQuesitons = additionalQuestionsAnswers.some(answer => Object.keys(selectedOptions).includes(answer.answer_option_id?.toString()) && answer.persona_id === persona.id);
      if (isInStudyAnswers && isInOptionsForStudyQuesitons) return true;
      if (isInAdditionalAnswers && isInOptionsForAdditionalQuesitons) return true;
      return false;
    }

    if (isInAdditionalAnswers || isInStudyAnswers) return true;
    return false;
  };

  const fetchStartingQuestionsAnswers = async () => {
    if (personaIds.length === 0) return;
    if (studyQuestions.length === 0) return;

    try {
      const res = await getAnswers(personaIds, id, 'main');
      const respAnswers = res.answers;
      setStudyQuestionsAnswers(respAnswers);

      if (res.isPending) {
        setTimeout(() => fetchStartingQuestionsAnswers(), 10000);
        return;
      }

      if (!res.isPending) {
        return;
      }

      setTimeout(() => fetchStartingQuestionsAnswers(), 10000);
    } catch (e) {
      setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
      console.error(e?.response?.data || e);
    }
  };

  const fetchAdditionalQuestionsAnswers = async () => {
    if (personaIds.length === 0) return;
    if (additionalQuestions.length === 0) return;

    try {
      const res = await getAnswers(personaIds, id, 'additional');
      setAdditionalQuestionsAnswers(res.answers);
      const isPending = res.isPending;

      if (!isPending) {
        return;
      }

      setTimeout(() => fetchAdditionalQuestionsAnswers(), 10000);
    } catch (e) {
      setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
      console.error(e?.response?.data || e);
    }
  };

  useEffect(() => fetchStartingQuestionsAnswers(), [personaIds, studyQuestions]);
  useEffect(() => fetchAdditionalQuestionsAnswers(), [personaIds, additionalQuestions]);


  const filterPersonas = (allPersonas, filters) => {
    return allPersonas.filter(persona => {
      return Object.entries(filters).every(([attributeId, attributeFilters]) => {
        return attributeFilters.length === 0 || attributeFilters.some(filter => {
          return persona.specifications.some(specification => {
            return specification.attributeId === filter.attributeId && specification.id === filter.id;
          });
        });
      });
    });
  }

  useEffect(() => {
    if (Object.keys(selectedPersonasFilters).length > 0) {
      const filteredPersonas = filterPersonas(personas, selectedPersonasFilters);
      setFilteredPersonas(filteredPersonas);
    } else {
      setFilteredPersonas(personas);
    }
  }, [selectedPersonasFilters]);

  const prepare = async () => {
    try {
      setState(state => (state.isLoading = true, { ...state }));
      const studyData = await onGetStudies(id, null, null, null, null, true);
      if (!studyData.length) {
        history.push('/studies');
      }

      if (studyData[0].panel_id && Number(studyData[0].panel_id) !== Number(panelId)) {
        history.push('/studies');
      }

      studyData[0].questions.map(question => {
        if (!question.answeroptions) {
          return;
        }
        question.answerOptions = question.answeroptions;
        question.answerOptions = question.answerOptions.filter(option => option !== null);
        delete question.answeroptions;
      });
      const personas = await onGetPersonas(panelId);
      setState(state => (state.personas = personas, { ...state }));

      if (id && panelId) {
        const additionalQuestionsRes = await onGetAdditionalQuestions(id, panelId, true);
        for (const question of additionalQuestionsRes) {
          if (!question.answeroptions) {
            return;
          }
          question.answerOptions = question.answeroptions;
          question.answerOptions = question.answerOptions.filter(option => option !== null);
          delete question.answeroptions;
        }
        setAdditionalQuestions(additionalQuestionsRes);
        const filteredAdditionalQuesiotns = filterQuestions(additionalQuestionsRes, 1);
        setFilteredAdditionalQuestions(filteredAdditionalQuesiotns);
      }

      setPersonas(personas);
      setFilteredPersonas(personas);
      setStudyQuestions(studyData[0]?.questions);
      setPersonaIds(personas.map(persona => persona.id));
      const filteredStudyQuesiotns = filterQuestions(studyData[0]?.questions, 1);
      setFilteredStudyQuestions(filteredStudyQuesiotns);
    } catch (e) {
      setState(state => (state.toggleStatusModal = { message: e?.response?.data?.msg || e.message, isSuccessModal: false, title: 'Warning' }, { ...state }));
      console.error(e?.response?.data || e);
    } finally {
      setState(state => (state.isLoading = false, { ...state }));
    }
  };

  useEffect(() => {
    prepare();
  }, [])

  const refresh = (e) => e.preventDefault();

  const handleChangeSelectValue = (newValue) => {
    setSelectValue(newValue);
  }

  const filterQuestions = (questions, selectedValue) => {
    let filteredQuestions = [];
    if (selectedValue === 1) {
      filteredQuestions = questions.filter(question => !question?.answerOptions || question?.answerOptions.length === 0 || question?.answerOptions[0]?.id === null);
    } else {
      filteredQuestions = questions.filter(question => question?.answerOptions && question?.answerOptions.length > 0 && question?.answerOptions[0]?.id !== null);
    }

    return filteredQuestions;
  };

  useEffect(() => {
    const filteredStudyQuestions = filterQuestions(studyQuestions, selectValue);
    const filteredAdditionalQuesiotns = filterQuestions(additionalQuestions, selectValue);
    setFilteredStudyQuestions(filteredStudyQuestions);
    setFilteredAdditionalQuestions(filteredAdditionalQuesiotns);
    setSelectedOptions({});
    setSelectedQuestions({});
    setFilteredPersonasForSelectedQuestions({});
    setSelectedPersonas({});
    setAreAllQuestionsSelected(false);
    setAreAllPersonasSelected(false);
    setAreAllOptionsSelect(false);
    setIsViewAnswersButtonClicked(false);
  }, [selectValue]);

  useEffect(() => {
    if (personas && personas.length > 0) {
      const filters = generateFilters(personas);
      setPersonaFilters(filters);
    }
  }, [personas]);

  const generateFilters = (personas) => {
    const filters = personas.reduce((acc, persona) => {
      persona.specifications.map(spec => {
        if (acc[spec.attributeName]) {
          if (!acc[spec.attributeName].find(f => f.option === spec.option)) {
            acc[spec.attributeName].push(spec)
          }
        } else {
          acc[spec.attributeName] = [spec]
        }
      })
      return acc
    }, {})
    return filters
  }

  const markAllQuestionsAndAnswers = (arr, questionsCopy, answersCopy, isAdditional) => {

    arr.map(question => {
      if (areAllQUestionsSelected) {
        questionsCopy[isAdditional ? question.id : question.questionid] = question;
        question?.answerOptions?.map(option => {
          answersCopy[option.id] = option;
        });
      }
    });

  }

  useEffect(() => {
    if (areAllQUestionsSelected) {
      let selectedOptionsCopy = { ...selectedOptions };
      const selectedQuestionsCopy = { ...selectedQuestions };
      markAllQuestionsAndAnswers(filteredAdditionalQuestions, selectedQuestionsCopy, selectedOptionsCopy, true);
      markAllQuestionsAndAnswers(filteredStudyQuestions, selectedQuestionsCopy, selectedOptionsCopy, false);

      if (selectValue === 2) {
        setSelectedOptions(selectedOptionsCopy);
        setAreAllOptionsSelect(true);
      } else {
        setSelectedOptions({});
      }
      setSelectedQuestions(selectedQuestionsCopy);

    }
  }, [areAllQUestionsSelected]);

  useEffect(() => {
    if (areAllPersonasSelected) {
      const selectedPersonasCopy = { ...selectedPersonas };
      if (filteredPersonas.length > 0) {
        filteredPersonas.map(persona => {
          if (Object.keys(filteredPersonasForSelectedQuestions).length > 0) {
            const isWithAnswer = Object.values(filteredPersonasForSelectedQuestions).find(value => value);

            if (isWithAnswer && filteredPersonasForSelectedQuestions[persona.id.toString()]) {
              selectedPersonasCopy[persona.id] = persona;
            }
            if (!isWithAnswer && filteredPersonasForSelectedQuestions[persona.id.toString()] !== undefined) {
              selectedPersonasCopy[persona.id] = persona;
            }
          } else {
            selectedPersonasCopy[persona.id] = persona;
          }
        });
      }
      setSelectedPersonas(selectedPersonasCopy);
    }
  }, [areAllPersonasSelected])

  const handleGetDataForSummary = () => {
    let answersData = [];
    const personasLength = Object.keys(selectedPersonas).length;
    Object.keys(selectedQuestions).map(selectedQuestion => {
      let arr = []
      selectedQuestions[selectedQuestion].answerOptions.map(answer => {
        const array = selectedQuestions[selectedQuestion].type === 'main' ? studyQuestionsAnswers : additionalQuestionsAnswers;
        const findOptionsAnswers = array.filter(q => { return q.answer_option_id === answer.id && Object.keys(selectedPersonas).includes(q.persona_id.toString()) }).length;
        arr.push({ answerId: answer.id, answerName: answer.answer_option, answeredPersonas: findOptionsAnswers });
      });
      answersData.push({ answers: [...arr], personas: personasLength, name: selectedQuestions[selectedQuestion].question, quesitonId: selectedQuestion });
      arr = [];
    })

    return { answersData };
  };

  const handleViewSummary = () => {
    if (selectValue === 2) {
      const { answersData } = handleGetDataForSummary();
      setSummaryData([...answersData]);
    }
    setIsLoadSummaryButtonClicked(true);
    setIsViewAnswersButtonClicked(false);
    setOpenPopOver(null);
  };

  const getQuestionsAndAnswers = (arr, questionsAndAnswers) => {
    Object.keys(selectedQuestions).map(key => {
      const answers = arr.filter(answer => { return answer.question_id.toString() === key });
      if (answers.length > 0) {
        questionsAndAnswers.push({ questionId: key, answers: answers, name: selectedQuestions[key].question, questionOptions: selectValue === 2 ? selectedQuestions[key].answerOptions : null });
      }
    });
  };

  const handleOpenVIew = () => {
    if (Object.keys(selectedQuestions).length > 0) {
      const questionsAndAnswers = [];
      getQuestionsAndAnswers(studyQuestionsAnswers, questionsAndAnswers);
      getQuestionsAndAnswers(additionalQuestionsAnswers, questionsAndAnswers);

      setQuestionsAndAnswersForAnswersCard([...questionsAndAnswers]);
    } else {
      setQuestionsAndAnswersForAnswersCard([]);
    }
    setIsViewAnswersButtonClicked(true);
    setIsLoadSummaryButtonClicked(false);
    setOpenPopOver(null);
  }

  return (
    <div className={styles.answersContainer}>
      <Breadcrumbs separator=">" aria-label="breadcrumb" className={styles.breadcrumbs}>
        <BrandLink className={styles.breadcrumb} to='/studies' color='primary' variant='body1'>Studies</BrandLink>
        <BrandLink className={styles.breadcrumb} to={`/studies?id=${id}`} color='primary' variant='body1'>View Study</BrandLink>
        <BrandLink className={styles.breadcrumb} to={`/conversation?id=${id}`} color='primary' variant='body1'>Start Conversation</BrandLink>
        <BrandLink className={clsx(styles.breadcrumb, styles.boldBreadcrumb)} to={`conversation?id=${id}&panelId=${panelId}&summary=${summary}`} onClick={refresh} color='primary' variant='body1'>View Answers and Summary</BrandLink>
      </Breadcrumbs>
      <Typography className={styles.pageTitle}>Answers and Summary</Typography>
      <div className={styles.pageContentContainer}>
        <PersonaModal setTogglePersonaModal={setTogglePersonaModal} togglePersonaModal={togglePersonaModal} />
        <PersonasFilterModal
          openFilter={openFilterDrawer}
          setOpenFilter={setOpenFilterDrawer}
          filtersOptions={personasFilters}
          selectedFiltersOptions={selectedPersonasFilters}
          setSelectedFiltersOptions={setPersonasFilters}
        />
        <div>
          <Typography className={styles.sectionTitle}>Select the questions and answers you want to see:</Typography>
          <div className={styles.card}>
            <div className={styles.cardHeader}>
              <FormControlLabel
                className={styles.formControl}
                control={<Checkbox
                  checked={areAllQUestionsSelected}
                  onChange={(event) => {
                    setAreAllQuestionsSelected(event.target.checked);
                    if (!event.target.checked) {
                      setSelectedQuestions({});
                      setSelectedOptions({});
                    }
                  }}
                  className={styles.checkBoxStyle}
                  color="primary"
                  name='select all'
                />}
                label='Select All'
              />
              <BrandSelect inputStyles={SELECT_STYLES} value={selectValue} select onChange={handleChangeSelectValue}>
                {
                  SELECT_OPTIONS.map(option => {
                    return (
                      <BrandMenuItem key={option.id} value={option.id}>{option.value}</BrandMenuItem>
                    )
                  })
                }
              </BrandSelect>
            </div>
            {
              filteredStudyQuestions.length > 0 &&
              filteredStudyQuestions.map((question, index) => {
                return (
                  <AnswersAndSummaryQuesitonCard
                    question={question}
                    index={index}
                    key={question.id}
                    selectedQuestions={selectedQuestions}
                    setSelectedQuestions={setSelectedQuestions}
                    setSelectedOptions={setSelectedOptions}
                    selectedOptions={selectedOptions}
                    additionalQuestions={filteredAdditionalQuestions}
                    studyQuestions={filteredStudyQuestions}
                    setAllSelected={setAreAllQuestionsSelected}
                    isOpenEnded={selectValue === 1 ? true : false}
                    isSelectAllQuestions={areAllQUestionsSelected}
                    setSelectedPersonas={setSelectedPersonas}
                  />
                )
              })
            }
            <ListItem className={styles.questionsSeparator}>Additional Questions</ListItem>
            {
              filteredAdditionalQuestions.length > 0 &&
              filteredAdditionalQuestions.map((question, index) => {
                return (
                  <AnswersAndSummaryQuesitonCard
                    question={question}
                    index={index}
                    selectedQuestions={selectedQuestions}
                    setSelectedQuestions={setSelectedQuestions}
                    setSelectedOptions={setSelectedOptions}
                    selectedOptions={selectedOptions}
                    key={question.quesitonid}
                    additionalQuestions={filteredAdditionalQuestions}
                    studyQuestions={filteredStudyQuestions}
                    setAllSelected={setAreAllQuestionsSelected}
                    isOpenEnded={selectValue === 1 ? true : false}
                    isAllOptionsSelect={areAllOptionsSelect}
                    setIsAllOptionsSelect={setAreAllOptionsSelect}
                    isSelectAllQuestions={areAllQUestionsSelected}
                    setSelectedPersonas={setSelectedPersonas}
                  />
                )
              })
            }
          </div>
        </div>
        <div>
          <div className={styles.cardTitleContainer}>
            <Typography className={styles.sectionTitle}>Persona(s) with answers to selected question(s):</Typography>
            <IconButton className={styles.iconButton} onClick={(e) => setOpenFilterDrawer(e.currentTarget)}>
              <SortRoundedIcon className={styles.sortIcon} fontSize='large' />
            </IconButton>
          </div>
          <div className={styles.card}>
            <div className={styles.personasCardHeader}>
              <FormControlLabel
                className={styles.formControl}
                control={<Checkbox
                  checked={areAllPersonasSelected}
                  onChange={(event) => {
                    setAreAllPersonasSelected(event.target.checked);
                    if (!event.target.checked) {
                      setSelectedPersonas({});
                    }
                  }}
                  className={styles.checkBoxStyle}
                  color="primary"
                  name='select all available'
                />}
                label='Select all available'
              />
              {
                Object.keys(selectedPersonas).length > 0 &&
                <Typography className={styles.selectedPersonas}>{Object.keys(selectedPersonas).length} Selected</Typography>
              }
            </div>
            <List>
              {
                filteredPersonas.length > 0 &&
                filteredPersonas.map(persona => {
                  if (Object.keys(selectedQuestions).length > 0) {
                    if (filteredPersonasForSelectedQuestions[persona.id]) {
                      return (
                        <ListItem key={persona.id} className={styles.personaCard}>
                          <FormControlLabel
                            className={styles.personaCheckBox}
                            control={<Checkbox
                              className={styles.checkBoxStyle}
                              color="primary"
                              checked={Boolean(selectedPersonas[persona.id])}
                              onChange={(event) => {
                                const selectedPersonasCopy = { ...selectedPersonas };
                                if (event.target.checked) {
                                  selectedPersonasCopy[persona.id] = persona;
                                } else {
                                  delete selectedPersonasCopy[persona.id];
                                }
                                setAreAllPersonasSelected(Object.keys(selectedPersonasCopy).length === personas.length);
                                setSelectedPersonas(selectedPersonasCopy);
                                setIsLoadSummaryButtonClicked(false);
                                setIsViewAnswersButtonClicked(false);
                              }}
                            />}
                          />
                          <ListItemText primary={persona.Name} />
                          <VisibilityOutlinedIcon className={styles.iconColor} color='primary' onClick={() => setTogglePersonaModal(persona)} />
                          <div className={styles.secondaryActions}>
                            <TertiaryBrandButton
                              className={styles.chatButton}
                              onClick={() => setState(state => (state.persona = {
                                ...persona,
                                studyId: id
                              }, { ...state }))}
                            >
                              <div className={styles.chat}>Chat</div>
                              <ChatRoundedIcon />
                            </TertiaryBrandButton>
                          </div>
                        </ListItem>
                      )
                    } else {

                      return null;
                    }

                  } else {

                    return (
                      <ListItem key={persona.id} className={styles.personaCard}>
                        <FormControlLabel
                          className={styles.personaCheckBox}
                          control={<Checkbox
                            className={styles.checkBoxStyle}
                            checked={Boolean(selectedPersonas[persona.id])}
                            onChange={(event) => {
                              const selectedPersonasCopy = { ...selectedPersonas };
                              if (event.target.checked) {
                                selectedPersonasCopy[persona.id] = persona;
                              } else {
                                delete selectedPersonasCopy[persona.id];
                              }
                              setSelectedPersonas(selectedPersonasCopy);
                              setAreAllPersonasSelected(Object.keys(selectedPersonasCopy).length === personas.length);
                            }}
                            color="primary"
                            name='select all available'
                          />}
                        />
                        <ListItemText primary={persona.Name} />
                        <VisibilityOutlinedIcon className={styles.iconColor} color='primary' onClick={() => setTogglePersonaModal(persona)} />
                        <div className={styles.secondaryActions}>
                          <TertiaryBrandButton
                            className={styles.chatButton}
                            onClick={() => setState(state => (state.persona = {
                              ...persona,
                              studyId: id
                            }, { ...state }))}
                          >
                            <div className={styles.chat}>Chat</div>
                            <ChatRoundedIcon />
                          </TertiaryBrandButton>
                        </div>
                      </ListItem>
                    )
                  }
                })
              }
            </List>
          </div>
        </div>
        <div>
          <div className={styles.card}>
            <div className={styles.cardContent}>
              <div className={styles.content}>
                {
                  isLoadSummaryButtonClicked &&
                  <Typography className={styles.answerAndSummaryText}>Summary</Typography>
                }
                {
                  isViewAnswersButtonClicked &&
                  <Typography className={styles.answerAndSummaryText}>Answers</Typography>
                }
                {
                  questionsAndAnswersForAnswersCard.length > 0 && Object.keys(selectedPersonas).length > 0 && isViewAnswersButtonClicked &&
                  questionsAndAnswersForAnswersCard.map((quesitionAndAnswer, index) => {
                    return (
                      <AnswersCard
                        key={quesitionAndAnswer.id}
                        questionName={quesitionAndAnswer.name}
                        quesitonId={quesitionAndAnswer.id}
                        answers={quesitionAndAnswer.answers}
                        index={index}
                        selectedPersonas={selectedPersonas}
                        setTogglePersonaModal={setTogglePersonaModal}
                        isOpenEnded={selectValue === 1 ? true : false}
                        questionsAnswersOptions={quesitionAndAnswer.questionOptions}
                      />
                    )
                  })
                }
                {
                  isLoadSummaryButtonClicked &&
                  <SummaryCard data={summaryData} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.actionsContainer}>
        <div className={styles.actions}>
          <BrandButton classes={{ root: styles.exitButton }} variant='outlined' onClick={() => history.push(`/conversation?id=${id}`)}>
            Back
          </BrandButton>
          <BrandButton onClick={(e) => setOpenPopOver(e.currentTarget)}>
            Actions
          </BrandButton>
          <BrandButton onClick={() => history.push('/studies')}>
            Finish
          </BrandButton>
        </div>
        <Popover
          PaperProps={{ classes: { root: styles.popover } }}
          anchorEl={openPopOver}
          keepMounted
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={Boolean(openPopOver)}
          onClose={() => setOpenPopOver(null)}
        >
          <List>
            <ListItem
              className={styles.listItem}
              button
              disabled={Object.keys(selectedQuestions).length === 0 || Object.keys(selectedPersonas).length === 0}
              onClick={handleOpenVIew}


            >
              <ListItemText
                primary={<Typography>View Answers for selected</Typography>} />
            </ListItem>
            <ListItem
              disabled={Object.keys(selectedQuestions).length === 0 || Object.keys(selectedPersonas).length === 0 || selectValue !== 2}
              className={styles.listItem}
              onClick={handleViewSummary}
              button
            >
              <ListItemText primary={<Typography>Create Summary for selected</Typography>} />
            </ListItem>
          </List>
        </Popover>
      </div>
    </div>
  )
}

export default AnswersAndSummary