import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import ListIcon from '@material-ui/icons/List';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    iconSize: {
        width: '36px',
        height: '36px',
        color: theme.palette.iconColor.secondary
    }
}));

export const IconSwitch = ({ label }) => {
    const styles = useStyles();
    switch (label) {
        case 'home':
            return <HomeIcon color='primary' className={styles.iconSize} />;
        case 'panels':
            return <PersonIcon color='primary' className={styles.iconSize} />;
        case 'studies':
            return <ListIcon color='primary' className={styles.iconSize} />;
        case 'users':
            return <PeopleAltOutlinedIcon color='primary' className={styles.iconSize} />;
        default:
            return <HomeIcon color='primary' className={styles.iconSize} />;
    }
}