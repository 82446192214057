export function debounce(onInput, func, wait = 300) {
  let timeout;
  function debounced(...args) {
    const later = () => {
      func.apply(this, args);
    };

    clearTimeout(timeout);
    onInput.apply(this, args);
    timeout = setTimeout(later, wait);
  }

  debounced.clear = () => {
    clearTimeout(timeout);
  };

  return debounced;
}

export function getToken(fromGetMe) {
  const authorizationToken = localStorage.getItem('token');
  if (!authorizationToken && !fromGetMe) {
    throw new Error('No token found in localStorage');
  }
  return authorizationToken;
}